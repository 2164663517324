import { api } from '../../../services/api';

import { ICompany } from '../models';

const getCompanyById = async (id: number): Promise<ICompany> => {
  const res = await api.get(`/company/${id}`);
  const { data } = res.data;
  return data;
};

const postCompany = async (company: ICompany): Promise<void> => {
  await api.post('/company', company);
};

const putCompany = async (company: ICompany): Promise<void> => {
  await api.put(`/company`, company);
};

export default {
  getCompanyById,
  postCompany,
  putCompany,
};
