/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';

import Icon from '@mdi/react';
import {
  mdiArrowUp,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiContentSave,
  mdiDelete,
  mdiDeleteOutline,
  mdiFilter,
  mdiMagnify,
  mdiPageFirst,
  mdiPageLast,
  mdiPencil,
  mdiPlusBox,
  mdiViewColumn,
} from '@mdi/js';

import {
  Icons as TypeIcons,
  Options as TypeOptions,
  Localization as TypeLocalization,
} from 'material-table';

const Icons: TypeIcons = {
  Add: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiPlusBox} />
  )),
  Check: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiCheck} />
  )),
  Clear: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiClose} />
  )),
  Edit: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiPencil} />
  )),
  Delete: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiDeleteOutline} />
  )),
  DetailPanel: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiChevronRight} />
  )),
  Export: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiContentSave} />
  )),
  Filter: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiFilter} />
  )),
  FirstPage: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiPageFirst} />
  )),
  LastPage: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiPageLast} />
  )),
  NextPage: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiChevronRight} />
  )),
  PreviousPage: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiChevronLeft} />
  )),
  ResetSearch: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiClose} />
  )),
  Search: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiMagnify} />
  )),
  SortArrow: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiArrowUp} />
  )),
  ThirdStateCheck: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiDelete} />
  )),
  ViewColumn: forwardRef((props: any, ref: any) => (
    <Icon size={1} {...props} ref={ref} path={mdiViewColumn} />
  )),
};

const Localization = (
  actionsTitle = '',
  isLoading = false,
): TypeLocalization => {
  return {
    toolbar: {
      searchPlaceholder: 'Pesquisar',
      nRowsSelected: '{0} Linha(s) selecionada(s)',
    },
    header: {
      actions: actionsTitle,
    },
    pagination: {
      labelRowsSelect: 'linhas',
      labelDisplayedRows: '{from}-{to} de {count}',
      nextTooltip: 'Próxima página',
      previousTooltip: 'Página anterior',
      lastTooltip: 'Última página',
      firstTooltip: 'Primeira página',
    },
    body: {
      emptyDataSourceMessage: isLoading
        ? 'Pesquisando...'
        : 'Nenhum registro encontrado',
    },
  };
};

const Options = <T extends object>(options: TypeOptions<T>): TypeOptions<T> => {
  return {
    ...options,
    toolbar: false,
    loadingType: 'linear',
    pageSize: 10,
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false,
    paging: false,
    headerStyle: {
      zIndex: 0,
      fontFamily: 'Maven Pro',
      color: 'rgba(0,0,0,.5)',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '1rem',
    },
    rowStyle: {
      fontFamily: 'Maven Pro',
      fontSize: '14px',
    },
  };
};

export { Icons, Options, Localization };
