/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { get, isNaN } from 'lodash';
import {
  mdiCalculator,
  mdiCurrencyUsd,
  mdiFileDocumentMultiple,
  mdiFormatListBulletedType,
  mdiInformation,
  mdiShieldEdit,
  mdiText,
  mdiTextSubject,
  mdiUpload,
  mdiCloudUpload,
} from '@mdi/js';
import { Grid } from '@material-ui/core';
import SimpleDialog from '../newTransactionModal/index';
import { useBackupFilters } from '../../../../hooks/backup-filters';
import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';
import { petitionTypeArray } from '../../../../utils/helpers/array-helper';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import {
  Autocomplete,
  Card,
  Checkbox,
  DatePicker,
  Input,
  RadioGroup,
  Select,
  TextEditor,
} from '../../../../components/Form';
import { OptionsProps } from '../../../../components/Form/RadioGroup';
import { IDefenseText } from '../../../DefenseText/models';
import { ISupportingDocument } from '../../../SupportingDocument/models';
import api from '../../services/index';
import { getData } from '../../../../components/Tables/AsyncTable/services/index';
import { IDefenseForm } from '../../models';
import { DivisorName } from './styles';
import { monetaryValue } from '../../../../utils/regex-validations';
import ans from '../../services/ans';
import { IAttendance, IFile } from '../../../Document/models';
import IntegrationSuccess from './notification';
import GenericModal from './errorDialog';
import LoadingModal from './loading';
import apiAns from '../../../../services/api/ans';
import ControlledTreeView from './treedoc';
import ControlledTreeViewAns from './treeDocAns';

const orderOptions: OptionsProps[] = [
  {
    name: 'rectification',
    label: 'Anulação da identificação do atendimento.',
    value: 1,
  },
  {
    name: 'allCancellation',
    label: 'Retificação do valor a ser ressarcido.',
    value: 2,
  },
  {
    name: 'othersSupportingDocument',
    label:
      'Anulação da identificação do atendimento ou, subsidiariamente, retificação do valor a ser ressarcido.',
    value: 3,
  },
];

const DefenseForm: React.FC = () => {
  const [, setStatus] = useState('');
  const [, setIsLoadingStatus] = useState(true);
  const history = useHistory();
  const { clearBackup } = useBackupFilters();
  const { setErrorMessage, setMessage } = useSnackbar();
  const formRef = useRef(null);
  // const formRefText = useRef(null);
  const defense = history.location.state as IDefenseForm;
  const { document, attendance } = defense || ({} as IDefenseForm);
  const [isLoading, setIsLoading] = useState(false);
  const [defenseTexts, setDefenseTexts] = useState<IDefenseText[]>([]);
  const [supportingDocuments, setSupportingDocuments] = useState<
    ISupportingDocument[]
  >([]);
  const [isLoadingDefenseTexts, setIsLoadingDefenseTexts] = useState(false);
  const [
    isLoadingSupportingDocuments,
    setIsLoadingSupportingDocuments,
  ] = useState(false);
  const [othersSupportingDocument, setOthersSupportingDocument] = useState(
    defense?.othersSupportingDocument || false,
  );
  const [showRectificationValue, setShowRectificationValue] = useState(
    defense?.orderOption === 2 || defense?.orderOption === 3,
  );
  const [rectification, setRectification] = useState<number>();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [defenseID, setDefenseID] = useState(null);
  const [uploadedFile, setUploadedFile] = useState<IFile | null>(null);
  const [protocol, setProtocol] = useState('');
  const [, setNotification] = useState({ message: '', type: '' });
  const [fullAttendance, setFullAttendance] = useState<IAttendance>();
  const [, setIsNotificationOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [, setIsSaveButtonClicked] = useState(false);

  // const [listText, setListText] = useState<ListText[]>([]);

  const showSuccessNotification = (message: string): void => {
    setNotification({ message, type: 'success' });
    setIsNotificationOpen(true);

    // Fechar a notificação após alguns segundos (opcional)
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 3000); // Fecha após 3 segundos (ajuste conforme necessário)
  };

  const showErrorNotification = (message: string): void => {
    setNotification({ message, type: 'error' });
    setIsNotificationOpen(true);
  };

  // const [textState, setTextState] = useState('');
  // const [textModalState, setTextModalState] = useState('');
  // const [listMotive, setListMotive] = useState<ListMotive[]>([
  //  {
  //    index: 0,
  //    title: '',
  //    value: '',
  //  },
  // ]);
  useEffect(() => {
    // Fazer a solicitação à sua API para obter o status
    apiAns
      .get(`/defense/protocol/${defense.id}`)
      .then(response => response.data)
      .then(data => {
        // Definir o valor retornado na variável de estado 'status'
        const protocolValue =
          data.protocol !== null ? data.protocol : 'Não Iniciado';
        setProtocol(protocolValue);
        setStatus(data.status);
        console.log(data.status);
        console.log('Valor do Protocolo:', data.protocol);
        setIsLoadingStatus(false); // Marcar que a solicitação foi concluída
      })
      .catch(error => {
        console.error('Erro ao obter o status:', error);
        setIsLoadingStatus(false); // Marcar que a solicitação foi concluída
      });

    getData<IAttendance>('/attendance', 'attendances', attendance.currentPage, {
      term: attendance.currentPage > 1 ? undefined : attendance?.number,
      id: attendance.documentId,
    }).then(response => {
      console.log(
        response.data,
        `attendance id : ${attendance.id}`,
        response.data.find((data: IAttendance) => data.id === attendance.id),
      );
      setFullAttendance(
        response.data.find((data: IAttendance) => data.id === attendance.id),
      );
    });
  }, [
    attendance,
    attendance.currentPage,
    attendance.documentId,
    attendance.id,
    attendance.number,
    defense.id,
  ]);

  useEffect(() => {
    if (!defense) history.replace('/defesas');
    setRectification(defense.rectificationValue);
  }, [defense, history]);

  useEffect(() => {
    const fillArrays = async (): Promise<void> => {
      setIsLoadingDefenseTexts(true);
      setIsLoadingSupportingDocuments(true);

      try {
        const texts = await api.getDefenseTexts();
        texts.sort((a, b) => {
          const aNumberMatch = /^\d+/.exec(a.name);
          const bNumberMatch = /^\d+/.exec(b.name);
          return (
            (aNumberMatch ? parseInt(aNumberMatch[0], 10) : 0) -
            (bNumberMatch ? parseInt(bNumberMatch[0], 10) : 0)
          );
        });
        setDefenseTexts(texts);

        if (defense) {
          const textsMap = texts.filter(
            text =>
              defense.defences?.filter(textId => textId === text.id).length,
          );
          formRef.current.setFieldValue('defences', textsMap);
        }
      } catch (err) {
        setErrorMessage(
          'Houve um erro ao buscar os textos de defesas.',
          err,
          true,
        );
      } finally {
        setIsLoadingDefenseTexts(false);
      }

      try {
        const documents = await api.getSupportingDocuments();
        setSupportingDocuments(documents);

        if (defense) {
          const documentsMap = documents.filter(
            supportingDocument =>
              defense.supportingDocuments?.filter(
                documentId => documentId === supportingDocument.id,
              ).length,
          );
          formRef.current.setFieldValue('supportingDocuments', documentsMap);
        }
      } catch (err) {
        setErrorMessage(
          'Houve um erro ao buscar os documentos comprobatórios.',
          err,
          true,
        );
      } finally {
        setIsLoadingSupportingDocuments(false);
      }
    };

    fillArrays();
  }, [defense, setErrorMessage]);

  const handleDownload = (defenseId: number): void => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/report?idDefense=${defenseId}`,
    );
  };

  const handleThirdButtonClick = async (): Promise<void> => {
    const defenseId = defense?.id;
    if (defenseId !== undefined) {
      setUploadedFile(uploadedFile);
      setIsVisibleModal(true);
    } else {
      // Se `defense.id` for `undefined`, mostrar um alerta com a mensagem.
      setModalMessage(
        'Uma defesa precisa ser gerada antes de anexar os documentos.',
      );
      setIsError(true);
      setModalOpen(true);
      setIsSpinner(false);
    }
  };

  const handleoAnsButtonClick = async (): Promise<void> => {
    const defenseId = defense?.id;
    console.log(defenseId);
    if (defenseId !== undefined) {
      try {
        setIsSpinner(true);
        const protocolResponse = await ans.postANS(defenseId);
        console.log(typeof protocolResponse);
        setModalMessage(
          `Sucesso ao enviar o protocolo para a ANS: ${protocolResponse}`,
        );
        setIsError(false);
        setModalOpen(true);
        setIsSpinner(false);
      } catch (error) {
        setModalMessage(`Error ao enviar para ANS: ${error}`);
        setIsError(true);
        setModalOpen(true);
        console.error('Erro na solicitação para a API:', error);
        setIsSpinner(false);
      }
    } else {
      // Se `defense.id` for `undefined`, mostrar um alerta com a mensagem.
      setModalMessage(
        'Uma defesa precisa ser gerada antes de enviar para a ANS.',
      );
      setIsError(true);
      setModalOpen(true);
    }
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
  };

  const handleSubmit = async (formData: IDefenseForm): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        petitionType: Yup.string().required(
          'O campo tipo de petição é obrigatório',
        ),
        text: Yup.string().required('O campo texto de defesa é obrigatório'),
        // .max(3000, 'Digite um texto com no máximo 3000 caracteres'),
        calculationMemory: Yup.string().max(
          3000,
          'Digite um texto com no máximo 3000 caracteres',
        ),
        supportingDocument: Yup.string().max(
          3000,
          'Digite um texto com no máximo 3000 caracteres',
        ),
        rectificationValue: Yup.number()
          .transform(value => (isNaN(value) ? undefined : value))
          .when('orderOption', {
            is: 2,
            then: (ref: Yup.NumberSchema<number>) =>
              ref.required('Campo obrigatório'),
          })
          .when('orderOption', {
            is: 3,
            then: (ref: Yup.NumberSchema<number>) =>
              ref.required('Campo obrigatório'),
          }),
      });

      await schema.validate(formData, { abortEarly: false });

      setIsSaveButtonClicked(true);
      setIsLoading(true);

      const data: IDefenseForm = {
        ...defense,
        ...formData,
        id_Attendance: attendance.id,
      };
      if (defense.id) {
        await api.putDefense(data);
        handleDownload(defense.id);
        setIsLoading(false);
      } else {
        const defenseId = await api.postDefense(data);
        setDefenseID(defenseID);
        handleDownload(defenseId);
        clearBackup();
        history.goBack();
      }

      setMessage({
        type: 'success',
        message: 'Defesa salva com sucesso',
      });
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar a defesa.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  const handleChangeDefenses = (
    value: string | IDefenseText | (string | IDefenseText)[],
    fullAttendanceArg: IAttendance,
  ): void => {
    let texts = '';

    if (typeof value === 'string') {
      texts = value;
    } else if (Array.isArray(value)) {
      // If multiple options are selected, concatenate their texts
      texts = value
        .filter((item): item is IDefenseText => typeof item !== 'string')
        .map((text: IDefenseText) => text.text)
        .join('\n\n');
    } else {
      // If a single option is selected
      texts = value.text;
    }

    // window.alert('o texto selecionado foi: ', texts);

    if (
      // fullAttendance && // Verifica se fullAttendance não é undefined
      fullAttendanceArg?.endDateAttendance &&
      fullAttendanceArg?.initialDateAttendance
    ) {
      // Formatar datas
      const initialDate = new Date(fullAttendanceArg.initialDateAttendance);
      const endDate = new Date(fullAttendanceArg.endDateAttendance);

      const formattedInitialDate = `${initialDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${(initialDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${initialDate.getFullYear()}`;
      const formattedEndDate = `${endDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${(endDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${endDate.getFullYear()}`;

      // Obter o nome da cidade da API
      fetch(
        `https://resus-front.intelicorp.com.br/api/defense/attendence/city/${fullAttendanceArg.id}`,
      )
        .then(response => {
          if (!response.ok) {
            throw new Error('Falha ao obter o nome da cidade.');
          }
          console.log(response);
          return response.json();
        })
        .then(cityData => {
          const cityName = cityData.city;
          const newText = texts
            .replace('%DATA_INICIAL%', formattedInitialDate)
            .replace('%DATA_FINAL%', formattedEndDate)
            .replace('%CIDADE%', cityName)
            .replace(
              '%PROCEDIMENTO%',
              fullAttendanceArg.mainProcedure.descriptionProcedure,
            );

          // Definir o valor do campo 'text' no formulário
          formRef.current.setFieldValue('text', newText);
        })
        .catch(error => {
          console.error('Erro ao obter o nome da cidade:', error);
        });
    } else {
      console.error('endDateAttendance is not available in Attendance object.');
    }
  };

  // const handleChangeDefenses = (
  //   value: string | IDefenseText | (string | IDefenseText)[],
  //   isRemoving: boolean,
  //   isValid: boolean,
  //   textRemoved: IDefenseText[],
  //   oldValue: string | IDefenseText | (string | IDefenseText)[],
  // ): void => {
  //   let texts = '';
  //   const valueFind = [];
  //   const list: ListText[] = [];
  //   const lineBreak = '\r\n';
  //   const lengthNewValue = value as IDefenseText[];
  //   const lengthValue = oldValue as IDefenseText[];

  //   (value as IDefenseText[])?.forEach(text => {
  //     if (textState && !isRemoving) {
  //       texts =
  //         textState + (texts.length ? lineBreak + lineBreak : '') + text.text;
  //     } else if (isRemoving) {
  //       texts += textState;
  //     } else {
  //       texts = texts + (texts.length ? lineBreak + lineBreak : '') + text.text;
  //     }
  //   });

  //   const textSeparated = texts.split('\r\n');

  //   if (!isRemoving && isValid) {
  //     const itemAdded = lengthNewValue.filter((itemNew: IDefenseText) => {
  //       return !lengthValue.includes(itemNew);
  //     });

  //     const itemExist = lengthNewValue.filter((itemNew: IDefenseText) => {
  //       return lengthValue.includes(itemNew);
  //     });

  //     textSeparated.map((item: string, index: number) => {
  //       if (item) {
  //         if (itemAdded[0].text.trim() === item.trim()) {
  //           list.push({ newText: '', oldText: item });
  //         } else if (textSeparated.length - 1 === index) {
  //           list.push({ newText: '', oldText: item });
  //         }
  //       }
  //     });

  //     if (itemExist && itemExist.length > 0) {
  //       listText.map((text: ListText) => {
  //         itemExist.map((existText: IDefenseText) => {
  //           if (text.oldText.trim() === existText.text.trim()) {
  //             list.push({
  //               newText: text.newText,
  //               oldText: text.oldText,
  //             });
  //           }
  //         });
  //       });
  //     }
  //   } else if (isRemoving && isValid) {
  //     textRemoved.map((itemRemoved: IDefenseText) => {
  //       listText.map((text: ListText, indexText: number) => {
  //         if (itemRemoved.text.trim() !== text.oldText.trim()) {
  //           list.push({
  //             newText: listText[indexText].newText,
  //             oldText: listText[indexText].oldText,
  //           });
  //         }
  //       });
  //     });
  //   }

  //   if (texts.includes('@')) {
  //     const size = texts.match(/@[{a-zA-Z}]+/g);

  //     if (size && size.length > 0) {
  //       size.map((item, index) => {
  //         if (item.search('@') !== -1) {
  //           valueFind.push({
  //             index,
  //             title: item,
  //             value: item,
  //           });
  //         }
  //       });
  //       setListMotive(valueFind);
  //     }
  //   }

  //   if (isValid) {
  //     setTextModalState(texts);
  //     setListText(list);
  //   }

  //   if (isRemoving) {
  //     texts = '';

  //     lengthNewValue.map((item: IDefenseText) => {
  //       list.map((text: ListText, index: number) => {
  //         if (item.text.trim() === text.oldText.trim()) {
  //           texts +=
  //             text.newText +
  //             (list.length - 1 !== index ? lineBreak + lineBreak : '');
  //         }
  //       });
  //     });

  //     setTextModalState(texts);
  //     setTextState(texts);

  //     formRef.current.setFieldValue('text', texts);
  //   } else if (texts.match(/@[{a-zA-Z}]+/g)) {
  //     setIsVisibleModal(true);
  //   } else {
  //     setMessage({
  //       type: 'warning',
  //       message: 'Nenhum texto encontrado para edição',
  //     });
  //   }
  // };

  const handleOthersSupportingDocument = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setOthersSupportingDocument(checked);
  };

  const handleOrderOption = (value: string | number): void => {
    setShowRectificationValue(value === 2 || value === 3);
  };

  // function setListMotive2(
  //   _arg0: { index: number; title: string; value: string }[],
  // ): void {
  //   throw new Error('Function not implemented.');
  // }

  // function setTextModalState2(_arg0: string): void {
  //   throw new Error('Function not implemented.');
  // }

  // function handleEdit(): void {
  //   throw new Error('Function not implemented.');
  // }
  return (
    <React.Fragment>
      <LoadingModal open={isSpinner} />
      <PageHeader
        showBackButton
        title={`${defense?.id ? 'Editar' : 'Nova'} Defesa`}
        subtitle={`Defesa de uma "${attendance?.type}"`}
      />
      <SimpleDialog
        open={isVisibleModal}
        selectedValue={modalValue}
        onBackdropClick={() => setIsVisibleModal(false)}
        onClose={(value: string) => setModalValue(value)}
        defenseID={defense?.id}
        uploadedFile={uploadedFile}
      />
      <Card
        formRef={formRef}
        isLoading={isLoading}
        initialData={defense}
        onSubmit={handleSubmit}
        submitText="Salvar e Gerar Defesa"
        submitIcon={mdiShieldEdit}
        // secondText="Baixar PDF"
        // secondIcon={mdiDownload}
        // showSecondButton={!!defense?.id}
        // onSecondButtonClick={() => handleDownload(defense?.id)}
        thirdText="Anexar Documento"
        thirdIcon={mdiUpload}
        showThirdButton
        onThirdButtonClick={handleThirdButtonClick}
        onSubmitAns={handleoAnsButtonClick}
        submitAnsText="Integrar ANS"
        submitAnsIcon={mdiCloudUpload}
        title={`Você está realizando a defesa do atendimento "Nº ${attendance?.orderNumber}" da "ABI ${document?.numberABI}"`}
      >
        <IntegrationSuccess
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
        <GenericModal
          open={modalOpen}
          onClose={handleCloseModal}
          message={modalMessage}
          isError={isError}
        />
        <Grid item xs={12}>
          <DivisorName>Atendimento</DivisorName>
        </Grid>
        <Input
          sm={6}
          md={3}
          label="Status"
          name="attendance.status"
          value={protocol}
          disabled
        />
        <Input
          sm={6}
          md={3}
          name="attendance.competence"
          label="Competência"
          disabled
        />

        <Input sm={6} md={3} name="attendance.type" label="Tipo" disabled />

        <DatePicker
          sm={6}
          md={3}
          name="attendance.initialDateAttendance"
          label="Data Inicial"
          disabled
        />

        <DatePicker
          sm={6}
          md={3}
          name="attendance.endDateAttendance"
          label="Data Final"
          disabled
        />

        <Grid item xs={12}>
          <DivisorName>Formulário de impugnação/recurso</DivisorName>
        </Grid>

        <Select
          startIcon={mdiFormatListBulletedType}
          name="petitionType"
          label="Tipo de Petição"
          placeholder="Selecione um tipo de petição"
          options={petitionTypeArray}
          optionValue="id"
          optionName="name"
          defaultValue={1}
          disabled={isLoading}
        />

        <Autocomplete
          multiple
          disableCloseOnSelect={false}
          loading={isLoadingDefenseTexts}
          startIcon={mdiText}
          label="Motivo de impugnação/recurso"
          placeholder="Selecione o(s) motivo(s) de impugnação/recurso..."
          name="defences"
          options={defenseTexts}
          optionValue="id"
          optionName="name"
          // defenseText
          // textState={textState}
          onValueChange={value => handleChangeDefenses(value, fullAttendance)}
          disabled={isLoadingDefenseTexts || isLoading}
          renderInput={null}
        />

        <TextEditor
          name="text"
          startIcon={mdiTextSubject}
          label="Detalhamento do Motivo"
          labelStyle={{ transform: 'translate(14px, -6px) scale(0.75)' }}
          placeholder="Detalhe o motivo aqui"
          // disabled
        />

        <Input
          sm={4}
          name="preliminary"
          startIcon={mdiInformation}
          label="Tempestividade e Outras Preliminares"
          placeholder="Informe a tempestividade ou outras preliminares"
          disabled={isLoading}
        />

        <Input
          sm={4}
          multiline
          name="calculationMemory"
          startIcon={mdiCalculator}
          label="Memória de Cálculo"
          placeholder="Se necessário, informe a memória de cálculo"
          disabled={isLoading}
        />

        <Autocomplete
          sm={4}
          multiple
          disableCloseOnSelect={false}
          loading={isLoadingSupportingDocuments}
          startIcon={mdiFileDocumentMultiple}
          label="Documentos Comprobatórios"
          placeholder="Selecione o(s) documento(s) comprobatório(s)..."
          name="supportingDocuments"
          options={supportingDocuments}
          optionValue="id"
          optionName="name"
          onChange={value => window.alert(`Valor selecionado: ${value}`)}
          disabled={isLoadingSupportingDocuments || isLoading}
          renderInput={null}
        />

        <Checkbox
          name="othersSupportingDocument"
          label="Informar outros documentos comprobatórios."
          onChange={handleOthersSupportingDocument}
          disabled={isLoading}
        />

        <Input
          multiline
          name="supportingDocument"
          startIcon={mdiFileDocumentMultiple}
          label="Outros Documentos Comprobatórios"
          placeholder="Informe documentos comprobatórios que não estão listados à cima"
          disabled={isLoading}
          hidden={!othersSupportingDocument}
        />

        <Grid item xs={12}>
          <DivisorName>Pedidos</DivisorName>
        </Grid>

        <RadioGroup
          name="orderOption"
          options={orderOptions}
          onValueChange={handleOrderOption}
          defaultValue={1}
        />

        <Input
          name="rectificationValue"
          startIcon={mdiCurrencyUsd}
          label="Valor Ressarcido"
          placeholder="Valor ressarcido em reais (R$)"
          // type="number"
          value={rectification}
          onChange={event =>
            setRectification(monetaryValue(event.target.value))
          }
          // InputProps={{
          //   inputProps: {
          //     pattern: '[-+]?[0-9]*[.,]?[0-9]+',
          //     step: '1.00',
          //   },
          // }}
          disabled={isLoading}
          hidden={!showRectificationValue}
        />
        <Grid item xs={12}>
          <DivisorName>Documentos Anexados</DivisorName>
        </Grid>
        <ControlledTreeView defenseID={defense?.id} />
        <Grid item xs={12}>
          <DivisorName>Documentos Anexados na ANS</DivisorName>
        </Grid>
        {defense?.id && <ControlledTreeViewAns defenseID={defense.id} />}
      </Card>
      {/* <ModalView
        open={isVisibleModal}
        onClose={() => setIsVisibleModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
         timeout: 500,
        }}
      >
        <Card
          title="Alterar texto"
          formRef={formRefText}
          showSubmitButton={false}
          initialData={{ textModal: setTextModalState2 }}
        >
          <IconCloseModal
            onClick={() => {
              setListMotive([
                {
                  index: 0,
                  title: '',
                  value: '',
                },
              ]);
              setTextModalState2('');
              setIsVisibleModal(false);
            }}
          />

          <Grid xs={12} item direction="row">
            <Grid style={{ width: '100%', marginBottom: 10 }}>
              <TextEditor
                name="textModal"
                startIcon={mdiTextSubject}
                label="Detalhamento do Motivo"
                labelStyle={{ transform: 'translate(14px, -6px) scale(0.75)' }}
                placeholder="Detalhe o motivo aqui"
                disabled
              />
            </Grid>

            <>
              {setListMotive.length > 0 &&
                setListMotive.map((item: ListMotive) => (
                  <Grid
                    key={item.index}
                    style={{ width: '100%', marginBottom: 10 }}
                  >
                    <InputFilter
                      label={item.title}
                      defaultValue={item.value}
                      onChange={text => {
                        // eslint-disable-next-line no-return-assign, no-param-reassign
                        return (item.value = text);
                      }}
                      placeholder={`Informe um valor para o campo ${item.title}`}
                    />
                  </Grid>
                ))}
            </>
          </Grid>

          <Grid xs={12} container item direction="row" justify="flex-end">
            <Button>Salvar</Button>
          </Grid>
        </Card>
      </ModalView> */}
    </React.Fragment>
  );
};

export default DefenseForm;
