/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';
import { darken } from 'polished';

export interface Props extends ButtonProps {
  buttonColor: string;
  textColor: string;
}

const MyButtonTransient = ({
  variant,
  buttonColor,
  textColor,
  ...rest
}: Props): React.ReactElement => <Button {...rest} variant={variant} />;

// https://github.com/styled-components/styled-components/pull/2093#issuecomment-474743876
export const MyButton = styled(MyButtonTransient)`
  ${props =>
    props.buttonColor === 'gradient'
      ? `background-image: linear-gradient(135deg, ${props.theme.palette.primary.main}, ${props.theme.palette.secondary.main});`
      : `background-color: ${
          props.variant === 'contained' ? props.buttonColor : 'transparent'
        } !important;`}

  color: ${props =>
    props.variant === 'contained' || props.buttonColor === 'gradient'
      ? props.textColor
      : props.buttonColor} !important;

  border-color: ${props =>
    props.variant === 'outlined' && props.buttonColor !== 'gradient'
      ? `${props.buttonColor}80`
      : 'unset'} !important;

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  &:hover:not(:disabled) {
    ${props =>
      props.buttonColor !== 'gradient' &&
      `background-color: ${
        props.variant === 'contained'
          ? darken(0.08, props.buttonColor)
          : `${props.buttonColor}0a`
      } !important;`}
    

    border-color: ${props =>
      props.variant === 'outlined' ? props.buttonColor : 'unset'} !important;
  }

  &:active:not(:disabled) {
    ${props =>
      props.variant === 'contained' || props.buttonColor === 'gradient'
        ? ''
        : `background-color: ${props.buttonColor}18 !important;`}
  }

  .MuiCircularProgress-colorPrimary {
    color: ${props => props.textColor};
  }
`;
