export enum CompanyTypeEnum {
  Admin = 1,
  Controller = 2,
  Requester = 3,
}

export enum UserProfileEnum {
  Admin = 1,
  Normal = 2,
}

export enum PetitionTypeEnum {
  Impeachment = 1,
  Resource = 2,
}

export enum DocumentStatusEnum {
  Open = 1,
  InProgress = 2,
  Done = 3,
}

export enum AttendanceStatusEnum {
  Open = 1,
  Filled = 2,
  Defended = 3,
  WithoutDefense = 4,
  Protocoled = 5,
}
