import { darken } from 'polished';
import {
  colorHexRegex,
  colorHexNoTransparencyRegex,
  colorSmallHexRegex,
} from '../regex-validations';

const paletteColors = [
  'primary',
  'secondary',
  'success',
  'info',
  'error',
  'warning',
];

export const validateHexColor = (
  color: string,
  allowHexOpacity = true,
): boolean => {
  if (!color) return false;
  if (allowHexOpacity && color.length === 9) {
    return colorHexRegex.test(color);
  }
  if (color.length === 4) {
    return colorSmallHexRegex.test(color);
  }
  return colorHexNoTransparencyRegex.test(color);
};

export const validatePaletteColor = (color: string): boolean => {
  if (!color) return false;
  return paletteColors.indexOf(color) >= 0;
};

export const addHolder = (color: string): string => {
  if (color?.length === 9) {
    const opacity = color.substring(7);
    const newOpacity = (0x19 + parseInt(opacity, 16)).toString(16);
    return color.substring(0, 7) + newOpacity;
  }
  return darken(0.04, color || '#FFF');
};
