import { api } from '../../../services/api';

import { ISupportingDocument } from '../models';

const getSupportingDocumentById = async (
  id: number,
): Promise<ISupportingDocument> => {
  const res = await api.get(`/supportingDocument/${id}`);
  const { data } = res.data;
  return data;
};

const postSupportingDocument = async (
  document: ISupportingDocument,
): Promise<void> => {
  await api.post('/supportingDocument', document);
};

const putSupportingDocument = async (
  document: ISupportingDocument,
): Promise<void> => {
  await api.put(`/supportingDocument`, document);
};

export default {
  getSupportingDocumentById,
  postSupportingDocument,
  putSupportingDocument,
};
