/* eslint-disable no-param-reassign */
import { api } from '../../../services/api';
import { IDefenseText } from '../../DefenseText/models';
import { ISupportingDocument } from '../../SupportingDocument/models';

import { IDefenseForm } from '../models';

const getDefenseTexts = async (): Promise<IDefenseText[]> => {
  const res = await api.get(`/defaultDefense`);
  const { texts } = res.data.data;
  return texts;
};

const getSupportingDocuments = async (): Promise<ISupportingDocument[]> => {
  const res = await api.get(`/supportingDocument`);
  const { documents } = res.data.data;
  return documents;
};

const getDefenseById = async (id: number): Promise<IDefenseForm> => {
  const res = await api.get(`/defense/${id}`);
  const { data } = res.data;
  return data;
};

const postDefense = async (defense: IDefenseForm): Promise<number> => {
  delete defense.attendance;
  delete defense.document;

  const res = await api.post(`/defense`, defense);
  const { data } = res.data;
  return data;
};

const putDefense = async (defense: IDefenseForm): Promise<number> => {
  delete defense.attendance;
  delete defense.document;

  const res = await api.put(`/defense`, defense);
  const { data } = res.data;
  return data;
};

export default {
  getDefenseTexts,
  getSupportingDocuments,
  getDefenseById,
  postDefense,
  putDefense,
};
