import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import background from '../../../../assets/background/background.svg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(${background});
  background-color: ${props => props.theme.palette.background.paper};
`;

export const LoaderContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 75%);
  z-index: 1;
`;

export const SearchContainer = styled.div`
  padding: 32px;
  display: flex;
`;

export const ContentContainer = styled.div`
  height: 100vh;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const HelloContainer = styled.div`
  margin-top: 48px;
  color: ${props => props.theme.palette.info.main};
  text-align: center;

  h1 {
    font-size: 40px;
  }

  span {
    font-weight: 400;
  }
`;

export const SelectCompanyContainer = styled.div`
  margin-top: 48px;
  max-width: 350px;
  text-align: center;

  h1 {
    font-size: 29px;
  }

  p {
    color: ${props => props.theme.palette.text.secondary};
  }
`;

export const CompaniesContainer = styled(Grid)`
  max-width: 1000px;
  margin-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const MessageNoCompany = styled.div`
  width: fit-content;
  margin: auto;
  padding: 12px !important;
  display: flex;
  align-items: center;
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: 48px;
  background-color: ${props => props.theme.palette.background.paper};

  svg {
    padding: 8px;
    background-color: black;
    border-radius: 100%;
    color: ${props => props.theme.palette.error.light};
  }

  p {
    margin: 0 12px;
    font-size: 20px;
    color: ${props => props.theme.palette.text.secondary};

    b {
      color: ${props => props.theme.palette.secondary.main};
    }
  }
`;
