// Arquivo FileSelect.tsx
import React from 'react';
import SimpleSelect from '../Dropdown';

interface FileSelectProps {
  onSelectChange: (value: string) => void; // Ajuste o tipo conforme necessário
  isDropdownEmpty: boolean;
}

export default function FileSelect({
  onSelectChange,
  isDropdownEmpty,
}: FileSelectProps): JSX.Element {
  return (
    <SimpleSelect
      onSelectChange={onSelectChange}
      isDropdownEmpty={isDropdownEmpty}
    />
  );
}
