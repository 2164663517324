import React from 'react';
import {
  mdiAccountGroup,
  mdiArrowLeft,
  mdiDesktopMacDashboard,
  mdiDomain,
  mdiExitToApp,
  mdiFile,
  mdiScriptTextOutline,
  mdiShieldEdit,
  mdiText,
  mdiTextBox,
  mdiTextBoxSearchOutline,
  mdiShieldAccount,
} from '@mdi/js';
import Icon from '@mdi/react';

import { useAuth } from '../../../hooks/auth';
import IconResus from '../../../assets/resus/IconeBrancoReSUS.png';

import RoundButton from '../../Buttons/RoundButton';

import NavItem from './Item';
import {
  Container,
  HeaderContainer,
  ItemsContainer,
  FooterContainer,
} from './styles';
import { UserProfileEnum, CompanyTypeEnum } from '../../../utils/enum';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const Navbar: React.FC<Props> = ({ isOpen, onClose }) => {
  const { loggedUser, loggedCompany, logout } = useAuth();

  return (
    <Container isOpen={isOpen}>
      <HeaderContainer isOpen={isOpen}>
        <div>
          <img src={IconResus} alt="Imagem do Usuário" />

          <p>
            <span>Olá</span> {loggedUser.name.split(' ')[0]}!
          </p>
        </div>

        <RoundButton
          icon={mdiArrowLeft}
          iconColor="#FFF"
          backgroundColor="#FFFFFF4d"
          onClick={onClose}
        />
      </HeaderContainer>

      <ItemsContainer isOpen={isOpen}>
        <NavItem
          isOpen={isOpen}
          route="/"
          icon={mdiDesktopMacDashboard}
          title="Dashboard"
        />

        <NavItem
          isOpen={isOpen}
          route="/empresas"
          icon={mdiDomain}
          title="Empresas"
          isVisible={loggedUser.profile === UserProfileEnum.Admin}
        />

        <NavItem
          isOpen={isOpen}
          route="/usuarios"
          icon={mdiAccountGroup}
          title="Usuários"
          isVisible={loggedUser.profile === UserProfileEnum.Admin}
        />

        <NavItem
          isOpen={isOpen}
          route="/textos-de-defesa"
          icon={mdiText}
          title="Textos de defesa"
          isVisible={loggedCompany.type !== CompanyTypeEnum.Requester}
        />

        <NavItem
          isOpen={isOpen}
          route="/documentos-comprobatorios"
          icon={mdiTextBox}
          title="Documentos comprobatórios"
          isVisible={loggedCompany.type !== CompanyTypeEnum.Requester}
        />

        <NavItem isOpen={isOpen} route="/abis" icon={mdiFile} title="ABIs" />

        <NavItem
          isOpen={isOpen}
          route="/defesas"
          icon={mdiShieldEdit}
          title="Defesas"
          isVisible={loggedCompany.type !== CompanyTypeEnum.Requester}
        />

        <NavItem
          isOpen={isOpen}
          route="/recolhimento"
          icon={mdiTextBoxSearchOutline}
          title="Recolhimentos"
        />

        <NavItem
          isOpen={isOpen}
          route="/logs"
          icon={mdiScriptTextOutline}
          title="Logs"
        />

        <NavItem
          isOpen={isOpen}
          route="/politicadeprivacidadeetermos"
          icon={mdiShieldAccount}
          title="Política de Privacidade e Termo de Uso"
        />
      </ItemsContainer>
      <FooterContainer onClick={logout} isOpen={isOpen}>
        <Icon color="white" size={1} path={mdiExitToApp} />
        <span>Sair</span>
      </FooterContainer>
    </Container>
  );
};

export default Navbar;
