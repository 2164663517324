/* eslint-disable no-param-reassign */
import ans from '../../../services/api/ans';

const postANS = async (defenseId: number): Promise<[]> => {
  const res = await ans.post(`/defense/send/ans/${defenseId}`);
  return res.data.protocolo;
  // const { texts } = res.data.data;
  // return res.data;
};

const postDocument = async (): Promise<[]> => {
  const res = await ans.post(`/defense/additional/doc`);
  // const { texts } = res.data.data;
  return res.data;
};

export default { postANS, postDocument };
