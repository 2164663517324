/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import apiAns from '../../../../services/api/ans';
import { IFile } from '../../../Document/models';
import FileList from '../../../Document/pages/Upload/components/FileList/index';
import UploadPdf from '../../../Document/pages/Upload/components/UploadDoc';
import SubmitAns from './button';
import { useFileUploader } from './uploadoc';

type Doc = {
  id: number;
  nome: string;
  index_type_document: number;
  index_container: number;
};

const useStyles = makeStyles({
  increasedSize: {
    width: '80%',
    maxWidth: 'none',
  },
  list: {
    'padding-block': '1rem',
    'padding-inline': '2rem',
    display: 'flex',
    'flex-direction': 'column',
    'row-gap': '1rem',
  },
});
export default function SimpleDialog(props: {
  onClose: (selectedValue: string) => void;
  selectedValue: string;
  open: boolean;
  onBackdropClick: () => void;
  defenseID: number;
  uploadedFile: IFile | null; // Add uploadedFile here
}): JSX.Element {
  const classes = useStyles();
  const { onClose, selectedValue, open, onBackdropClick, defenseID } = props;
  const [, setIsDropdownVisible] = useState(false);
  const [, setIsDropdownEmpty] = useState(false); // Adicione o estado para controlar se o dropdown está vazio
  const [, setIsModalOpen] = useState(false); // Adicione o estado do modal

  const [docsSelected, setDocSelected] = useState<Doc[]>([]);

  const { files, handleCancel, setFiles, updateFile } = useFileUploader();

  useEffect(() => {
    console.log('Refresh: ', docsSelected);
    console.log('files: ', files);
  }, [docsSelected, files]);

  const updateOrCreateObjectInArray = (array: any, updatedObject) => {
    const doc = array.find(
      (obj, index) => obj?.index_container === updatedObject?.index_container,
    );

    if (doc) {
      const docAtualizado = { ...doc, ...updatedObject };
      array[updatedObject.index_container] = docAtualizado;
      setDocSelected(array);
    } else {
      array[updatedObject?.index_container] = updatedObject;
      setDocSelected(array);
    }
  };

  const deleteObjectAtIndex = (array, indexToDelete) => {
    const updatedData = [...array];

    // delete o objeto na positação do index passado por parametro. (não foi chat gpt).
    const x = updatedData.splice(3, 1);

    if (x.length > 0) {
      setDocSelected(updatedData);
    }
  };

  const handleDelete = (index_container: any) => {
    deleteObjectAtIndex(docsSelected, index_container);
  };

  const setDocument = ({ id, nome, index_type_document, index_container }) => {
    // console.log("ID: ", id, " Nome: ", nome, " index: ", index_type_document, " index container: ", index_container);
    updateOrCreateObjectInArray(docsSelected, {
      id,
      nome,
      index_type_document,
      index_container,
    });
  };

  useEffect(() => {
    // Check if there are any uploaded files to show the dropdown
    if (files && files.length > 0) {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }
  }, [files]);

  const handleClose = (): void => {
    setIsModalOpen(false);
    onClose(selectedValue);
  };

  const handleSubmitClick = (): void => {
    const apiUrl =
      // 'https://uzbrxyztx6.execute-api.us-east-1.amazonaws.com/api/defense/additional/doc'; // Substitua com a URL da sua API
      'https://resus-front.intelicorp.com.br/api/defense/additional/doc';

    const allFilesHaveDocumentType = files.every((file, index) => {
      const id = docsSelected.find(doc => doc.index_container === index);
      return id && id.id !== undefined;
    });

    if (!allFilesHaveDocumentType) {
      alert(
        'Por favor, selecione pelo menos um tipo de documento antes de enviar.',
      );
      return;
    }

    // Crie um novo FormData
    const formData = new FormData();
    const mapeamentoDocumentosIds: any = [];

    // Itere sobre cada arquivo em files e adicione ao FormData
    files.forEach((file, index) => {
      const id = docsSelected.filter(doc => doc.index_container === index);

      if (id.length > 0) {
        formData.append(`additionalDoc`, file.file, file.name);
        mapeamentoDocumentosIds.push({
          ...file,
          ...{ documento: { id: id[0].id, nome: id[0].nome } },
        });
      } else {
        console.log(' Index do arquivo: ', `${index} - ${file.name}`);
      }
    });

    // Adicione outros dados ao FormData, como documentType e idDefense
    formData.append('documentType', JSON.stringify(mapeamentoDocumentosIds));
    formData.append('idDefense', defenseID.toString());

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    fetch(apiUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          // A solicitação foi bem-sucedida
          console.log(response.status);
          setIsModalOpen(false);
          setFiles([]);
          setIsDropdownEmpty(false); // Reseta o estado de isDropdownEmpty
          return response.status;
        }
        // A solicitação falhou, trate o erro aqui
        console.error('Erro ao enviar os arquivos para a API');
      })
      .then(data => {
        // Você pode fazer algo com a resposta da API aqui
        console.log('Resposta da API:', data);
      })
      .catch(error => {
        // Trate erros de rede ou outros erros aqui
        console.error('Erro na solicitação:', error);
      });
  };

  return (
    <Dialog
      onBackdropClick={onBackdropClick}
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: classes.increasedSize,
      }}
    >
      <DialogTitle>Anexar Documento</DialogTitle>
      <List className={classes.list}>
        <UploadPdf setFiles={setFiles} onUpload={() => null} />
        <FileList
          files={files}
          onUpdate={updateFile}
          onDelete={handleDelete}
          onCancel={handleCancel}
          onSelectChange={selectedOption => {
            setIsDropdownEmpty(false);
            setDocument(selectedOption);
          }}
        />
        <SubmitAns handleOnClick={handleSubmitClick} />
      </List>
    </Dialog>
  );
}
