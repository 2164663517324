/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Grid, LinearProgress } from '@material-ui/core';
import { mdiContentSave } from '@mdi/js';
import Button from '../../Buttons/Button';

import { ButtonsGrid, ProgressGrid } from './styles';

export interface FormProps {
  formRef: React.Ref<FormHandles>;
  initialData?: object;
  isLoading?: boolean;
  buttonGridWidth?: number;
  // Submit Button
  submitText?: string;
  submitIcon?: string;
  showSubmitButton?: boolean;
  onSubmit?: SubmitHandler;
  // Second Button
  secondText?: string;
  secondIcon?: string;
  showSecondButton?: boolean;
  onSecondButtonClick?(): void;
  disabledButton?: boolean;
  // third Button
  thirdText?: string;
  thirdIcon?: string;
  showThirdButton?: boolean;
  onThirdButtonClick?(): void;
  // Submit Ans Button
  submitAnsText?: string;
  submitAnsIcon?: string;
  showSubmitAnsButton?: boolean;
  onSubmitAns?(): void;
}

const BasicForm: React.FC<FormProps> = ({
  formRef,
  initialData = {},
  isLoading = false,
  buttonGridWidth = 450,
  // Submit Button
  submitText = null,
  submitIcon = null,
  showSubmitButton = true,
  onSubmit = () => null,
  // Second Button
  secondText = null,
  secondIcon = null,
  showSecondButton = false,
  onSecondButtonClick,
  children = null,
  disabledButton = false,
  // Third Button
  thirdText = null,
  thirdIcon = null,
  showThirdButton = false,
  onThirdButtonClick,
  // ANS Button
  submitAnsText = null,
  submitAnsIcon = null,
  showSubmitAnsButton = true,
  onSubmitAns = () => null,
}) => (
  <Form ref={formRef} onSubmit={onSubmit} initialData={initialData}>
    <Grid container direction="row" alignItems="center" spacing={3}>
      {isLoading && (
        <ProgressGrid>
          <LinearProgress />
        </ProgressGrid>
      )}

      {children}

      {(showSecondButton || showSubmitButton || showThirdButton) && (
        <ButtonsGrid
          xs={12}
          container
          item
          direction="row"
          justify="flex-end"
          minWidthGrid={buttonGridWidth}
        >
          {showThirdButton && (
            <Grid item>
              <Button
                {...(thirdIcon !== null ? { startIcon: thirdIcon } : {})}
                type="button"
                buttonColor="secondary"
                variant="outlined"
                disabled={isLoading || disabledButton}
                onClick={onThirdButtonClick}
              >
                {thirdText}
              </Button>
            </Grid>
          )}
          {showSubmitAnsButton && (
            <Grid item>
              <Button
                startIcon={submitAnsIcon || mdiContentSave}
                type="button"
                buttonColor="secondary"
                disabled={isLoading || disabledButton}
                onClick={onSubmitAns}
              >
                {submitAnsText}
              </Button>
            </Grid>
          )}
          {showSecondButton && (
            <Grid item>
              <Button
                {...(secondIcon !== null ? { startIcon: secondIcon } : {})}
                type="button"
                buttonColor="secondary"
                variant="outlined"
                disabled={isLoading || disabledButton}
                onClick={onSecondButtonClick}
              >
                {secondText}
              </Button>
            </Grid>
          )}

          {showSubmitButton && (
            <Grid item>
              <Button
                startIcon={submitIcon || mdiContentSave}
                type="submit"
                buttonColor="secondary"
                disabled={isLoading || disabledButton}
              >
                {submitText || 'Salvar'}
              </Button>
            </Grid>
          )}
        </ButtonsGrid>
      )}
    </Grid>
  </Form>
);

export default BasicForm;
