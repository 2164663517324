import styled from 'styled-components';
import { SpeedDialAction, SpeedDialActionProps } from '@material-ui/lab';

interface Props extends SpeedDialActionProps {
  color?: string;
  colorHover?: string;
  hiddenButton?: boolean;
}

export const SpeedDialActions = styled(SpeedDialAction)<Props>`
  box-shadow: 'none';
  background-color: ${props => props.color};
  ${props => (props.hiddenButton ? `display: none !important;` : '')}
  & :hover {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.colorHover};
  }
`;
