import { api } from '../../../../../services/api';

import { INotification } from '../models';

interface Props {
  quantity: number;
}

const getNotification = async (): Promise<INotification[]> => {
  const res = await api.get(`/Notifications`);
  const { data } = res.data;
  return data.notifications;
};

const putNotification = async (idNotification: number): Promise<void> => {
  const res = await api.put(`/Notifications/visualize/${idNotification}`);
  const { data } = res.data;
  return data;
};

const getQuantity = async (): Promise<Props> => {
  const res = await api.get(`/Notifications/quantity`);
  const { data } = res.data;
  return data;
};

export default {
  getNotification,
  putNotification,
  getQuantity,
};
