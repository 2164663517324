import React, { useState, useRef, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Grid, Backdrop } from '@material-ui/core';

import { useSnackbar } from '../../../../../../hooks/snackbar';
import { handleFormError } from '../../../../../../utils/helpers/form-helper';

import { DataSeparateTitle } from '../../../../../../styles/global';
import {
  Card,
  Checkbox,
  DatePicker,
  Input,
} from '../../../../../../components/Form';

import api from '../../../../services';
import {
  IAttendance,
  IAttendanceList,
  IAttendanceModal,
} from '../../../../models';

import { IconCloseModal, ModalView } from './styles';

interface Props {
  attendance: IAttendance | IAttendanceList;
  isVisible: boolean;
  onClose: (success: boolean, attendanceModal?: IAttendanceModal) => void;
}

const AttendanceModal: React.FC<Props> = ({
  attendance,
  isVisible,
  onClose,
}) => {
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const [adicionalInfo, setAdicionalInfo] = useState<IAttendanceModal>();
  const [isLoading, setIsLoading] = useState(false);
  const [beneficiaryBirthDay, setBeneficiaryBirthDay] = useState<Date>();
  const [excludeDate, setExcludeDate] = useState<Date>();
  const [admissionDate, setAdmissionDate] = useState<Date>();
  const [adherenceDate, setAdherenceDate] = useState<Date>();

  useEffect(() => {
    const data = attendance?.attendanceAdicionalInfo;
    setAdicionalInfo(data);
    if (data) {
      setBeneficiaryBirthDay(data.beneficiaryBirthDay);
      setExcludeDate(data.excludeDate);
      setAdmissionDate(data.admissionDate);
      setAdherenceDate(data.adherenceDate);
    }
  }, [attendance]);

  const handleSubmit = async (data: IAttendanceModal): Promise<void> => {
    try {
      setIsLoading(true);

      const modalData: IAttendanceModal = {
        ...data,
        id: adicionalInfo?.id,
        id_Attendance: attendance.id,
        adherenceDate,
        admissionDate,
        excludeDate,
        beneficiaryBirthDay,
      };

      if (adicionalInfo) {
        await api.putAttendanceModal(modalData);
      } else {
        modalData.id = await api.postAttendanceModal(modalData);
      }

      setMessage({
        type: 'success',
        message: 'Informações adicionais salva com sucesso',
      });

      setIsLoading(false);

      onClose(true, modalData);
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar as informações do atendimento',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  return (
    <ModalView
      open={isVisible}
      onClose={() => onClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Card
        formRef={formRef}
        initialData={adicionalInfo}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        showSubmitButton
        title={`Estes são os dados adicionais do atendimento "${attendance?.type}" de "Nº ${attendance?.orderNumber}"`}
      >
        <Grid item xs={12}>
          <IconCloseModal onClick={() => onClose(false)}>
            <Icon size={1} path={mdiClose} />
          </IconCloseModal>
        </Grid>

        <Grid item xs={12}>
          <DataSeparateTitle>Plano</DataSeparateTitle>
        </Grid>

        <Input
          sm={4}
          name="contract"
          label="Contrato"
          placeholder="Informe o número do contrato"
          disabled={isLoading}
        />

        <Input
          sm={4}
          name="ansPlan"
          label="Plano ANS"
          placeholder="E o plano ANS"
          disabled={isLoading}
        />

        <Input
          sm={4}
          name="ansName"
          label="Nome Plano ANS"
          placeholder="E também o nome do plano ANS"
          disabled={isLoading}
        />

        <Input
          sm={3}
          name="formationPrice"
          label="Formação Preço"
          placeholder="Informe a formação de preço"
          disabled={isLoading}
        />

        <Input
          sm={3}
          name="contractType"
          label="Tipo Contratação"
          placeholder="Informe o tipo de contratação"
          disabled={isLoading}
        />

        <Input
          sm={3}
          name="segmentation"
          label="Segmentação"
          placeholder="Informe a segmentação"
          disabled={isLoading}
        />

        <Input
          sm={3}
          name="coparcenary"
          label="Co-Participação"
          placeholder="E a co-participação"
          disabled={isLoading}
        />

        <DatePicker
          sm={4}
          name="adherenceDate"
          label="Data Adesão"
          valueInput={adherenceDate}
          onChange={setAdherenceDate}
          placeholder="Informe a data de adesão"
          disabled={isLoading}
        />

        <DatePicker
          sm={4}
          name="admissionDate"
          label="Data Admissão"
          valueInput={admissionDate}
          onChange={setAdmissionDate}
          placeholder="Informe a data de admissão"
          disabled={isLoading}
        />

        <DatePicker
          sm={4}
          name="excludeDate"
          label="Data Exclusão"
          valueInput={excludeDate}
          onChange={setExcludeDate}
          placeholder="Informe a data de exclusão"
          disabled={isLoading}
        />

        <Grid item xs={12}>
          <DataSeparateTitle>Empresa</DataSeparateTitle>
        </Grid>

        <Input
          sm={6}
          name="companyCode"
          label="Código Empresa"
          placeholder="Informe também o código da empresa"
          disabled={isLoading}
        />

        <Input
          sm={6}
          name="companyName"
          label="Nome da Empresa"
          placeholder="E o nome da empresa"
          disabled={isLoading}
        />

        <Grid item xs={12}>
          <DataSeparateTitle>Beneficiário</DataSeparateTitle>
        </Grid>

        <Input
          sm={6}
          name="beneficiaryName"
          label="Nome Beneficiário"
          placeholder="Informe o nome do beneficiário?"
          disabled={isLoading}
        />

        <DatePicker
          sm={6}
          name="beneficiaryBirthDay"
          label="Data Nascimento"
          valueInput={beneficiaryBirthDay}
          onChange={setBeneficiaryBirthDay}
          placeholder="Informe a data de nascimento"
          disabled={isLoading}
        />

        <Checkbox
          md={6}
          lg={3}
          name="beneficiaryExcluded"
          label="Beneficiário excluído"
          disabled={isLoading}
        />

        <Checkbox
          md={6}
          lg={3}
          name="beneficiaryGrace"
          label="Beneficiário em Carência"
          disabled={isLoading}
        />

        <Checkbox
          md={6}
          lg={3}
          name="beneficiaryCPT"
          label="Informe o beneficiário em CPT"
          disabled={isLoading}
        />

        <Checkbox
          md={6}
          lg={3}
          name="beneficiaryCoverage"
          label="Beneficiário Fora Abrangência"
          disabled={isLoading}
        />
      </Card>
    </ModalView>
  );
};

export default AttendanceModal;
