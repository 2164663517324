import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';

import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { companyTypeArray } from '../../../../utils/helpers/array-helper';
import { CompanyTypeEnum } from '../../../../utils/enum';
import { IBasicFilter } from '../../../../models/api';

import { FabButton, FabButtonContainer } from '../../../../styles/global';
import InputFilter from '../../../../components/Filters/Input';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import AsyncTable from '../../../../components/Tables/AsyncTable';

import { ICompany } from '../../models';
import api from '../../services';

const CompanyList: React.FC = () => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const { setErrorMessage } = useSnackbar();
  const [filters, setFilters] = useState<IBasicFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const renderType = ({ type }: ICompany): string =>
    companyTypeArray.find(value => value.id === type)?.name || 'Não Informado';

  const columns: Column<ICompany>[] = [
    {
      title: 'Código',
      field: 'code',
    },
    {
      title: 'Nome',
      field: 'name',
    },
    {
      title: 'Tipo',
      field: 'type',
      hidden: loggedCompany.type !== CompanyTypeEnum.Admin,
      render: renderType,
    },
  ];

  const handleEdit = async ({ id }: ICompany): Promise<void> => {
    try {
      setIsLoading(true);
      const company = await api.getCompanyById(id);
      history.push({
        pathname: `/empresas/editar/${id}`,
        state: company,
      });
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao buscar os dados da empresa.',
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const actions: Action<ICompany>[] = [
    {
      icon: () => <Icon size={1} path={mdiPencil} />,
      tooltip: 'Editar',
      onClick: (_, company) => handleEdit(company as ICompany),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar as empresas.', err, true);
  };

  const handleCreate = (): void => {
    history.push('/empresas/novo');
  };

  return (
    <FabButtonContainer>
      <PageHeader
        title="Empresas"
        subtitle="Aqui mostra uma lista de todas empresas cadastradas"
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="companies"
            dataUrl="/company"
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>

      <FabButton color="secondary" onClick={handleCreate}>
        <Icon size={1} path={mdiPlus} />
      </FabButton>
    </FabButtonContainer>
  );
};

export default CompanyList;
