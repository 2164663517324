import { INotification } from '../components/PrivateLayout/Toolbar/Notification/models';

export interface NotificationProps {
  notification: INotification[];
  quantity: number;
}

export const initNotification: NotificationProps = {
  notification: [
    {
      id: 0,
      id_Document: 0,
      id_Company: 0,
      id_User: 0,
      show: false,
      userName: '',
      numberAbi: '',
      user: '',
      document: null,
    },
  ],
  quantity: 0,
};
