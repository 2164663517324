import React from 'react';
import { Container } from './styles';
// import IconAnimated from '../../assets/users/user.svg';

interface Props {
  show: boolean;
  message?: string;
  transparent?: boolean;
}

const Loading: React.FC<Props> = ({
  show = false,
  message = 'Carregando',
  transparent = false,
}) => {
  return show ? (
    <Container transparent={transparent}>
      <div>
        {/* <img src={IconAnimated} alt="" /> */}
        <span>{message}</span>
      </div>
    </Container>
  ) : null;
};

export default Loading;
