import styled from 'styled-components';

interface Props {
  size: number;
  sizeImage: number;
}

export const Container = styled.h1<Props>`
  width: max-content;
  padding-bottom: 8px;
  font-size: ${props => props.size}px;
  font-weight: 900;
  background: linear-gradient(
    100deg,
    ${props => props.theme.palette.primary.main} 10%,
    ${props => props.theme.palette.secondary.main} 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  img {
    height: ${props => props.sizeImage}px;
  }

  @media (max-width: 600px) {
    img {
      height: 28px;
    }
  }
`;
