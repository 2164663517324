/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { mdiLock } from '@mdi/js';
import { Grid } from '@material-ui/core';

import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import { Card, Input } from '../../../../components/Form';
import Logo from '../../../../components/Logo';

import { RulesPassword, IPassword } from '../../models';

import api from '../../services';

import {
  Container,
  PasswordContent,
  FormContent,
  RulesPasswordText,
} from './styles';
import theme from '../../../../styles/theme';

const initialPasswords = {
  password: '',
  confirmPassword: '',
};

const initialRules: RulesPassword[] = [
  {
    text: 'Pelo menos 8 caracteres.',
    valid: false,
  },
  {
    text: 'Pelo menos 1 letra maiúscula.',
    valid: false,
  },
  {
    text: 'Pelo menos 1 letra minúscula.',
    valid: false,
  },
  {
    text: 'Pelo menos 1 número.',
    valid: false,
  },
  {
    text: 'Pelo menos 1 caractere especial.',
    ex: 'Ex.: *#(@!)$&',
    valid: false,
  },
  {
    bold: 'NÃO',
    text: 'deve ter espaço em branco.',
    valid: false,
  },
  {
    bold: 'NÃO',
    text: 'deve ter números nem letras em sequências.',
    ex: 'Ex.: ABCD, DCBA ou 1234, 4321.',
    valid: false,
  },
];

const UserPassword: React.FC = () => {
  const history = useHistory();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const { token } = useParams<{ token: string }>();
  const [errorRule, setErrorRule] = useState('');
  const [passwordValue, setPasswordValue] = useState(initialPasswords);
  const [rulesPassword, setRulesPassword] = useState(initialRules);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: IPassword): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required('Campo senha é obrigatório.'),
        confirmPassword: Yup.string()
          .oneOf(
            [Yup.ref('password')],
            'Senhas divergentes, por favor verifique.',
          )
          .required('Campo confirmar senha é obrigatório.'),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);

      const params: IPassword = {
        token,
        password: data.password,
        confirmPassword: data.confirmPassword,
      };

      await api.postPassword(params);

      setMessage({ type: 'success', message: 'Senha cadastrada com sucesso.' });
      setIsLoading(false);

      localStorage.clear();
      history.replace('/');
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar a senha.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const validatePassword = (p: any): void => {
      const newRulesPass: RulesPassword[] = Array.from(rulesPassword);

      const anUpperCase = /[A-Z]/;
      const aLowerCase = /[a-z]/;
      const aNumber = /[0-9]/;
      const aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
      const hasSpace = /\s/;
      const hasSequence = /abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789/;
      const hasSequenceReverse = /987|876|765|654|543|432|321|210|zyx|yxw|xwv|wvu|vut|uts|tsr|srq|rqp|qpo|pon|onm|nml|mlk|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba/;

      // Mark valid on list
      newRulesPass[0].valid = (p.length && p.length > 7) || false;
      newRulesPass[1].valid = anUpperCase.test(p) || false;
      newRulesPass[2].valid = aLowerCase.test(p) || false;
      newRulesPass[3].valid = aNumber.test(p) || false;
      newRulesPass[4].valid = aSpecial.test(p) || false;
      newRulesPass[5].valid = (p.length && !hasSpace.test(p)) || false;
      newRulesPass[6].valid =
        (p.length &&
          !hasSequence.test(p.toLowerCase()) &&
          !hasSequenceReverse.test(p.toLowerCase())) ||
        false;

      setRulesPassword(newRulesPass);

      if (p.length < 7) {
        setErrorRule('Sua senha deve ter mais de 8 caracteres!');
      } else {
        setErrorRule('');
        switch (false) {
          case anUpperCase.test(p):
            setErrorRule('Sua senha deve ter ao menos uma letra maiúscula.');
            break;
          case aLowerCase.test(p):
            setErrorRule('Sua senha deve ter ao menos uma letra minúscula.');
            break;
          case aNumber.test(p):
            setErrorRule('Sua senha deve ter ao menos um número.');
            break;
          case aSpecial.test(p):
            setErrorRule('Sua senha deve ter ao menos um caractere especial.');
            break;
          case !hasSpace.test(p):
            setErrorRule('Sua senha NÃO deve ter espaço em branco.');
            break;
          case !hasSequence.test(p.toLowerCase()) &&
            !hasSequenceReverse.test(p.toLowerCase()):
            setErrorRule(
              'Sua senha NÃO deve ter letras ou números em sequência, ex: ABCD, DCBA ou 1234, 4321.',
            );
            break;
          default:
            return p;
        }
      }
    };
    const debounce = setTimeout(() => {
      validatePassword(passwordValue.password);
    }, 400);
    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValue.password]);

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>,
    input: string,
  ): void => {
    setPasswordValue({ ...passwordValue, [input]: event.target.value });
  };

  return (
    <Container>
      <FormContent>
        <Card
          formRef={formRef}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          title=""
          disabledButton={
            !rulesPassword[0].valid ||
            !rulesPassword[1].valid ||
            !rulesPassword[2].valid ||
            !rulesPassword[3].valid ||
            !rulesPassword[4].valid ||
            !rulesPassword[5].valid ||
            !rulesPassword[6].valid
          }
        >
          <Grid item xs={12}>
            <Logo size={68} sizeImage={85} />
          </Grid>
          <Input
            label="Senha"
            placeholder="Informe a senha do usuário"
            name="password"
            type="password"
            startIcon={mdiLock}
            disabled={isLoading}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangePassword(event, 'password');
            }}
          />

          <Input
            label="Confirmar Senha"
            placeholder="Por favor, informe a senha novamente"
            name="confirmPassword"
            type="password"
            startIcon={mdiLock}
            disabled={isLoading}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangePassword(event, 'confirmPassword');
            }}
          />

          {passwordValue.password && errorRule && (
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <span
                style={{
                  color: theme.palette.error.main,
                  fontSize: '13px',
                  textAlign: 'center',
                }}
              >
                ***
                {errorRule}
                ***
              </span>
            </Grid>
          )}

          <Grid item xs={12}>
            <ul style={{ padding: 0 }}>
              {rulesPassword.map((key: RulesPassword) => (
                <RulesPasswordText key={key.text} passwordIsValid={key.valid}>
                  {key.bold && <b>{key.bold}</b>}
                  {` ${key.text}`}
                  {key.ex && (
                    <b
                      style={{
                        color: theme.palette.primary.dark,
                      }}
                    >
                      {key.ex}
                    </b>
                  )}
                </RulesPasswordText>
              ))}
            </ul>
          </Grid>
        </Card>
      </FormContent>
      <PasswordContent>
        <h1>Vamos criar uma senha nova?</h1>
        <p>
          Informe uma senha ao lado seguindo as regrinhas citadas abaixo dos
          campos para uma senha bem segura :)
        </p>
      </PasswordContent>
    </Container>
  );
};

export default UserPassword;
