import styled from 'styled-components';
import {
  LinearProgress as MuiLinearProgress,
  Paper as MuiPaper,
} from '@material-ui/core';

interface TableProps {
  columns: any[];
  actionsTitle: string;
  isLoading: boolean;
  length: number;
  noElevation: boolean;
}

export const Container = styled.div`
  td {
    width: auto !important;
    border: none;

    &.MuiTableCell-paddingNone {
      div {
        span {
          margin: auto;
        }
        button {
          width: 44px;
          height: 44px;
          margin: auto;
        }
      }
    }
  }

  th {
    padding: 16px !important;
    border: none;

    :not(.MuiTableCell-paddingCheckbox) {
      width: auto !important;
    }
  }

  thead {
    svg {
      color: ${props => props.theme.palette.primary.main} !important;
    }
  }

  tbody {
    tr {
      border-color: '#000';

      :nth-of-type(2n + 1) {
        background-color: ${props => props.theme.palette.background.default};
      }
    }
  }
  tfoot {
    .MuiTableCell-footer {
      border: none;
    }

    button {
      color: ${props => props.theme.palette.primary.main};
    }

    .MuiButton-sizeSmall {
      margin: 2px;

      :disabled {
        color: #fff;
        background-color: ${props => props.theme.palette.primary.main};
      }
    }
  }
`;

export const ResponsiveTable = styled.div<TableProps>`
  ${({ columns, actionsTitle, isLoading, length, noElevation, theme }) => `
  
  div:nth-child(1) {
    border-radius: ${theme.shape.borderRadius}px;
    ${noElevation && `border: 0`} 
  }
  
  @media (max-width: ${theme.breakpoints.values.sm}px) {

    .MuiPagination-ul {
      justify-content: center;
    }
    
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
    
    thead:first-of-type tr { 
      display: none;
    }

    thead {
      z-index: 1;

      tr {
        margin-top: ${isLoading ? 4 : 0}px;
      }
    }

    tbody {
      ${
        !length
          ? `
          td {
            border: none;
            text-align: center;
            padding: 16px 16px 8px !important;
          }
          `
          : `
          tr { border-bottom: 1px solid ${theme.palette.grey[300]}; }

          tr:nth-of-type(even) { 
            background-color: ${theme.palette.grey[50]}; 
          }

          td {
            min-height: 53px;
            border: none;
            border-bottom: 1px solid ${theme.palette.grey[200]}; 
            position: relative;
            padding-left: 50%; 
          }
          
          td:before {
            font-family: Maven Pro, Helvetica, Arial, sans-serif;
            position: absolute;
            top: 16px;
            left: 16px;
            width: 45%; 
            padding-right: 10px; 
            // white-space: nowrap;
          }
        
          button {
            margin: auto 0 auto 50% !important;
          }
      
          button + button {
            margin: auto 0 !important;
          }

          ${columns.map(
            (column, index) => `
              td:nth-of-type(${index + 1}):before {
                content: '${column.title}';
              }`,
          )}
      
          ${
            actionsTitle
              ? `
                td:nth-of-type(${columns.length + 1}):before {
                  content: '${actionsTitle}';
                }`
              : ''
          }
      `
      }
    }
  }
`}
`;

export const Paper = styled(MuiPaper)`
  ${props =>
    props.elevation !== 0 ? '' : 'border: 1px solid rgba(0, 0, 0, 0.12);'}
`;

export const LinearProgress = styled(MuiLinearProgress)`
  border-radius: 0 !important;
  z-index: 1;
  margin-top: -4px;
`;
