import moment from 'moment';

export const formatDate = (
  date: Date,
  showTime = false,
  showSeconds = false,
): string => {
  let pattern = 'DD-MM-YYYY';
  if (showTime) {
    pattern += ' HH:mm';
    if (showSeconds) {
      pattern += ':ss';
    }
  }
  return moment(date).format(pattern);
};

export const formatCnpj = (cnpj: string): string => {
  if (!cnpj) return '';
  return cnpj
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
};

export const formatMonetaryValue = (value: number): string => {
  if (!value) return 'R$ 0,00';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};
