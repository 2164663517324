import React from 'react';

import PageHeader from '../../components/PrivateLayout/PageHeader';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <PageHeader
        title="Não Encontrado"
        subtitle="Aqui será a página para uma página não existente"
        showBackButton
      />
    </Container>
  );
};

export default NotFound;
