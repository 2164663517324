import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { mdiTextBox } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import { Card, Input } from '../../../../components/Form';

import api from '../../services';
import { ISupportingDocument } from '../../models';

const SupportingDocumentForm: React.FC = () => {
  const history = useHistory();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const supportingDocument = history.location.state as ISupportingDocument;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: ISupportingDocument): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('O campo nome é obrigatório')
          .max(200, 'Digite um nome com no máximo 200 caracteres'),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);
      if (supportingDocument) {
        await api.putSupportingDocument({ ...data, id: supportingDocument.id });
      } else {
        await api.postSupportingDocument(data);
      }

      setMessage({
        type: 'success',
        message: 'Documento comprobatório salvo com sucesso',
      });

      history.goBack();
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar o tipo de documento comprobatório.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title={`${
          supportingDocument ? 'Editar' : 'Criar'
        } documento comprobatório`}
        subtitle={`${
          supportingDocument
            ? 'Aqui é mostrado os dados do documento comprobatório'
            : 'Preencha os campos para cadastrar'
        }`}
      />

      <Card
        formRef={formRef}
        onSubmit={handleSubmit}
        initialData={supportingDocument}
        isLoading={isLoading}
        title={
          supportingDocument
            ? `Você selecionou o documento comprobatório "${supportingDocument.name}" para editar`
            : 'Você clicou para cadastrar uma novo documento comprobatório'
        }
      >
        <Input
          sm={12}
          name="name"
          startIcon={mdiTextBox}
          label="Nome"
          placeholder="Qual o nome do documento comprobatório?"
        />
      </Card>
    </React.Fragment>
  );
};

export default SupportingDocumentForm;
