import styled from 'styled-components';
import { InputLabel as MuiInputLabel } from '@material-ui/core';

interface Props {
  error: boolean;
  disabled: boolean;
}

export const EditorContainer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 18.5px 14px;

  border: 1px solid
    ${props => {
      if (props.error) return props.theme.palette.error.main;
      if (props.disabled) return '#f1f1f1';
      return props.theme.palette.primary.main;
    }};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  background-color: ${props => (props.disabled ? '#f1f1f1' : 'transparent')};

  .sun-editor .se-wrapper .se-placeholder {
    font: caption;
    padding: 0;
  }

  .sun-editor {
    min-height: 180px;
    border: none;
    background-color: transparent;
  }

  .sun-editor .se-wrapper .se-wrapper-wysiwyg {
    background-color: transparent;
    padding: 0;
  }

  .sun-editor-editable p {
    font: caption;
    display: block;
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', 'Maven Pro', 'Helvetica', 'Arial', sans-serif;
    color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit')};
  }
`;

export const InputLabel = styled(MuiInputLabel)<Props>`
  left: -5px;
  padding: 0 7px;
  background-color: ${props =>
    props.disabled ? 'transparent' : props.theme.palette.background.paper};
`;
