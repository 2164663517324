import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';

import RoundButton from '../../Buttons/RoundButton';

import { Container } from './styles';
import TextHighline from '../../TextHighline';

interface Props {
  title: string;
  subtitle: string;
  onBack?: () => void;
  showBackButton?: boolean;
}

const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  onBack = undefined,
  showBackButton = false,
}) => {
  const history = useHistory();

  useEffect(() => {
    document.title = `ReSUS - ${title}`;
  }, [title]);

  return (
    <Container>
      {showBackButton && (
        <RoundButton
          icon={mdiArrowLeft}
          iconColor="secondary"
          backgroundColor="secondary"
          onClick={() => (onBack ? onBack() : history.goBack())}
        />
      )}

      <div>
        <h2>{title}</h2>
        <TextHighline>{subtitle}</TextHighline>
      </div>
    </Container>
  );
};

export default PageHeader;
