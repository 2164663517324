/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { TextField, StandardTextFieldProps } from '@material-ui/core';

interface Props extends StandardTextFieldProps {
  appearance: 'input' | 'textArea';
}

const MyInputTransient = ({
  appearance,
  ...rest
}: Props): React.ReactElement => <TextField {...rest} variant="outlined" />;

// https://github.com/styled-components/styled-components/pull/2093#issuecomment-474743876
export const MyInput = styled(MyInputTransient)`
  textarea {
    ${props => (props.appearance === 'textArea' ? 'min-height: 180px;' : '')}
  }
`;
