import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Backdrop } from '@material-ui/core';
import { mdiEmailSend, mdiClose, mdiEmail } from '@mdi/js';
import Icon from '@mdi/react';

import { handleFormError } from '../../../../../../utils/helpers/form-helper';
import { Card, Input } from '../../../../../../components/Form';
import { useSnackbar } from '../../../../../../hooks/snackbar';

import {
  IconCloseModal,
  ModalView,
  SpanEmailError,
  ErrorMessage,
} from './styles';

import { IForgotPassword } from '../../../../models';
import api from '../../../../services';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const initialEmail = {
  email: '',
};

const ResetPassword: React.FC<Props> = ({ isVisible, onClose }) => {
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const [emailValue, setEmailValue] = useState(initialEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleSubmit = async (data: IForgotPassword): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('Campo e-mail é obrigatório.'),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);

      await api.postForgotPassword(data);

      onClose();

      setMessage({
        type: 'success',
        message:
          'E-mail enviado com sucesso! Basta acessá-lo e seguir os passos que indicamos.',
      });

      setIsLoading(false);
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao enviar o e-mail',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  const validateEmail = (e: string): void => {
    // eslint-disable-next-line no-useless-escape
    const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(isEmail.test(e));

    if (e !== '') {
      if (isEmailValid === true) {
        setErrorEmail('');
      } else {
        setErrorEmail('* Insira um e-mail válido');
      }
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      validateEmail(emailValue.email);
    }, 100);
    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue]);

  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    input: string,
  ): void => {
    setEmailValue({ ...emailValue, [input]: event.target.value });
  };

  return (
    <ModalView
      open={isVisible}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Card
        title="Esqueceu sua senha?"
        submitIcon={mdiEmailSend}
        submitText="Enviar"
        formRef={formRef}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      >
        <IconCloseModal onClick={() => onClose()}>
          <Icon size={1} path={mdiClose} />
        </IconCloseModal>

        <Input
          sm={12}
          fullWidth
          name="email"
          startIcon={mdiEmail}
          label="E-mail"
          placeholder="Nos informe seu e-mail para recuperá-la..."
          disabled={isLoading}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeEmail(event, 'email');
          }}
        />
        <ErrorMessage>
          {!isEmailValid && <SpanEmailError>{errorEmail}</SpanEmailError>}
        </ErrorMessage>
      </Card>
    </ModalView>
  );
};

export default ResetPassword;
