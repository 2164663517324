import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

export const DivisorName = styled.span`
  font-weight: 500;
  color: ${props => props.theme.palette.secondary.main};
`;

export const IconCloseModal = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const ModalView = styled(Modal)`
  display: flex;
  margin-bottom: 40px;
  padding: 40px;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  outline: 0;

  .iuJDEt {
    @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      max-height: 600px;
      overflow: auto;
    }
  }
`;
