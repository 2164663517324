/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import { useBackupFilters } from '../../../hooks/backup-filters';

import Table from '../Table';
import * as api from './services';
import { Props, DataTable } from './models';
import { PaginationContainer } from './styles';

const asyncOptions = { sorting: false, pageSizeOptions: [] };

const AsyncTable = <RowData extends object>({
  dataUrl = null,
  dataKey = null,
  refresh = false,
  ignoreBackupFilters = false,
  keyBackupFilters = '',
  onError = null,
  isLoading = false,
  options = null,
  filters,
  currentPage = 0,
  setCurrentPage,
  setFilters = () => null,
  ...rest
}: Props<RowData>): JSX.Element => {
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>();
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(currentPage);
  const [dataTable, setData] = useState<DataTable<RowData>>([]);
  const [lastFilter, setLastFilter] = useState();
  const [lastPage, setLastPage] = useState(0);
  const { setBackup, getBackup } = useBackupFilters();

  const getData = async (): Promise<void> => {
    if (!ignoreBackupFilters)
      setBackup({ ...filters, pageBackup: page }, keyBackupFilters);

    try {
      setIsLoadingTable(true);
      const res = await api.getData<RowData>(dataUrl, dataKey, page, filters);
      setData(res.data);
      setPages(Math.ceil(res.totalCount / 10));
    } catch (err) {
      if (onError) onError(err);
    } finally {
      setIsLoadingTable(false);
    }
  };

  const compareFilters = (f1: unknown, f2: unknown): boolean => {
    const lodashResult = lodash.isEqual(f1, f2);

    if (!lodashResult) {
      const isF1Empty =
        f1 === null || f1 === undefined || Object.entries(f1).length === 0;

      const isF2Empty =
        f2 === null || f2 === undefined || Object.entries(f2).length === 0;

      if (isF1Empty && isF2Empty) {
        return true;
      }

      if (!isF1Empty && isF2Empty) {
        const entries = Object.entries(f1);
        let equals = true;
        entries.forEach(key => {
          if (key[1]) {
            equals = false;
          }
        });
        return equals;
      }

      if (isF1Empty && !isF2Empty) {
        const entries = Object.entries(f2);
        let equals = true;
        entries.forEach(key => {
          if (key[1]) {
            equals = false;
          }
        });
        return equals;
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    const { pageBackup, ...filtersBackup } = getBackup(keyBackupFilters);

    if (page === 0) {
      if (!ignoreBackupFilters && pageBackup) {
        setPage(pageBackup);
        if (setCurrentPage) setCurrentPage(pageBackup);
        setFilters(filtersBackup);
        return;
      }

      setPage(1);
      if (setCurrentPage) setCurrentPage(1);
      return;
    }

    const filtersEquals = compareFilters(lastFilter, filters);

    if (filtersEquals && page === lastPage) return;

    setLastFilter(filters);

    if (!filtersEquals) {
      if (!ignoreBackupFilters && lastPage === 0 && pageBackup) {
        setPage(pageBackup);
        if (setCurrentPage) setCurrentPage(pageBackup);
      } else {
        setPage(1);
        if (setCurrentPage) setCurrentPage(1);
      }

      if (lastPage > 1) return;
    }
    setLastPage(page);

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, dataUrl, page, filters]);

  // For lists that don't refresh on modal closing
  useEffect(() => {
    if (refresh) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <React.Fragment>
      <Table
        {...rest}
        isLoading={isLoadingTable || isLoading}
        data={dataTable}
        options={{ ...options, ...asyncOptions }}
      />

      {pages > 1 && (
        <PaginationContainer>
          <Pagination
            color="secondary"
            size="medium"
            disabled={isLoadingTable || isLoading}
            count={pages}
            page={page}
            showFirstButton
            showLastButton
            onChange={(_, newPage) => {
              if (setCurrentPage) setCurrentPage(newPage);
              return setPage(newPage);
            }}
          />
        </PaginationContainer>
      )}
    </React.Fragment>
  );
};

export default AsyncTable;
