import React from 'react';
import { Route } from 'react-router-dom';

import PrivateLayout from '../components/PrivateLayout';
import CompanyList from '../containers/Company/pages/List';
import CompanyForm from '../containers/Company/pages/Form';
import Dashboard from '../containers/Dashboard';
import DefenseList from '../containers/Defense/pages/List';
import DefenseForm from '../containers/Defense/pages/Form';
import DefenseTextList from '../containers/DefenseText/pages/List';
import DefenseTextForm from '../containers/DefenseText/pages/Form';
import SupportingDocumentList from '../containers/SupportingDocument/pages/List';
import SupportingDocumentForm from '../containers/SupportingDocument/pages/Form';
import DocumentList from '../containers/Document/pages/List';
import DocumentUpload from '../containers/Document/pages/Upload';
import DocumentDetails from '../containers/Document/pages/Details';
import Expired from '../containers/Expired';
import LogDetails from '../containers/Log/pages/Details';
import LogList from '../containers/Log/pages/List';
import ReapForm from '../containers/Reap/pages/Form';
import ReapList from '../containers/Reap/pages/List';
import NotFound from '../containers/NotFound';
import UserForm from '../containers/User/pages/Form';
import UserList from '../containers/User/pages/List';
import UserPassword from '../containers/User/pages/Password';
import PolicyAndTerm from '../containers/PolicyAndTerm';

const PrivateRoutes: React.FC = () => (
  <PrivateLayout>
    <Route exact component={Dashboard} path="/" />

    <Route exact component={CompanyList} path="/empresas" />
    <Route exact component={CompanyForm} path="/empresas/novo" />
    <Route exact component={CompanyForm} path="/empresas/editar/:id" />

    <Route exact component={UserList} path="/usuarios" />
    <Route exact component={UserForm} path="/usuarios/novo" />
    <Route exact component={UserForm} path="/usuarios/editar/:id" />
    <Route exact component={UserPassword} path="/senha/:token" />

    <Route exact component={DefenseList} path="/defesas" />
    <Route exact component={DefenseForm} path="/defesas/novo" />
    <Route exact component={DefenseForm} path="/defesas/editar/:id" />

    <Route exact component={DefenseTextList} path="/textos-de-defesa" />
    <Route exact component={DefenseTextForm} path="/textos-de-defesa/novo" />
    <Route
      exact
      component={DefenseTextForm}
      path="/textos-de-defesa/editar/:id"
    />

    <Route
      exact
      component={SupportingDocumentList}
      path="/documentos-comprobatorios"
    />
    <Route
      exact
      component={SupportingDocumentForm}
      path="/documentos-comprobatorios/novo"
    />
    <Route
      exact
      component={SupportingDocumentForm}
      path="/documentos-comprobatorios/editar/:id"
    />

    <Route exact component={DocumentList} path="/abis" />
    <Route exact component={DocumentUpload} path="/abis/novo" />
    <Route exact component={DocumentDetails} path="/abis/detalhes/:id" />

    <Route exact component={LogList} path="/logs" />
    <Route exact component={LogDetails} path="/logs/detalhes/:id" />

    <Route exact component={ReapList} path="/recolhimento" />
    <Route exact component={ReapForm} path="/recolhimento/novo" />
    <Route exact component={ReapForm} path="/recolhimento/detalhes/:id" />

    <Route exact component={Expired} path="/expirou" />

    <Route
      exact
      component={PolicyAndTerm}
      path="/politicadeprivacidadeetermos"
    />

    <Route component={NotFound} path="/*" />
  </PrivateLayout>
);

export default PrivateRoutes;
