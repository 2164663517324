import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import jwt_decode from 'jwt-decode';

import {
  IAuthCompany,
  IAuthData,
  IAuthUser,
  ITokenDecoded,
} from '../models/auth';

interface AuthContextData {
  loggedUser: IAuthUser;
  loggedCompany: IAuthCompany;
  isAuthenticated(): boolean;
  setAuthData(data: IAuthData): void;
  validateToken(): void;
  logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const getAuthData = (): IAuthData => {
    const data = localStorage.getItem('@authData');
    try {
      return JSON.parse(data);
    } catch (_) {
      return null;
    }
  };

  const [authData, setAuthData] = useState<IAuthData>(getAuthData());

  useEffect(() => {
    if (authData === null) {
      localStorage.clear();
    } else {
      localStorage.setItem('@authData', JSON.stringify(authData));
    }
  }, [authData]);

  const isAuthenticated = useCallback((): boolean => !!authData, [authData]);

  const validateToken = useCallback((): void => {
    const token = authData?.token;
    if (token) {
      const { exp } = jwt_decode<ITokenDecoded>(token);
      const now = new Date().getTime();
      const isValid = exp * 1000 > now;
      if (isValid) return;
    }
    window.location.replace('/expirou');
  }, [authData]);

  const logout = (): void => {
    setAuthData(null);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedUser: authData?.user,
        loggedCompany: authData?.company,
        isAuthenticated,
        setAuthData,
        validateToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
