import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import {
  mdiEye,
  mdiShieldEdit,
  mdiMagnify,
  mdiListStatus,
  mdiShieldRemove,
} from '@mdi/js';

import { useAuth } from '../../../../../../hooks/auth';
import { useSnackbar } from '../../../../../../hooks/snackbar';
import {
  CompanyTypeEnum,
  AttendanceStatusEnum,
} from '../../../../../../utils/enum';
import { cnpjMask } from '../../../../../../utils/masks';
import {
  formatDate,
  formatMonetaryValue,
} from '../../../../../../utils/helpers/format-helper';
import {
  attendanceStatusArray,
  attendanceStatusFilter,
} from '../../../../../../utils/helpers/array-helper';

import {
  DataSeparateTitle,
  StatusContainer,
} from '../../../../../../styles/global';
import AsyncTable from '../../../../../../components/Tables/AsyncTable';
import {
  Input as InputFilter,
  // DatePicker as DatePickerFilter,
  Select,
} from '../../../../../../components/Filters';
import { Card, Input, DatePicker } from '../../../../../../components/Form';
import palette from '../../../../../../styles/theme/palette';
import Dialog from '../../../../../../components/Dialog';
import { ContentMainProcedure, ContentDate } from './styles';

import { IBasicFilterId } from '../../../../../../models/api';
import apiDefense from '../../../../../Defense/services';
import api from '../../../../services';
import { IAttendance, IAttendanceList, IDocument } from '../../../../models';

import AttendanceModal from '../AttendanceModal';

interface Props {
  document: IDocument;
  onSelectAttendance: (attendance: IAttendance) => void;
}

const DocumentStep: React.FC<Props> = ({ document, onSelectAttendance }) => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const { setMessage, setErrorMessage } = useSnackbar();

  const formRef = useRef(null);
  const [auxAttendance, setAuxAttendance] = useState<IAttendanceList>();
  const [refreshTable, setRefreshTable] = useState(false);
  // const [initialDate, setInitialDate] = useState<Date>();
  // const [endDate, setEndDate] = useState<Date>();
  const [filters, setFilters] = useState<IBasicFilterId>({
    id: document?.id,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const renderStatus = ({
    id_Status,
    statusName,
  }: IAttendanceList): JSX.Element | string => {
    if (!Object.values(AttendanceStatusEnum).includes(id_Status)) return '';
    const { color } = attendanceStatusArray.find(({ id }) => id === id_Status);
    return (
      <StatusContainer color={color}>
        <span>{statusName}</span>
      </StatusContainer>
    );
  };

  const renderInitialDate = ({
    initialDateAttendance,
  }: IAttendanceList): JSX.Element => {
    return <ContentDate>{formatDate(initialDateAttendance)}</ContentDate>;
  };

  const renderEndDate = ({
    endDateAttendance,
  }: IAttendanceList): JSX.Element => {
    return <ContentDate>{formatDate(endDateAttendance)}</ContentDate>;
  };

  const renderMainProcedure = ({
    mainProcedure,
  }: IAttendanceList): JSX.Element => {
    return (
      <ContentMainProcedure>
        {mainProcedure && mainProcedure.descriptionProcedure}
      </ContentMainProcedure>
    );
  };

  const columns: Column<IAttendanceList>[] = [
    {
      title: 'Nº Ordem',
      field: 'orderNumber',
    },
    {
      title: 'Tipo',
      field: 'type',
    },
    {
      title: 'Nº Processo',
      render: () => document?.processNumber,
    },
    {
      title: 'Nº Atendimento',
      field: 'number',
    },
    {
      title: 'Competência',
      field: 'competence',
    },
    {
      title: 'Data Inicial',
      render: renderInitialDate,
    },
    {
      title: 'Data Final',
      render: renderEndDate,
    },
    {
      title: 'Procedimento Principal',
      render: renderMainProcedure,
    },
    {
      title: 'Status',
      render: renderStatus,
    },
  ];
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleDefense = async ({
    id,
    id_Defense,
  }: IAttendanceList): Promise<void> => {
    try {
      setIsLoading(true);
      if (id_Defense) {
        const defense = await apiDefense.getDefenseById(id_Defense);
        history.push({
          pathname: `/defesas/editar/${id_Defense}`,
          state: defense,
        });
      } else {
        console.log(id);
        let attendance = await api.getAttendanceById(id);
        attendance = { ...attendance, currentPage, documentId: document?.id };
        history.push({
          pathname: `/defesas/novo/`,
          state: { document, attendance },
        });
      }
    } catch (err) {
      setErrorMessage(
        `Houve um erro ao buscar os dados ${
          id_Defense ? 'da defesa' : 'do atendimento'
        }.`,
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (attendance: IAttendanceList): void => {
    setAuxAttendance(attendance);
    setRefreshTable(false);
    setIsShowDialog(true);
  };

  // const handleOpenModal = (attendance: IAttendanceList): void => {
  //   setAuxAttendance(attendance);
  //   setRefreshTable(false);
  //   setIsShowModal(true);
  // };

  const handleDetails = async ({ id }: IAttendanceList): Promise<void> => {
    try {
      setIsLoading(true);
      const attendance = await api.getAttendanceById(id);

      onSelectAttendance(attendance);
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao buscar os dados do atendimento.',
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const actions: (
    | Action<IAttendanceList>
    | ((rowData: IAttendanceList) => Action<IAttendanceList>)
  )[] = [
    ({ id_Defense }) => ({
      icon: () => <Icon size={1} path={mdiShieldEdit} />,
      tooltip: !id_Defense ? 'Defender' : 'Editar Defesa',
      hidden: loggedCompany.type === CompanyTypeEnum.Requester,
      disabled: isLoading,
      onClick: (_, attendance) => handleDefense(attendance as IAttendanceList),
    }),
    ({ id_Status }) => ({
      icon: () => <Icon size={1} path={mdiShieldRemove} />,
      tooltip: 'Sem Defesa',
      hidden: loggedCompany.type === CompanyTypeEnum.Requester,
      disabled:
        isLoading ||
        id_Status === AttendanceStatusEnum.WithoutDefense ||
        id_Status === AttendanceStatusEnum.Protocoled,
      onClick: (_, attendance) =>
        handleOpenDialog(attendance as IAttendanceList),
    }),
    // * Button commented isn't used by users at moment *
    // {
    //   icon: () => <Icon size={1} path={mdiViewList} />,
    //   tooltip: 'Informações Adicionais',
    //   disabled: isLoading,
    //   onClick: (_, attendance) =>
    //     handleOpenModal(attendance as IAttendanceList),
    // },
    {
      icon: () => <Icon size={1} path={mdiEye} />,
      tooltip: 'Visualizar',
      disabled: isLoading,
      onClick: (_, attendance) => handleDetails(attendance as IAttendanceList),
    },
  ];

  const handleTerm = (term: string): void => {
    setFilters({ ...filters, term });
  };

  // const handleInitialDate = (date: Date): void => {
  //   setInitialDate(date);

  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setFilters({
  //     ...filters,
  //     initialDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
  //   });
  // };

  // const handleEndDate = (date: Date): void => {
  //   setEndDate(date);

  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setFilters({
  //     ...filters,
  //     endDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
  //   });
  // };

  const handleStatus = (
    event: React.ChangeEvent<{
      name?: string;
      value: number;
    }>,
  ): void => {
    const { value } = event.target;
    setFilters({ ...filters, status: value });
  };

  const handleSetNoDefense = async (): Promise<void> => {
    try {
      setIsLoading(true);

      setIsShowDialog(false);

      await api.putAttendanceStatus(
        auxAttendance.id,
        AttendanceStatusEnum.WithoutDefense,
      );

      setMessage({
        type: 'success',
        message: 'Status do atendimento alterado com sucesso.',
      });

      setRefreshTable(true);
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao alterar o status do atendimento.',
        err,
        true,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = async (refresh: boolean): Promise<void> => {
    setRefreshTable(refresh);
    setIsShowModal(false);
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os atendimentos.', err, true);
  };
  return (
    <React.Fragment>
      <Card
        formRef={formRef}
        initialData={document}
        showSubmitButton={false}
        title={`Você está visualizando a "ABI ${document?.numberABI}"`}
      >
        <Input sm={4} name="numberABI" label="Número da ABI" disabled />

        <Input
          sm={4}
          name="processNumber"
          label="Número do processo"
          disabled
        />

        <Input sm={4} name="tradeNumber" label="Número do ofício" disabled />

        <Input sm={4} name="companyName" label="Nome da operadora" disabled />

        <Input sm={4} name="cnpj" label="CNPJ" mask={cnpjMask} disabled />

        <DatePicker
          sm={4}
          name="registerDate"
          label="Data / Hora registro da transação"
          disabled
        />

        <Input sm={3} name="ansRegister" label="Registro ANS" disabled />

        <Input sm={3} name="statusName" label="Status" disabled />

        <Input
          sm={3}
          name="quantityProcess"
          label="Quantidade de processos"
          disabled
        />

        <Input
          sm={3}
          label="Valor total dos processos"
          value={formatMonetaryValue(document?.totalProcessValue)}
          disabled
        />

        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <DataSeparateTitle>Atendimentos</DataSeparateTitle>
        </Grid>

        <Grid item xs={12} md={8}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            onChange={handleTerm}
          />
        </Grid>

        <Select
          md={4}
          startIcon={mdiListStatus}
          label="Status"
          placeholder="Selecione uma opção..."
          options={attendanceStatusFilter}
          optionName="name"
          optionValue="id"
          onChange={handleStatus}
          defaultValue={0}
          disabled={isLoading}
        />

        {/* <DatePickerFilter
          sm={4}
          label="De"
          valueInput={initialDate}
          onChange={handleInitialDate}
          placeholder="Data Inicial"
        />

        <DatePickerFilter
          sm={4}
          label="Até"
          valueInput={endDate}
          minDate={initialDate}
          onChange={handleEndDate}
          placeholder="Data Final"
        /> */}

        <Grid item xs={12}>
          <AsyncTable
            noElevation
            keyBackupFilters="Attendances"
            actions={actions}
            columns={columns}
            dataKey="attendances"
            dataUrl="/attendance"
            filters={filters}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            onError={handleError}
            refresh={refreshTable}
          />
        </Grid>

        <Dialog
          title="Atendimento Sem Defesa"
          message={`Você tem certeza que deseja marcar esse atendimento como "Sem Defesa"?`}
          open={isShowDialog}
          onSubmit={handleSetNoDefense}
          onClose={() => setIsShowDialog(false)}
          colorTitle={palette.secondary.main}
          colorButton={palette.success.main}
          colorButtonTwo={palette.error.main}
        />
      </Card>

      <AttendanceModal
        attendance={auxAttendance}
        isVisible={isShowModal}
        onClose={handleCloseModal}
      />
    </React.Fragment>
  );
};

export default DocumentStep;
