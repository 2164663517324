import React from 'react';
import DropZone from 'react-dropzone';
import filesize from 'filesize';
import Icon from '@mdi/react';
import { mdiProgressUpload, mdiUpload, mdiUploadOff } from '@mdi/js';
import { DropContainer, UploadMessage } from './styles';
import { IFile } from '../../../../models';

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
  onUpload: (file: IFile) => void;
}

const UploadPdf: React.FC<Props> = ({ setFiles, onUpload }) => {
  // const [uploadedFile, setUploadedFile] = useState<IFile | null>(null);
  const renderDragMessage = (
    isDragActive: boolean,
    isDragReject: boolean,
  ): JSX.Element => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <Icon size={1.3} path={mdiProgressUpload} /> <b>Adicione</b>&nbsp;ou
          arraste os arquivos aqui!&nbsp;
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <Icon size={1.3} path={mdiUploadOff} /> Arquivo não suportado!&nbsp;
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        <Icon size={1.3} path={mdiUpload} /> Solte os arquivos aqui!&nbsp;
      </UploadMessage>
    );
  };

  const handleAdd = (files: IFile[]): void => {
    setFiles(prev => (prev ? [...prev, ...files] : files));
  };

  const handleDropAccepted = <T extends File>(files: T[]): void => {
    const uploadFiles = files.map(
      file =>
        ({
          file,
          name: file.name,
          size: filesize(file.size),
          progress: 0,
          status: 'loading',
        } as IFile),
    );

    handleAdd(uploadFiles);
    onUpload?.(uploadFiles[0]);
  };

  return (
    <DropZone
      accept="application/pdf"
      onDropAccepted={handleDropAccepted}
      maxSize={18 * 1024 * 1024}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <div>
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </div>
        </DropContainer>
      )}
    </DropZone>
  );
};

export default UploadPdf;
