import styled from 'styled-components';

interface ValidProps {
  passwordIsValid: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${props => props.theme.palette.background.paper};

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    background-image: linear-gradient(
      65deg,
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.secondary.main}
    );
    flex-direction: column-reverse;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex: 1;
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  h1 {
    width: 100%;
    text-align: center;
  }

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    height: 70%;
  }
`;

export const PasswordContent = styled.div`
  height: 100%;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    background-image: linear-gradient(
      135deg,
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.secondary.main}
    );
  }
  text-align: center;

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: ${props => props.theme.palette.background.paper};
  }

  p {
    font-size: 24px;
    font-weight: 300;
    color: ${props => props.theme.palette.background.paper};
  }

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const RulesPasswordText = styled.li<ValidProps>`
  border-radius: 6px;
  margin: 5px;
  padding: 3px 0;
  display: table;
  position: relative;

  ${props =>
    props.passwordIsValid
      ? `text-decoration: line-through; color: #4CAF50;`
      : ''}
`;
