import * as Yup from 'yup';

export const handleFormError = <T>(
  err: T,
  formRef: React.MutableRefObject<any>,
  defaultMessage: string,
  setErrorMessage: <T>(
    defaultMessage: string,
    error?: T,
    showAdminMessage?: boolean,
  ) => void,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
): void => {
  if (err instanceof Yup.ValidationError) {
    const errorMessages = {};

    err.inner.forEach(error => {
      errorMessages[error.path] = error.message;
    });

    formRef.current.setErrors(errorMessages);
  } else {
    setErrorMessage(defaultMessage, err, true);
  }

  setIsLoading(false);
};
