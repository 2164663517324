/* eslint-disable array-callback-return */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import {
  FormControl,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import { useSnackbar } from '../../../hooks/snackbar';
// import { IMessageSnackbar } from '../../../models/snackbar';
import { AutocompleteIcon, Container, ChipContainer } from './styles';
import { IDefenseText } from '../../../containers/DefenseText/models';

type FocusEvent = React.FocusEvent<HTMLTextAreaElement | HTMLDivElement>;
type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const icon = <AutocompleteIcon path={mdiCheckboxBlankOutline} tint="action" />;

const checkedIcon = (
  <AutocompleteIcon path={mdiCheckboxMarked} tint="secondary" />
);

interface Props<T extends object>
  extends AutocompleteProps<T, boolean, boolean, boolean> {
  optionName: string | ((option: T) => string);
  optionValue: string;
  label?: string;
  name?: string;
  placeholder?: string;
  startIcon?: string;
  textState?: string;
  onValueChange?: (
    value: string | T | NonNullable<T> | (string | T)[],
    isRemoving?: boolean,
    isValid?: boolean,
    textRemoved?: IDefenseText[],
    oldValue?: string | T | NonNullable<T> | (string | T)[],
  ) => void;
  setTextState?: (value: string) => void;
  onFocus?: (e: FocusEvent) => void;
  defenseText?: boolean;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const AutocompleteForm = <T extends object>({
  optionName,
  optionValue,
  options = [],
  label = null,
  name = null,
  placeholder = null,
  startIcon = null,
  loading = false,
  multiple = false,
  disableCloseOnSelect = null,
  textState = null,
  onValueChange = null,
  onFocus = null,
  defenseText = false,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  ...rest
}: Props<T>): JSX.Element => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);
  const [value, setValue] = useState<T | T[]>(multiple ? [] : null);
  const { setErrorMessage } = useSnackbar();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        if (!value) return multiple ? [] : null;
        if (optionValue) {
          if (multiple) return (value as T[]).map(obj => obj[optionValue]);
          return (value as T)[optionValue];
        }
        return value;
      },
      setValue: (_, val) => {
        setValue(val || (multiple ? [] : null));
      },
    });
  }, [fieldName, value, registerField, optionValue, multiple]);

  const handleFocus = (e: FocusEvent): void => {
    clearError();
    if (onFocus) onFocus(e);
  };

  const handleOptionLabel = (option: T): string => {
    if (typeof optionName === 'string') {
      return option[optionName];
    }
    return optionName(option);
  };

  const handleChange = (
    newValue: string | T | NonNullable<T> | (string | T)[],
  ): void => {
    if (defenseText) {
      try {
        let isValid = false;
        const textRemoved = [];
        const lengthNewValue = newValue as IDefenseText[];
        const lengthValue = value as IDefenseText[];

        if (value.toString().length > newValue.toString().length) {
          const removed = lengthValue.filter((item: IDefenseText) => {
            return !lengthNewValue.includes(item);
          });

          removed.map((newText: IDefenseText) => {
            textRemoved.push({ id: newText.id, text: newText.text });
          });
        }

        if (textState.includes('@')) {
          const size = textState.match(/@[{a-zA-Z}]+/g);
          if (size.length > 0) {
            isValid = true;
          }
        } else {
          (newValue as IDefenseText[])?.forEach((text, index) => {
            if (lengthNewValue.length - 1 === index) {
              if (text.text.includes('@')) {
                // const size2 = text.text.match(/@[a-zA-Z\u00C0-\u00FF]+/g);
                // const size2 = text.text.match(/@<[a-zA-Z ]+/g);
                const size = text.text.match(/@[{a-zA-Z}]+/g);

                if (size.length > 0) {
                  isValid = true;
                }
              }
            }
          });
        }

        if (isValid || value.toString().length > newValue.toString().length) {
          setValue(newValue as T | T[]);
        }

        if (onValueChange)
          onValueChange(
            newValue,
            value.toString().length > newValue.toString().length,
            isValid,
            textRemoved,
            value,
          );
      } catch (err) {
        setErrorMessage('Ocorreu um erro ao buscar as variáveis', err, true);
      }
    } else {
      setValue(newValue as T | T[]);

      if (onValueChange)
        onValueChange(
          newValue,
          // value.toString().length > newValue.toString().length,
        );
    }
  };

  return (
    <Container item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <FormControl fullWidth>
        <Autocomplete
          {...rest}
          options={options}
          multiple={multiple}
          disableCloseOnSelect={
            multiple && (disableCloseOnSelect === null || disableCloseOnSelect)
          }
          openText="Abrir"
          closeText="Fechar"
          clearText="Limpar"
          noOptionsText="Sem opções"
          loadingText="Carregando..."
          loading={loading}
          value={value}
          onChange={(_, newValue) => handleChange(newValue as T | T[])}
          getOptionLabel={handleOptionLabel}
          defaultValue={defaultValue}
          renderOption={(option, { selected }) => (
            <div style={{ width: '100%', overflow: 'hidden' }}>
              {multiple && (selected ? checkedIcon : icon)}
              {handleOptionLabel(option)}
            </div>
          )}
          renderInput={params => (
            <TextField
              {...params}
              ref={inputRef}
              variant="outlined"
              error={!!error}
              onFocus={handleFocus}
              label={label}
              placeholder={placeholder}
              helperText={error}
              FormHelperTextProps={{
                style: {
                  position: 'absolute',
                  bottom: -19,
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: startIcon ? (
                  <React.Fragment>
                    <InputAdornment position="start">
                      <Icon size={1} path={startIcon} color="action" />
                    </InputAdornment>
                    <ChipContainer>
                      {params.InputProps.startAdornment}
                    </ChipContainer>
                  </React.Fragment>
                ) : (
                  params.InputProps.startAdornment
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress
                        color="secondary"
                        size={20}
                        style={{ marginRight: 32 }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Container>
  );
};

export default AutocompleteForm;
