import React from 'react';
import Card from '../../Card';
import Form, { FormProps } from '../Form';

interface Props extends FormProps {
  title?: string;
  overflow?: boolean;
}

const CardForm: React.FC<Props> = ({ title, overflow = false, ...rest }) => (
  <Card title={title} overflow={overflow}>
    <Form {...rest} />
  </Card>
);

export default CardForm;
