import React, { createContext, useContext } from 'react';

interface BackupFiltersContextData {
  setBackup(filter: unknown, key?: string): void;
  clearBackup(key?: string): void;
  getBackup(key?: string): any;
}

const BackupFiltersContext = createContext({} as BackupFiltersContextData);

const KEY = '@backupFilters';

const BackupFiltersProvider: React.FC = ({ children }) => {
  const setBackup = (filter: unknown, key = ''): void => {
    localStorage.setItem(KEY + key, JSON.stringify(filter));
  };

  const clearBackup = (key = ''): void => {
    localStorage.removeItem(KEY + key);
  };

  const getBackup = (key = ''): any => {
    const backup = localStorage.getItem(KEY + key);
    if (backup) {
      return JSON.parse(backup);
    }
    return {};
  };

  return (
    <BackupFiltersContext.Provider
      value={{ setBackup, clearBackup, getBackup }}
    >
      {children}
    </BackupFiltersContext.Provider>
  );
};

const useBackupFilters = (): BackupFiltersContextData => {
  const context = useContext(BackupFiltersContext);
  if (!context) {
    throw new Error(
      'useBackupFilters must be used within an BackupFiltersProvider',
    );
  }
  return context;
};

export { BackupFiltersProvider, useBackupFilters };
