/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Grid, InputAdornment } from '@material-ui/core';
import Icon from '@mdi/react';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import TextField from '@material-ui/core/TextField';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';

import moment from 'moment';
import 'moment/locale/pt-br';

type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props {
  name: string;
  labelStyle?: React.CSSProperties;
  valueInput?: Date;
  inputFormat?: string;
  mask?: string;
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  hidden?: boolean;
  startIcon?: string;
  endIcon?: string | React.ReactElement;
  setText?(text: string): void;
  onChange?(date: any, keyboardInputValue?: string): void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const DatePickerForm: React.FC<Props> = ({
  name,
  labelStyle = {},
  valueInput,
  inputFormat,
  mask,
  maxDate,
  minDate,
  placeholder,
  label = null,
  disabled = false,
  hidden = false,
  startIcon = null,
  onChange = null,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
}) => {
  moment.locale('pt-br');
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ({ value }) => value,
      setValue: (ref, value) => {
        ref.value = value;
      },
    });
  }, [fieldName, inputRef, registerField]);

  const handleChange = (date: unknown, keyboardInputValue?: string): void => {
    if (onChange) {
      if (typeof date === 'object') {
        try {
          const newDate = (date as moment.Moment).format();
          onChange(newDate, keyboardInputValue);
          return;
          // eslint-disable-next-line no-empty
        } catch (_) {}
      }
      onChange(date, keyboardInputValue);
    }
  };

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ display: hidden ? 'none' : 'flex' }}
    >
      <LocalizationProvider
        dateAdapter={MomentAdapter}
        locale={moment.locale('pt-br')}
      >
        <DatePicker
          ref={inputRef}
          label={label}
          value={valueInput || defaultValue || ''}
          onChange={handleChange}
          onError={value => value}
          inputFormat={inputFormat || 'DD-MM-YYYY'}
          mask={mask || '__-__-____'}
          renderInput={props => (
            <TextField
              {...props}
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: label ? null : 'none',
                  ...labelStyle,
                },
              }}
              FormHelperTextProps={{
                style: {
                  position: 'absolute',
                  bottom: -19,
                },
              }}
              placeholder={placeholder}
              InputProps={{
                ...props.InputProps,
                startAdornment: startIcon && (
                  <InputAdornment position="start">
                    <Icon size={1} path={startIcon} color="action" />
                  </InputAdornment>
                ),
                endAdornment: disabled ? null : props.InputProps.endAdornment,
              }}
            />
          )}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default DatePickerForm;
