import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import Toolbar from './Toolbar';
import Navbar from './Navbar';
import { Container, RouterContainer } from './styles';

const PrivateLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { validateToken } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (pathname !== '/expirou' && pathname.indexOf('/senha/') === -1)
      validateToken();
  }, [pathname, validateToken]);

  return pathname !== '/expirou' && pathname.indexOf('/senha/') === -1 ? (
    <Container navbarIsOpen={isOpen}>
      <Toolbar isOpen={isOpen} onOpen={() => setIsOpen(true)} />
      <Navbar isOpen={isOpen} onClose={() => setIsOpen(false)} />

      <RouterContainer>
        <Switch>{children}</Switch>
      </RouterContainer>
    </Container>
  ) : (
    <Switch>{children}</Switch>
  );
};

export default PrivateLayout;
