import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const IntegrationSuccess = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sucesso</DialogTitle>
      <DialogContent>O processamento foi realizado com sucesso.</DialogContent>
    </Dialog>
  );
};

export default IntegrationSuccess;
