import React, { useRef } from 'react';

import { Card, Input } from '../../../../../../components/Form';
import { formatMonetaryValue } from '../../../../../../utils/helpers/format-helper';

import { IProcedure } from '../../../../models';

interface Props {
  procedure: IProcedure;
}

const ProcedureStep: React.FC<Props> = ({ procedure }) => {
  const formRef = useRef(null);

  return (
    <React.Fragment>
      <Card
        formRef={formRef}
        initialData={procedure}
        showSubmitButton={false}
        title={`Você está visualizando o procedimento "${procedure?.codeProcedure}"`}
      >
        <Input sm={4} name="typeProcedure" label="Tipo" disabled />

        <Input sm={4} name="codeProcedure" label="Código" disabled />

        <Input sm={4} name="quantityProcedure" label="Quantidade" disabled />

        <Input
          sm={6}
          label="Valor"
          value={formatMonetaryValue(procedure?.valueProcedure)}
          disabled
        />

        <Input sm={6} name="descriptionProcedure" label="Descrição" disabled />
      </Card>
    </React.Fragment>
  );
};

export default ProcedureStep;
