import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { mdiNumeric, mdiCurrencyUsd, mdiTextSubject, mdiText } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import { Card, Input, DatePicker } from '../../../../components/Form';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import api from '../../services';
import { IReap } from '../../models';

import { monetaryValue, number } from '../../../../utils/regex-validations';

const ReapForm: React.FC = () => {
  const history = useHistory();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);

  const reapDetail = history.location.state as IReap;
  const [isLoading, setIsLoading] = useState(false);
  const [nn, setNN] = useState<string>();
  const [valueReap, setValueReap] = useState<number>();
  const [documentDate, setDocumentDate] = useState<Date>();
  const [dueDate, setDueDate] = useState<Date>();

  useEffect(() => {
    if (history.location.pathname.includes('detalhes') && !reapDetail)
      history.replace('/recolhimento');
  }, [reapDetail, history]);

  const handleSubmit = async (data: IReap): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        nm: Yup.string()
          .required('O campo nosso número é obrigatório')
          .max(100, 'Digite o nosso número com no máximo 100 caracteres'),
        documentDate:
          !documentDate &&
          Yup.string().required('O campo data do documento obrigatório'),
        dueDate:
          !dueDate &&
          Yup.string().required('O campo data de vencimento obrigatório'),
        value: Yup.string().required('O valor do recolhimento é obrigatório'),
        operatorName: Yup.string().required(
          'O nome da operadora é obrigatório',
        ),
        description: Yup.string()
          .required('A descrição é obrigatório')
          .max(300, 'Digite a descrição com no máximo 300 caracteres'),
      });
      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);
      const dataReap: IReap = {
        ...data,
        value: Number(valueReap),
        documentDate,
        dueDate,
      };

      await api.postReap(dataReap);
      setMessage({
        type: 'success',
        message: 'Recolhimento salvo com sucesso',
      });
      history.goBack();
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar o recolhimento, entre em contato com o administrador.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title={`${reapDetail ? 'Detalhes' : 'Criar'} Recolhimento`}
        subtitle={`${
          reapDetail
            ? 'Aqui é mostrado os dados do recolhimento'
            : 'Preencha os campos para cadastrar'
        }`}
      />

      <Card
        formRef={formRef}
        onSubmit={handleSubmit}
        showSubmitButton={!reapDetail}
        initialData={reapDetail}
        isLoading={isLoading}
        title={
          reapDetail
            ? `Você selecionou o recolhimento "${reapDetail.id}" para visualizar`
            : `Você clicou para cadastrar um novo recolhimento`
        }
      >
        <Input
          sm={6}
          name="nm"
          value={nn}
          startIcon={mdiNumeric}
          onChange={e => setNN(number(e.target.value))}
          label="Nosso Número"
          placeholder="Informe o Nosso Número"
          disabled={isLoading || !!reapDetail}
        />

        <Input
          sm={6}
          name="operatorName"
          startIcon={mdiText}
          label="Nome da operadora"
          placeholder="Informe também o nome da operadora"
          disabled={isLoading || !!reapDetail}
        />

        <DatePicker
          sm={4}
          name="documentDate"
          valueInput={documentDate}
          onChange={setDocumentDate}
          label="Data Documento"
          disabled={isLoading || !!reapDetail}
        />

        <DatePicker
          sm={4}
          name="dueDate"
          valueInput={dueDate}
          onChange={setDueDate}
          label="Data Vencimento"
          disabled={isLoading || !!reapDetail}
        />

        <Input
          sm={4}
          name="value"
          startIcon={mdiCurrencyUsd}
          label="Valor Recolhimento"
          placeholder="e o Valor Recolhimento?"
          value={valueReap}
          onChange={event => setValueReap(monetaryValue(event.target.value))}
          disabled={isLoading || !!reapDetail}
        />

        <Input
          sm={12}
          multiline
          name="description"
          startIcon={mdiTextSubject}
          label="Descrição Detalhada"
          placeholder="Informe a descrição"
          disabled={isLoading || !!reapDetail}
        />
      </Card>
    </React.Fragment>
  );
};

export default ReapForm;
