import palette from '../../styles/theme/palette';
import { ColorArray, BasicArray } from '../../models/array';

import {
  AttendanceStatusEnum,
  CompanyTypeEnum,
  DocumentStatusEnum,
  PetitionTypeEnum,
  UserProfileEnum,
} from '../enum';

const attendanceStatusArray: ColorArray[] = [
  { id: AttendanceStatusEnum.Open, color: palette.primary.main },
  { id: AttendanceStatusEnum.Filled, color: palette.secondary.main },
  { id: AttendanceStatusEnum.Defended, color: palette.success.main },
  { id: AttendanceStatusEnum.WithoutDefense, color: palette.error.main },
  { id: AttendanceStatusEnum.Protocoled, color: palette.info.main },
];

const attendanceStatusFilter: BasicArray[] = [
  { id: 0, name: 'Todos' },
  { id: AttendanceStatusEnum.Open, name: 'Aberto' },
  { id: AttendanceStatusEnum.Filled, name: 'Preenchido' },
  { id: AttendanceStatusEnum.Defended, name: 'Defendido' },
  { id: AttendanceStatusEnum.WithoutDefense, name: 'Sem Defesa' },
  { id: AttendanceStatusEnum.Protocoled, name: 'Protocolado' },
];

const companyTypeArray: BasicArray[] = [
  { id: CompanyTypeEnum.Admin, name: 'Administrador' },
  { id: CompanyTypeEnum.Controller, name: 'Auditor' },
  { id: CompanyTypeEnum.Requester, name: 'Solicitante' },
];

const documentStatusArray: ColorArray[] = [
  { id: DocumentStatusEnum.Open, text: 'Aberto', color: palette.primary.main },
  {
    id: DocumentStatusEnum.InProgress,
    text: 'Em andamento',
    color: palette.secondary.main,
  },
  {
    id: DocumentStatusEnum.Done,
    text: 'Concluído',
    color: palette.success.main,
  },
];

const documentStatusFilter: BasicArray[] = [
  { id: 0, name: 'Todos' },
  { id: DocumentStatusEnum.Open, name: 'Aberto' },
  { id: DocumentStatusEnum.InProgress, name: 'Em andamento' },
  { id: DocumentStatusEnum.Done, name: 'Concluído' },
];

const petitionTypeArray: BasicArray[] = [
  { id: PetitionTypeEnum.Impeachment, name: 'Impugnação' },
  { id: PetitionTypeEnum.Resource, name: 'Recurso' },
];

const userProfileArray: BasicArray[] = [
  { id: UserProfileEnum.Admin, name: 'Administrador' },
  { id: UserProfileEnum.Normal, name: 'Normal' },
];

export {
  attendanceStatusArray,
  attendanceStatusFilter,
  companyTypeArray,
  documentStatusArray,
  documentStatusFilter,
  petitionTypeArray,
  userProfileArray,
};
