/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import apiAns from '../../../../services/api/ans';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      display: 'grid',
      minWidth: 300,
      flexDirection: 'column',
      color: '#252f60',
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#252f60',
      },
      '& .MuiInputLabel-root': {
        color: '#252f60',
        backgroundColor: '#f5f5f5',
      },
    },
    dropdownContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default function SimpleSelect({ onSelectChange, isDropdownEmpty }) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [age, setAge] = useState('');

  useEffect(() => {
    apiAns
      .get('/defense/document-type-ans')
      .then(response => response.data)
      .then(data => {
        setOptions(data);
      })
      .catch(error => {
        console.error('Erro ao buscar opções ', error);
      });
  }, []);

  const handleChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
  ) => {
    const selectedIndexValue = event.target.value as string;

    setAge(selectedIndexValue);

    const selectedItem = options.find(
      (option, index) => index === Number(selectedIndexValue),
    );

    if (selectedItem) {
      onSelectChange({
        id: selectedItem?.id,
        nome: selectedItem?.nome,
        index_type_document: selectedIndexValue,
      });
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Selecione o Tipo do Documento</InputLabel>
      <Select
        value={age}
        onChange={handleChange}
        required
        error={isDropdownEmpty}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option.nome}
          </MenuItem>
        ))}
      </Select>
      {isDropdownEmpty && (
        <FormHelperText style={{ color: 'red' }}>
          Por favor, selecione uma opção no dropdown.
        </FormHelperText>
      )}
    </FormControl>
  );
}
