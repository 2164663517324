import { IAuthData } from '../models/auth';

interface Authorization {
  Authorization: string;
}

const getAuthHeader = (): Authorization => {
  const data = localStorage.getItem('@authData');
  if (data && data !== 'undefined') {
    const { token }: IAuthData = JSON.parse(data);
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
  }
  return { Authorization: '' };
};

const updateToken = (token: string): void => {
  if (!token) return;
  const data = localStorage.getItem('@authData');
  if (data && data !== 'undefined') {
    const authData: IAuthData = JSON.parse(data);
    localStorage.setItem('@authData', JSON.stringify({ ...authData, token }));
  }
};

export { getAuthHeader, updateToken };
