import styled, { css } from 'styled-components';

interface Props {
  status: 'success' | 'error' | 'loading';
}

const lineSuccess = css`
  color: ${props => props.theme.palette.success.main};
  background-color: ${props => props.theme.palette.success.light};
`;

const lineError = css`
  color: ${props => props.theme.palette.error.main};
  background-color: ${props => props.theme.palette.error.light};
`;

const lineLoading = css`
  color: ${props => props.theme.palette.primary.main};
  background-color: ${props => props.theme.palette.primary.light};
`;

export const FileContainer = styled.li<Props>`
  padding: 4px 8px 4px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: ${props => props.theme.shape.borderRadius}px;

  ${props => props.status === 'success' && lineSuccess}
  ${props => props.status === 'error' && lineError}
  ${props => props.status === 'loading' && lineLoading}

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & + li {
    margin-top: 16px;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    margin-right: 16px;
  }

  .MuiLinearProgress-root {
    width: 80px;
    height: 8px;
    border-radius: ${props => props.theme.shape.borderRadius}px;

    .MuiLinearProgress-bar {
      background-color: #3be083;
    }
  }
`;

export const FileActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    text-transform: capitalize;
  }

  .MuiIconButton-root {
    color: #333;
    margin-left: 8px;
    padding: 8px;
  }

  @media (max-width: 420px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
