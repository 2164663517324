import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import { InputAdornment, TextField } from '@material-ui/core';
import Icon from '@mdi/react';

interface Props {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  startIcon?: string;
  endIcon?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  onChange?(value: string): void;
}

const InputFilter: React.FC<Props> = ({
  placeholder = 'Difícil de encontrar? Digíte aqui que vamos encontrar para você...',
  defaultValue = null,
  startIcon,
  endIcon,
  onChange,
  multiline = false,
  rows = 1,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState<string>();
  const [first, setFirst] = useState(true);
  const debouncedValue = useDebounce(value, 50);

  useEffect(() => {
    inputRef.current.value = defaultValue || '';
  }, [defaultValue]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }
    if (onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setValue(e.target.value);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      inputRef={inputRef}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      multiline={multiline}
      rows={multiline ? rows : 1}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">
            <Icon size={1} path={startIcon} color="action" />
          </InputAdornment>
        ) : null,
        endAdornment: endIcon ? (
          <InputAdornment position="end">
            <Icon size={1} path={endIcon} color="action" />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default InputFilter;
