/* eslint-disable no-param-reassign */
import React from 'react';
import SunEditor, { SunEditorReactProps } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import Options, { Mode } from './config';

interface Props extends SunEditorReactProps {
  mode?: Mode;
}

const TextEditor: React.FC<Props> = ({ mode = 'classic', ...rest }) => (
  <SunEditor {...rest} lang="pt_br" setOptions={Options(mode)} />
);

export default TextEditor;
