import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  mdiAccount,
  mdiCardAccountDetails,
  mdiDomain,
  mdiEmail,
} from '@mdi/js';

import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { userProfileArray } from '../../../../utils/helpers/array-helper';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import { Autocomplete, Card, Input, Select } from '../../../../components/Form';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import api from '../../services';
import { IFormUser, IUserCompany } from '../../models';

const UserForm: React.FC = () => {
  const history = useHistory();
  const { loggedUser, logout } = useAuth();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const user = history.location.state as IFormUser;
  const [companies, setCompanies] = useState<IUserCompany[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCompanies = async (): Promise<void> => {
      try {
        setIsLoadingCompanies(true);
        const companyArray = await api.getCompanies();
        setCompanies(companyArray);

        if (user) {
          const map = companyArray.filter(
            company =>
              user.companies.filter(companyId => companyId === company.id)
                .length,
          );
          formRef.current.setFieldValue('companies', map);
        }
      } catch (err) {
        setErrorMessage('Houve um erro ao buscar as empresas.', err, true);
      } finally {
        setIsLoadingCompanies(false);
      }
    };

    getCompanies();
  }, [formRef, setErrorMessage, user]);

  const handleSubmit = async (data: IFormUser): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('O campo nome é obrigatório')
          .max(50, 'Digite um nome com no máximo 50 caracteres'),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('O campo e-mail é obrigatório'),
        profile: Yup.string().required('O campo perfil é obrigatório'),
        companies: Yup.array().required('O campo empresas é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);
      if (user) {
        await api.putUser({ ...data, id: user.id });
      } else {
        await api.postUser(data);
      }

      setMessage({ type: 'success', message: 'Usuário salvo com sucesso' });

      if (
        loggedUser.id_User === user?.id &&
        loggedUser.profile !== user?.profile
      ) {
        logout();
      } else {
        history.goBack();
      }
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar o usuário.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title={`${user ? 'Editar' : 'Criar'} usuário`}
        subtitle={`${
          user
            ? 'Aqui é mostrado os dados do usuário'
            : 'Preencha os campos para cadastrar'
        }`}
      />

      <Card
        formRef={formRef}
        onSubmit={handleSubmit}
        initialData={user}
        isLoading={isLoading}
        title={
          user
            ? `Você selecionou o usuário "${user.name}" para editar`
            : `Você clicou para cadastrar um novo usuário`
        }
      >
        <Input
          sm={6}
          lg={4}
          name="name"
          startIcon={mdiAccount}
          label="Nome"
          placeholder="Qual o nome do usuário?"
          disabled={isLoading}
        />

        <Input
          sm={6}
          lg={4}
          name="email"
          startIcon={mdiEmail}
          label="E-mail"
          placeholder="E o e-mail?"
          disabled={isLoading}
        />

        <Select
          lg={4}
          startIcon={mdiCardAccountDetails}
          name="profile"
          label="Perfil"
          placeholder="Selecione o perfil do usuário..."
          options={userProfileArray}
          optionName="name"
          optionValue="id"
          defaultValue={1}
          disabled={isLoading}
        />

        <Autocomplete
          multiple
          loading={isLoadingCompanies}
          startIcon={mdiDomain}
          label="Empresas"
          placeholder="Digite o código ou nome da empresa para pesquisar..."
          name="companies"
          options={companies}
          optionValue="id"
          optionName={(option: IUserCompany) =>
            `${option.code} - ${option.name}`
          }
          disabled={isLoadingCompanies || isLoading}
          renderInput={null}
        />
      </Card>
    </React.Fragment>
  );
};

export default UserForm;
