const palette = {
  primary: {
    main: '#69DED8',
    light: '#6EC8FB1A',
    dark: '#007184',
    contrastText: '#fff',
  },
  secondary: {
    main: '#252f60',
    light: '#B37DF01A',
    dark: '#091644',
    contrastText: '#fff',
  },
  success: {
    main: '#6FCF97',
    light: '#6FCF971A',
    contrastText: '#fff',
  },
  warning: {
    main: '#ffc107',
    light: '#ffc1071A',
    contrastText: '#fff',
  },
  info: {
    main: '#2196F3',
    light: '#2196F31A',
  },
  error: {
    main: '#EB5757',
    light: '#EB57571A',
  },
  background: {
    default: '#f9f9f9',
    paper: '#F5F5F5',
  },
  text: {
    primary: '#333333',
    secondary: '#7A7A7A',
  },
  action: {
    active: '#7A7A7A',
  },
};

export default palette;
