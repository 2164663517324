import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiMagnify, mdiEye, mdiPlus, mdiListStatus } from '@mdi/js';

import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import {
  documentStatusArray,
  documentStatusFilter,
} from '../../../../utils/helpers/array-helper';
import { CompanyTypeEnum, DocumentStatusEnum } from '../../../../utils/enum';
import { formatCnpj } from '../../../../utils/helpers/format-helper';
import { IDateStatusFilter } from '../../../../models/api';

import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import {
  Input,
  // DatePicker,
  Select,
} from '../../../../components/Filters';
import AsyncTable from '../../../../components/Tables/AsyncTable';
import {
  FabButton,
  FabButtonContainer,
  ListLinesContainer,
  StatusContainer,
} from '../../../../styles/global';

import { IDocument } from '../../models';
import api from '../../services';

const DocumentList: React.FC = () => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const { setErrorMessage } = useSnackbar();
  // const [initialDate, setInitialDate] = useState<Date>();
  // const [endDate, setEndDate] = useState<Date>();
  const [filters, setFilters] = useState<IDateStatusFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const renderStatus = ({ status }: IDocument): JSX.Element | string => {
    if (!Object.values(DocumentStatusEnum).includes(status)) return '';
    const { text, color } = documentStatusArray.find(({ id }) => id === status);
    return (
      <StatusContainer color={color}>
        <span>{text}</span>
      </StatusContainer>
    );
  };

  const renderOperatorCnpj = ({
    companyName,
    cnpj,
  }: IDocument): JSX.Element => (
    <ListLinesContainer>
      {companyName}
      <b>{formatCnpj(cnpj)}</b>
    </ListLinesContainer>
  );

  const renderRegisterDate = ({ registerDate }: IDocument): JSX.Element => (
    <ListLinesContainer>
      {moment(registerDate).format('DD-MM-YYYY')}
      <b>{moment(registerDate).format('HH:mm')}</b>
    </ListLinesContainer>
  );

  const columns: Column<IDocument>[] = [
    {
      title: 'ABI',
      field: 'numberABI',
    },
    {
      title: 'Número do ofício',
      field: 'tradeNumber',
    },
    {
      title: 'Número do processo',
      field: 'processNumber',
    },
    {
      title: 'Nome da operadora / CNPJ',
      render: renderOperatorCnpj,
    },
    {
      title: 'Data / Hora registro da transação',
      render: renderRegisterDate,
    },
    {
      title: 'Status',
      render: renderStatus,
    },
  ];

  const handleDetails = async ({ id }: IDocument): Promise<void> => {
    try {
      setIsLoading(true);
      const document = await api.getDocumentById(id);
      history.push({
        pathname: `/abis/detalhes/${id}`,
        state: document,
      });
    } catch (err) {
      setErrorMessage('Houve um erro ao buscar os dados da ABI.', err);
      setIsLoading(false);
    }
  };

  const actions: Action<IDocument>[] = [
    {
      icon: () => <Icon size={1} path={mdiEye} />,
      tooltip: 'Visualizar',
      onClick: (_, document) => handleDetails(document as IDocument),
    },
  ];

  const handleTerm = (term: string): void => {
    setFilters({ ...filters, term });
  };

  // const handleInitialDate = (date: Date): void => {
  //   setInitialDate(date);

  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setFilters({
  //     ...filters,
  //     initialDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
  //   });
  // };

  // const handleEndDate = (date: Date): void => {
  //   setEndDate(date);

  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setFilters({
  //     ...filters,
  //     endDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
  //   });
  // };

  const handleStatus = (
    event: React.ChangeEvent<{
      name?: string;
      value: number;
    }>,
  ): void => {
    const { value } = event.target;
    setFilters({
      ...filters,
      status: value === 0 ? null : value,
    });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar as ABIs.', err, true);
  };

  const handleCreate = (): void => {
    history.push('/abis/novo');
  };

  return (
    <FabButtonContainer>
      <PageHeader
        title="ABIs"
        subtitle="Aqui você encontra todas as ABIs disponíveis"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Input
            label="Pesquisar"
            startIcon={mdiMagnify}
            onChange={handleTerm}
          />
        </Grid>

        <Select
          md={4}
          startIcon={mdiListStatus}
          label="Status"
          placeholder="Selecione uma opção..."
          options={documentStatusFilter}
          optionName="name"
          optionValue="id"
          onChange={handleStatus}
          defaultValue={0}
        />

        {/* <DatePicker
          sm={4}
          label="De"
          valueInput={initialDate}
          maxDate={endDate}
          onChange={handleInitialDate}
          placeholder="Data Inicial"
        />

        <DatePicker
          sm={4}
          label="Até"
          valueInput={endDate}
          minDate={initialDate}
          onChange={handleEndDate}
          placeholder="Data Final"
        /> */}

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="documents"
            dataUrl="/document"
            filters={filters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>

      {loggedCompany.type !== CompanyTypeEnum.Requester && (
        <FabButton color="secondary" onClick={handleCreate}>
          <Icon size={1} path={mdiPlus} />
        </FabButton>
      )}
    </FabButtonContainer>
  );
};

export default DocumentList;
