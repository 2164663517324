import React, { useCallback } from 'react';
import { useTheme, Theme } from '@material-ui/core/styles';
import { ButtonProps, CircularProgress } from '@material-ui/core';

import Icon from '@mdi/react';
import { IPaletteColor, IPaletteVariantColor } from '../../../models/palette';
import {
  validatePaletteColor,
  validateHexColor,
} from '../../../utils/helpers/colors-helper';

import { Props as MyButtonProps, MyButton } from './styles';

interface Props extends ButtonProps {
  buttonColor: IPaletteColor | 'gradient' | string;
  variantColor?: IPaletteVariantColor;
  textColor?: string;
  isLoading?: boolean;
}

const Button: React.FC<Props> = ({
  buttonColor,
  variantColor = 'main',
  variant = 'contained',
  size = 'large',
  textColor = null,
  isLoading = false,
  startIcon = null,
  endIcon = null,
  children = null,
  ...props
}) => {
  const { palette } = useTheme() as Theme;

  const getColors = useCallback((): MyButtonProps => {
    let btnColor = palette.primary.main;
    let txtColor = palette.getContrastText(palette.primary.main);
    if (validatePaletteColor(buttonColor)) {
      const color = palette[buttonColor];
      btnColor = color[variantColor];
      if (validateHexColor(textColor)) {
        txtColor = textColor;
      } else if (variantColor === 'main') {
        txtColor = color.contrastText;
      } else {
        txtColor = palette.getContrastText(color[variantColor]);
      }
    } else if (buttonColor === 'gradient') {
      const color = buttonColor;
      btnColor = color;
      if (validateHexColor(textColor)) {
        txtColor = textColor;
      } else {
        txtColor = 'white';
      }
    } else if (validateHexColor(buttonColor, false)) {
      const color = buttonColor;
      btnColor = color;
      if (validateHexColor(textColor)) {
        txtColor = textColor;
      } else {
        txtColor = palette.getContrastText(color);
      }
    }
    return { buttonColor: btnColor, textColor: txtColor };
  }, [palette, buttonColor, textColor, variantColor]);

  return (
    <MyButton
      {...props}
      {...getColors()}
      variant={variant}
      size={size}
      startIcon={startIcon && <Icon size={1} path={startIcon as string} />}
      endIcon={endIcon && <Icon size={1} path={endIcon as string} />}
    >
      {isLoading ? <CircularProgress size={26} /> : children}
    </MyButton>
  );
};

export default Button;
