import React from 'react';
import { AuthProvider } from './auth';
import { BackupFiltersProvider } from './backup-filters';
import { NotificationProvider } from './notification';
import { SnackbarProvider } from './snackbar';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <SnackbarProvider>
      <NotificationProvider>
        <BackupFiltersProvider>{children}</BackupFiltersProvider>
      </NotificationProvider>
    </SnackbarProvider>
  </AuthProvider>
);

export default AppProvider;
