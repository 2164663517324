import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import PrivateRoutes from './private-routes';
import PublicRoutes from './public-routes';

const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated() ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};

export default Routes;
