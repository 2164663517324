/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400,
  },
});

interface Document {
  id: string;
  nome: string;
}

interface ApiResponse {
  ans: Document[];
  resus: Document[];
}

export default function ControlledTreeView(props: { defenseID: number }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const { defenseID } = props;
  const [selected, setSelected] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          `https://resus-front.intelicorp.com.br/api/defense/document/list/${defenseID}`,
        );
        // Extrair os documentos "resus" do JSON de resposta
        const resusDocuments = response.data.resus;

        const resusRegister = resusDocuments.map(document => ({
          id: document.id,
          documentName: document.nome,
        }));
        setDocuments(resusRegister);
      } catch (error) {
        console.error('Erro ao buscar documentos:', error);
      }
    };

    fetchDocuments();
  }, [defenseID]);

  const handleToggle = (_event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (_event, nodeIds) => {
    setSelected(nodeIds);
  };

  const renderTree = nodes => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={nodes.documentName}
    />
  );

  return (
    <TreeView
      className={classes.root}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {documents.length > 0 && documents.map(document => renderTree(document))}
    </TreeView>
  );
}
