import styled from 'styled-components';
import { Grid, Paper, PaperProps } from '@material-ui/core';

interface CardProps extends PaperProps {
  background: string;
}

export const Container = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media (min-width: 769px) {
    div + div {
      margin-left: 12px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    div + div {
      margin-top: 12px;
    }
  }
`;

export const Card = styled(Paper)<CardProps>`
  max-height: 170px;
  display: flex;
  flex-direction: column;
  color: #fff !important;
  background: ${props => props.background} !important;
  padding: 20px;
  cursor: pointer;
  transition: 180ms ease-in-out;
  justify-content: space-between;
  width: calc(25% - 8px);

  div {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

export const Title = styled.span`
  font-size: 24px;
  line-height: 32px;
`;

export const Subtitle = styled.span`
  font-size: 16px;
`;

export const Number = styled.span`
  font-size: 24px;
  margin-top: 20px;
`;
