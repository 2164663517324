import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .MuiPickersDateRangePickerInput-root {
    width: 100%;
  }

  button {
    padding: 4px;
    margin-right: 6px;
  }
`;

export const ContainerDate = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;

  > svg {
    margin: 10px;
  }

  span {
    /* font-size: 15px; */
    color: ${props => props.theme.palette.action.active};

    + span {
      margin-left: 6px;
    }
  }

  input {
    outline: none;
    border: none;
    width: 0px;
    font-size: 15px;
    color: transparent;
  }

  input::after {
    border: none;
  }
`;
