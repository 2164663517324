import styled from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.background.paper};
`;

export const LogoContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  flex: 0 1;

  button {
    margin-left: ${props => (props.isOpen ? -72 : 0)}px;
    margin-right: ${props => (props.isOpen ? 24 : 16)}px;
    transition: margin-left 180ms, margin-right 180ms;
  }

  h1 {
    margin-bottom: -12px;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  background-color: ${props => props.theme.palette.secondary.light};
  border-radius: 32px;

  p {
    padding-left: 8px;
    padding-right: 24px;
    color: ${props => props.theme.palette.secondary.main};
    font-size: 18px;
  }

  img {
    margin-left: 4px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
`;
