import React, { useState } from 'react';
import { formatDate } from '../../../../../../utils/helpers/format-helper';

import { INotification } from '../../models';
import { ItemList, ItemTitle, ItemDate } from './styles';

interface NotificationsProps {
  notifications: INotification;
  updateNotification(id: number): Promise<void>;
  date?: Date;
}

const Notification: React.FC<NotificationsProps> = ({
  notifications,
  updateNotification,
  date = null,
}) => {
  const [open] = useState(false);

  return (
    <>
      <ItemList
        className={open ? 'isOpen' : 'isClosed'}
        onClick={() => updateNotification(notifications.id)}
      >
        <ItemTitle> ABI número {notifications.numberAbi} foi criada</ItemTitle>
      </ItemList>
      {date && (
        <ItemDate>
          Data última atualização {formatDate(date, true, true)}
        </ItemDate>
      )}

      {/* {open && (
        <StyledPaper>
          <ContainerButton>
            <ArrowButton onClick={() => setOpen(!open)}>
              <Icon size={0.8} path={mdiClose} />
            </ArrowButton>
          </ContainerButton>
          <Selected>{selected}</Selected>
          <List>
            <OptionsButton>
              <Icon size={1} path={mdiDeleteForeverOutline} />
              <ItemContent>Remover esta notificação</ItemContent>
            </OptionsButton>
            <OptionsButton>
              <Icon size={1} path={mdiArrowRightThinCircleOutline} />
              <ItemContent>Ir para o local</ItemContent>
            </OptionsButton>
          </List>
        </StyledPaper>
      )} */}
    </>
  );
};
export default Notification;
