import React from 'react';

import { useAuth } from '../../hooks/auth';

import Button from '../../components/Buttons/Button';

import { Container } from './styles';

const Expired: React.FC = () => {
  const { logout } = useAuth();

  const handleLogout = (): void => {
    logout();
  };

  return (
    <Container>
      <div>
        <h1>Sua sessão expirou!</h1>
        <p>
          Por motivos de segurança, reciclamos a sessão de usuário após algum
          período. Clique no botão abaixo para realizar o login novamente.
        </p>

        <Button fullWidth buttonColor="gradient" onClick={handleLogout}>
          Fazer login novamente
        </Button>
      </div>
    </Container>
  );
};

export default Expired;
