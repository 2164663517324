/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import {
  Grid,
  FormControl,
  Select,
  FormHelperText,
  SelectProps,
  InputLabel,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import Icon from '@mdi/react';

type FocusEvent = React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>;
type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props<T extends object> extends SelectProps {
  label: string;
  name: string;
  options: T[];
  optionName: string;
  optionValue: string;
  startIcon?: string;
  hidden?: boolean;
  onFocus?(e: FocusEvent): void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const SelectForm = <T extends object>({
  label,
  name,
  options,
  optionName,
  optionValue,
  startIcon = null,
  placeholder = null,
  defaultValue: startValue = 0,
  disabled = false,
  hidden = false,
  onFocus = null,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  multiple = false,
  ...rest
}: Props<T>): JSX.Element => {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        const { value } = ref.childNodes[2];
        if (multiple) {
          if (!value) {
            return [];
          }
          return value.split(',');
        }
        if (!value) {
          return '';
        }
        return value;
      },
    });
  }, [defaultValue, fieldName, multiple, registerField]);

  const handleFocus = (e: FocusEvent): void => {
    clearError();
    if (onFocus) onFocus(e);
  };

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ display: hidden ? 'none' : 'flex' }}
    >
      <FormControl fullWidth disabled={disabled}>
        <InputLabel error={!!error}>{label}</InputLabel>

        <Select
          {...rest}
          ref={selectRef}
          multiple={multiple}
          startAdornment={
            startIcon ? (
              <InputAdornment position="start">
                <Icon size={1} path={startIcon} color="action" />
              </InputAdornment>
            ) : (
              undefined
            )
          }
          label={label}
          variant="outlined"
          defaultValue={defaultValue || startValue}
          error={!!error}
          onFocus={handleFocus}
          disabled={disabled}
        >
          {placeholder && (
            <MenuItem value={0} disabled>
              {placeholder}
            </MenuItem>
          )}

          {options.map(option => (
            <MenuItem key={option[optionValue]} value={option[optionValue]}>
              {option[optionName]}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText
          error={!!error}
          style={{ position: 'absolute', bottom: -19 }}
        >
          {error}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default SelectForm;
