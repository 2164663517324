import SetOptions from 'suneditor-react/types/SetOptions';

export type Mode = 'classic' | 'inline' | 'balloon' | 'balloon-always';

const getOptions = (mode: Mode): SetOptions => ({
  buttonList: [
    ['undo', 'redo'],
    ['bold', 'underline', 'align'],
    ['horizontalRule', 'list', 'table', 'image'],
  ],
  mode,
  rtl: false,
  katex: 'window.katex',
  resizingBar: false,
  showPathLabel: false,
  width: '100%',
  height: 'auto',
  defaultStyle:
    'font-size: 16px; font-family: "Roboto", "Maven Pro", "Helvetica", "Arial", sans-serif;',
});

export default getOptions;
