/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { ContainerFrame, FramePolicy } from './styles';

const PolicyAndTerm: React.FC = () => {
  return (
    <ContainerFrame>
      <FramePolicy
        src="https://cdn.cloudmed.io/cloudmed/index.html"
        title="Política de Privacidade e Termo de Uso Cloudmed"
      />
    </ContainerFrame>
  );
};

export default PolicyAndTerm;
