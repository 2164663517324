import LinearProgress from '@material-ui/core/LinearProgress';
import { mdiCheckCircle, mdiCloseCircle, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import FileSelect from '../../../../../Defense/pages/newTransactionModal/fileSelect';
import Button from '../../../../../../components/Buttons/Button';
import { IFile } from '../../../../models';
import { FileActions, FileContainer, FileInfo } from './styles';

interface Props {
  files: IFile[];
  onUpdate: (lastFile: IFile, newFile?: IFile) => void;
  onCancel: () => void;
  onDelete?: (value: any) => void;
  onSelectChange?: (value: any) => void;
}

const FileList: React.FC<Props> = ({
  files,
  onUpdate,
  onCancel,
  onDelete,
  onSelectChange,
}) => {
  const handleCancel = (file: IFile): void => {
    onCancel();
    onUpdate(file, { ...file, status: 'error' });
  };

  return (
    <React.Fragment>
      {files?.map((file, index) => (
        <Container key={`container-${index}`}>
          <FileContainer key={index} status={file.status}>
            <FileInfo>
              <span>{file.name}</span>
              {file.status === 'loading' &&
                (file.progress === 0 ? (
                  <span>Na Fila</span>
                ) : (
                  <LinearProgress variant="determinate" value={file.progress} />
                ))}
            </FileInfo>

            <FileActions>
              {file.status === 'error' ? (
                <React.Fragment>
                  <Button
                    size="small"
                    buttonColor="error"
                    variantColor="main"
                    onClick={() =>
                      onUpdate(file, {
                        ...file,
                        status: 'loading',
                        progress: 0,
                      })
                    }
                  >
                    Tentar Novamente
                  </Button>

                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        onUpdate(file);
                        onDelete({ index_container: index });
                      }}
                    >
                      <Icon size={1} path={mdiDelete} />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <b>{file.size}</b>
                  {file.status === 'success' ? (
                    <React.Fragment>
                      <b style={{ marginLeft: 10 }}>indeterminada</b>
                      <Icon
                        size={1}
                        path={mdiCheckCircle}
                        style={{ margin: '8px 12px 8px 20px' }}
                      />
                    </React.Fragment>
                  ) : (
                    <Tooltip title="Cancelar">
                      <IconButton onClick={() => handleCancel(file)}>
                        <Icon size={1} path={mdiCloseCircle} />
                      </IconButton>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}
            </FileActions>
          </FileContainer>
          {onSelectChange && (
            <FileSelect
              key={`${file.name}-index-${index}`}
              onSelectChange={(value: any) =>
                onSelectChange({ ...value, ...{ index_container: index } })
              }
              isDropdownEmpty={false}
            />
          )}
        </Container>
      ))}
    </React.Fragment>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
`;

export default FileList;
