import { api } from '../../../services/api';

import { IReap } from '../models';

const postReap = async (reap: IReap): Promise<void> => {
  await api.post('/reap', reap);
};

const getReapById = async (id: number): Promise<IReap> => {
  const res = await api.get(`/reap/${id}`);
  const { data } = res.data;
  return data;
};

export default { postReap, getReapById };
