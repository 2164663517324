import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiEye, mdiMagnify, mdiPlus } from '@mdi/js';

import { FabButton, FabButtonContainer } from '../../../../styles/global';
import { useSnackbar } from '../../../../hooks/snackbar';
import { IDateFilter } from '../../../../models/api';
import { formatDate } from '../../../../utils/helpers/format-helper';

import {
  Input as InputFilter,
  DatePicker,
} from '../../../../components/Filters';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import AsyncTable from '../../../../components/Tables/AsyncTable';
import { ColorDueDate } from './styles';

import { IReap } from '../../models';
import api from '../../services';
import palette from '../../../../styles/theme/palette';

const ReapList: React.FC = () => {
  const history = useHistory();
  const reapExpiredDashBoard = history.location.state as number;
  const { setErrorMessage } = useSnackbar();
  const [initialDate, setInitialDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filters, setFilters] = useState<IDateFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    if (reapExpiredDashBoard) {
      let dateReap = new Date(Date.now());

      if (Number(reapExpiredDashBoard) === 15) {
        dateReap = new Date(
          moment(dateReap)
            .add(6, 'days')
            .format('YYYY-MM-DD'),
        );
      }
      if (Number(reapExpiredDashBoard) === 30) {
        dateReap = new Date(
          moment(dateReap)
            .add(16, 'days')
            .format('YYYY-MM-DD'),
        );
      }

      const newReapExpired = Number(reapExpiredDashBoard) + 1;

      const dateReapEnd = new Date(
        moment(dateReap)
          .add(String(newReapExpired), 'days')
          .format('YYYY-MM-DD'),
      );

      setInitialDate(dateReap);
      setEndDate(dateReapEnd);
      setFilters({
        ...filters,
        initialDate: moment(dateReap).format('YYYY-MM-DD'),
        endDate: moment(dateReap)
          .add(String(newReapExpired), 'days')
          .format('YYYY-MM-DD'),
      });
    }
  }, [reapExpiredDashBoard]);//eslint-disable-line

  const colorDueDate = (date: Date): string => {
    if (date) {
      const time = new Date(Date.now());
      if (moment(date).isAfter(time)) {
        const diff = Math.abs(time.getTime() - new Date(date).getTime());
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

        if (days <= 5) {
          return palette.error.main;
        }
        if (days > 5 && days <= 15) {
          return palette.warning.main;
        }
        if (days > 15 && days < 30) {
          return palette.error.main;
        }
      }
      return palette.error.main;
    }
    return null;
  };

  const renderDueDate = ({ dueDate }: IReap): JSX.Element => {
    const color = colorDueDate(dueDate);

    return <ColorDueDate color={color}>{formatDate(dueDate)}</ColorDueDate>;
  };

  const columns: Column<IReap>[] = [
    {
      title: 'Nosso Número',
      field: 'nm',
    },
    {
      title: 'Data Documento',
      render: ({ documentDate }) => formatDate(documentDate),
    },
    {
      title: 'Data Vencimento',
      render: renderDueDate,
    },
    {
      title: 'Nome Operadora',
      field: 'operatorName',
    },
    {
      title: 'Valor',
      render: ({ value }) =>
        value ? value.toString().replace('.', ',') : value,
    },
  ];

  const handleDetails = async ({ id }: IReap): Promise<void> => {
    try {
      setIsLoading(true);
      const reap = await api.getReapById(id);
      history.push({
        pathname: `/recolhimento/detalhes/${id}`,
        state: reap,
      });
    } catch (err) {
      setErrorMessage('Houve um erro ao buscar os recolhimentos.', err, true);
      setIsLoading(false);
    }
  };

  const handleInitialDate = (date: Date): void => {
    setInitialDate(date);

    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFilters({
      ...filters,
      initialDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
    });
  };

  const handleEndDate = (date: Date): void => {
    setEndDate(date);

    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFilters({
      ...filters,
      endDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
    });
  };

  const actions: Action<IReap>[] = [
    {
      icon: () => <Icon size={1} path={mdiEye} />,
      tooltip: 'Visualizar',
      onClick: (_, reap) => handleDetails(reap as IReap),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ ...filters, term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os recolhimentos.', err, true);
  };

  const handleCreate = (): void => {
    history.push('/recolhimento/novo');
  };

  return (
    <FabButtonContainer>
      <PageHeader
        title="Recolhimento"
        subtitle="Aqui você encontra os Recolhimentos"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={6}>
          <InputFilter
            label="Pesquisar"
            placeholder="Difícil de encontrar? Digíte o nosso número aqui e encontraremos para você..."
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <DatePicker
          sm={4}
          md={3}
          label="De"
          valueInput={initialDate}
          onChange={handleInitialDate}
          placeholder="Data Inicial"
        />

        <DatePicker
          sm={4}
          md={3}
          label="Até"
          valueInput={endDate}
          minDate={initialDate}
          onChange={handleEndDate}
          placeholder="Data Final"
        />

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="reaps"
            dataUrl="/reap"
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>
      <FabButton color="secondary" onClick={handleCreate}>
        <Icon size={1} path={mdiPlus} />
      </FabButton>
    </FabButtonContainer>
  );
};

export default ReapList;
