import styled from 'styled-components';

interface Props {
  navbarIsOpen: boolean;
}

export const Container = styled.div<Props>`
  margin-left: ${props => (props.navbarIsOpen ? 298 : 80)}px;
  transition: margin-left 180ms;
`;

export const RouterContainer = styled.div`
  padding: 24px;
  min-height: calc(100vh - (120px));
  background-color: #f1f1f1;
`;
