import React from 'react';
import { Box, PaperProps } from '@material-ui/core';

import TextHighline from '../TextHighline';

import { Container, TitleContainer } from './styles';

interface Props extends PaperProps {
  title?: string;
  overflow?: boolean;
}

const Card: React.FC<Props> = ({ title, children, overflow, ...rest }) => (
  <Container {...rest} overflow={overflow ? 'auto' : 'hidden'}>
    {title && (
      <TitleContainer p={2}>
        <TextHighline>{title}</TextHighline>
      </TitleContainer>
    )}
    <Box p={2}>{children}</Box>
  </Container>
);

export default Card;
