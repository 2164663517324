import { CancelTokenSource } from 'axios';
import { api, api_no_timeout } from '../../../services/api';
import { AttendanceStatusEnum } from '../../../utils/enum';

import {
  IAttendance,
  IAttendanceModal,
  IDocument,
  IFile,
  IProcedure,
} from '../models';

const getDocumentById = async (id: number): Promise<IDocument> => {
  const res = await api.get(`/document/${id}`);
  const { data } = res.data;
  return data;
};

const getAttendanceById = async (id: number): Promise<IAttendance> => {
  const res = await api.get(`/attendance/${id}`);
  const { data } = res.data;
  return data;
};

const getProcedureById = async (id: number): Promise<IProcedure> => {
  const res = await api.get(`/procedure/${id}`);
  const { data } = res.data;
  return data;
};

const toBase64 = (file: File): Promise<string | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const postDocument = async (
  file: IFile,
  { token }: CancelTokenSource,
  onUploadProgress: (progressEvent: any) => void,
): Promise<void> => {
  const params = {
    name: file.name,
    file: await toBase64(file.file),
  };
  await api_no_timeout.post('/document', params, {
    cancelToken: token,
    onUploadProgress,
  });
};

// const postDocumentPdf = async (
//   file: IFile,
//   { token }: CancelTokenSource,
//   onUploadProgress: (progressEvent: any) => void,
// ): Promise<void> => {
//   const params = {
//     name: file.name,
//     file: await toBase64(file.file),
//   };
//   await api_no_timeout.post('/defense/additional/doc', params, {
//     cancelToken: token,
//     onUploadProgress,
//   });
// };

const postAttendanceModal = async (
  attendanceModal: IAttendanceModal,
): Promise<number> => {
  const res = await api.post(`/attendance/info`, attendanceModal);
  const { data } = res.data;
  return data;
};

const putAttendance = async (attendance: IAttendance): Promise<void> => {
  const res = await api.put(`/attendance`, attendance);
  const { data } = res.data;
  return data;
};

const putAttendanceStatus = async (
  attendanceId: number,
  statusId: AttendanceStatusEnum,
): Promise<void> => {
  await api.put(`/attendance/status`, {
    id: attendanceId,
    id_Status: statusId,
  });
};

const putAttendanceModal = async (
  attendanceModal: IAttendanceModal,
): Promise<void> => {
  await api.put(`/attendance/info`, attendanceModal);
};

export default {
  getDocumentById,
  getAttendanceById,
  getProcedureById,
  postDocument,
  postAttendanceModal,
  // postDocumentPdf,
  putAttendance,
  putAttendanceModal,
  putAttendanceStatus,
};
