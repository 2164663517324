import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { IBasicFilter } from '../../../../models/api';

import { FabButton, FabButtonContainer } from '../../../../styles/global';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import InputFilter from '../../../../components/Filters/Input';
import AsyncTable from '../../../../components/Tables/AsyncTable';

import { IDefenseText } from '../../models';
import api from '../../services';

const DefenseTextList: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useSnackbar();
  const [filters, setFilters] = useState<IBasicFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleText = ({ text }: IDefenseText): string => {
    return text?.length > 200 ? `${text.substr(0, 200).trim()}...` : text;
  };

  const columns: Column<IDefenseText>[] = [
    {
      title: 'Nome',
      field: 'name',
    },
    {
      title: 'Texto',
      field: 'text',
      render: handleText,
    },
  ];

  const handleEdit = async ({ id }: IDefenseText): Promise<void> => {
    try {
      setIsLoading(true);
      const texts = await api.getDefenseTextById(id);
      history.push({
        pathname: `/textos-de-defesa/editar/${id}`,
        state: texts,
      });
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao buscar os dados do texto de defesa.',
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const actions: Action<IDefenseText>[] = [
    {
      icon: () => <Icon size={1} path={mdiPencil} />,
      tooltip: 'Editar',
      onClick: (_, text) => handleEdit(text as IDefenseText),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os textos de defesa.', err, true);
  };

  const handleCreate = (): void => {
    history.push('/textos-de-defesa/novo');
  };

  return (
    <FabButtonContainer>
      <PageHeader
        title="Textos de defesa"
        subtitle="Aqui estão os textos de defesa"
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="texts"
            dataUrl="/defaultDefense"
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>

      <FabButton color="secondary" onClick={handleCreate}>
        <Icon size={1} path={mdiPlus} />
      </FabButton>
    </FabButtonContainer>
  );
};

export default DefenseTextList;
