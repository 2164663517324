import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  height: 42px;
  padding: 2px 10px 0;
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.grey[600]};

  .MuiFormControlLabel-label {
    margin-top: 1px;
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 8px;
  }
`;
