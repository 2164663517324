import React, { ChangeEvent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  mdiAccount,
  mdiCardAccountDetails,
  mdiDomain,
  mdiNumeric,
} from '@mdi/js';

import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { companyTypeArray } from '../../../../utils/helpers/array-helper';
import { handleFormError } from '../../../../utils/helpers/form-helper';
import { CompanyTypeEnum } from '../../../../utils/enum';

import { Card, Input, Select } from '../../../../components/Form';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import api from '../../services';
import { ICompany } from '../../models';
import { cnpjMask, number } from '../../../../utils/regex-validations';

const CompanyForm: React.FC = () => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const showType = loggedCompany.type === CompanyTypeEnum.Admin;
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const company = history.location.state as ICompany;
  const [isLoading, setIsLoading] = useState(false);
  const [isCnpj, setIsCnpj] = useState(company?.code.length > 4);
  const [codeCnpj, setCodeCnpj] = useState<string>();

  const handleSubmit = async (data: ICompany): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('O campo nome é obrigatório')
          .max(50, 'Digite um nome com no máximo 50 caracteres'),
        code: Yup.string().required('O campo código é obrigatório'),
        type: showType
          ? Yup.string().required('O campo tipo é obrigatório')
          : Yup.string(),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);
      if (company) {
        await api.putCompany({ ...data, id: company.id });
      } else {
        await api.postCompany(data);
      }

      if (company) {
        setMessage({
          type: 'success',
          message: 'Empresa atualizada com sucesso',
        });
      } else {
        setMessage({ type: 'success', message: 'Empresa salva com sucesso' });
      }
      history.goBack();
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar a empresa.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  const OnChangeCodeCnpj = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    if (event.target.value.includes('.')) {
      setCodeCnpj(
        event.target.value.length <= 5
          ? number(event.target.value)
          : cnpjMask(event.target.value),
      );
      setIsCnpj(event.target.value.length > 5);
    } else {
      setCodeCnpj(
        event.target.value.length <= 4
          ? number(event.target.value)
          : cnpjMask(event.target.value),
      );
      setIsCnpj(event.target.value.length > 4);
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title={`${company ? 'Editar' : 'Criar'} empresa`}
        subtitle={`${
          company
            ? 'Aqui é mostrado os dados da empresa'
            : 'Preencha os campos para cadastrar'
        }`}
      />

      <Card
        formRef={formRef}
        onSubmit={handleSubmit}
        initialData={company}
        isLoading={isLoading}
        title={
          company
            ? `Você selecionou a empresa "${company.name}" para editar`
            : 'Você clicou para cadastrar uma nova empresa'
        }
      >
        <Input
          md={6}
          lg={showType ? 4 : 6}
          startIcon={mdiAccount}
          name="name"
          label="Nome"
          placeholder="Qual o nome da empresa?"
          disabled={isLoading}
        />

        <Input
          md={6}
          lg={showType ? 4 : 6}
          startIcon={isCnpj ? mdiCardAccountDetails : mdiNumeric}
          name="code"
          value={codeCnpj}
          label="Código Interno / CNPJ"
          onChange={OnChangeCodeCnpj}
          maxLength={isCnpj && 18}
          placeholder="Informe também o código interno ou o CNPJ..."
          disabled={isLoading}
        />

        <Select
          lg={4}
          startIcon={mdiDomain}
          name="type"
          label="Tipo"
          placeholder="Selecione o tipo da empresa..."
          options={companyTypeArray}
          optionName="name"
          optionValue="id"
          defaultValue={1}
          disabled={isLoading}
          hidden={!showType}
        />
      </Card>
    </React.Fragment>
  );
};

export default CompanyForm;
