/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCheckboxBlank, mdiCheckboxMarked } from '@mdi/js';

import { Container } from './styles';

const icon = (
  <Icon
    size={1}
    path={mdiCheckboxMarked}
    color="action"
    style={{ marginRight: 8 }}
  />
);

const checkedIcon = (
  <Icon
    size={1}
    path={mdiCheckboxBlank}
    color="secondary"
    style={{ marginRight: 8 }}
  />
);

type Value<T extends object> = string | NonNullable<T> | (string | T)[];

export interface Props<T extends object> {
  options: T[];
  multiple?: boolean;
  placeholder?: string;
  startIcon?: React.ReactElement;
  defaultValue?: Value<T>;
  optionName(option: T): string;
  onChange?(value: Value<T>): void;
}

const language = {
  openText: 'Abrir',
  closeText: 'Fechar',
  clearText: 'Limpar',
  noOptionsText: 'Sem opções',
  loadingText: 'Carregando...',
};

const AutocompleteForm = <T extends object>({
  placeholder = null,
  multiple = false,
  startIcon = null,
  defaultValue = null,
  optionName,
  onChange = null,
  ...rest
}: Props<T>): JSX.Element => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<Value<T>>(
    defaultValue || (multiple ? [] : null),
  );

  useEffect(() => {
    setValue(defaultValue || (multiple ? [] : null));
  }, [defaultValue, multiple]);

  const handleChange = (
    _: React.ChangeEvent<unknown>,
    newValue: Value<T>,
  ): void => {
    if (newValue) setValue(newValue);
    else setValue(multiple ? [] : null);

    if (onChange) onChange(newValue as Value<T>);
  };

  return (
    <Container>
      {startIcon &&
        React.cloneElement(startIcon, {
          color: focus ? 'primary' : 'action',
          style: {
            transition: 'color 180ms',
          },
        })}

      <Autocomplete
        {...rest}
        {...language}
        fullWidth
        multiple={multiple}
        disableCloseOnSelect={multiple}
        value={value}
        onChange={handleChange}
        getOptionLabel={optionName}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {multiple && (selected ? checkedIcon : icon)}
            {optionName(option)}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            placeholder={placeholder}
            style={{ marginTop: 2 }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
        )}
      />
    </Container>
  );
};

export default AutocompleteForm;
