import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import shadows from './shadows';

export default createMuiTheme({
  palette,
  shadows,
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiButton: {
      containedSizeLarge: {
        padding: '14px 20px 12px 20px;',
      },
      textSizeLarge: {
        padding: '14px 20px 12px 20px;',
      },
      outlinedSizeLarge: {
        padding: '13px 20px 12px 20px;',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: palette.primary.main,
      },
      root: {
        background: palette.background.paper,
        '&.Mui-disabled': {
          background: '#F1F1F1',
          '& fieldset': {
            border: 0,
          },
        },
        '& .MuiSelect-icon': {
          color: palette.primary.main,
        },
        '&.MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${palette.primary.main}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.primary.main,
      },
    },
    MuiFormLabel: {
      root: {
        zIndex: 1,
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .Mui-disabled': {
          color: palette.primary.main,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        '& svg': {
          fill: palette.action.active,
        },
      },
    },
  },
});
