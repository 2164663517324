import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { userProfileArray } from '../../../../utils/helpers/array-helper';
import { IBasicFilter } from '../../../../models/api';

import { FabButton, FabButtonContainer } from '../../../../styles/global';
import AsyncTable from '../../../../components/Tables/AsyncTable';
import InputFilter from '../../../../components/Filters/Input';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import api from '../../services';
import { IListUser } from '../../models';

const UserList: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useSnackbar();
  const [filters, setFilters] = useState<IBasicFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const renderProfile = ({ profile }: IListUser): string =>
    userProfileArray.find(value => value.id === profile)?.name ||
    'Não Informado';

  const columns: Column<IListUser>[] = [
    {
      title: 'Nome',
      field: 'name',
    },
    {
      title: 'E-mail',
      field: 'email',
    },
    {
      title: 'Perfil',
      field: 'profile',
      render: renderProfile,
    },
  ];

  const handleEdit = async ({ id }: IListUser): Promise<void> => {
    try {
      setIsLoading(true);
      const user = await api.getUserById(id);
      history.push({
        pathname: `/usuarios/editar/${id}`,
        state: user,
      });
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao buscar os dados do usuário.',
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const actions: Action<IListUser>[] = [
    {
      icon: () => <Icon size={1} path={mdiPencil} />,
      tooltip: 'Editar',
      onClick: (_, user) => handleEdit(user as IListUser),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os usuários.', err, true);
  };

  const handleCreate = (): void => {
    history.push('/usuarios/novo');
  };

  return (
    <FabButtonContainer>
      <PageHeader
        title="Usuários"
        subtitle="Aqui é mostrado uma lista de todos usuários cadastrados"
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="users"
            dataUrl="/user"
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>

      <FabButton color="secondary" onClick={handleCreate}>
        <Icon size={1} path={mdiPlus} />
      </FabButton>
    </FabButtonContainer>
  );
};

export default UserList;
