import React from 'react';
import { useTheme, Theme } from '@material-ui/core/styles';
import ListExpired from './ListExpired';
import { IExpiredDocument } from '../../models';
import { Container } from './styles';

interface Props {
  expiredDate: IExpiredDocument[];
}

const ExpiredDocsDate: React.FC<Props> = ({ expiredDate }) => {
  const { palette } = useTheme() as Theme;

  return (
    <Container item xs={12}>
      <ListExpired
        background={palette.error.main}
        title={`${expiredDate[0].day} dias`}
        subtitle="Vencimento para os próximos 5 dias"
        value={expiredDate[0].quantity}
        pathname="/recolhimento"
        params={expiredDate[0].day.toString()}
      />
      <ListExpired
        background={palette.warning.main}
        title={`${expiredDate[1].day} dias`}
        subtitle="Vencimento para os próximos 15 dias"
        value={expiredDate[1].quantity}
        pathname="/recolhimento"
        params={expiredDate[1].day.toString()}
      />
      <ListExpired
        background={palette.success.main}
        title={`${expiredDate[2].day} dias`}
        subtitle="Vencimento para os próximos 30 dias"
        value={expiredDate[2].quantity}
        pathname="/recolhimento"
        params={expiredDate[2].day.toString()}
      />
    </Container>
  );
};

export default ExpiredDocsDate;
