import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, InputBase } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiClose, mdiMagnify } from '@mdi/js';

import { Container } from './styles';

interface Props {
  value: string;
  onChange(value: string): void;
}

const SearchInput: React.FC<Props> = ({ value, onChange }) => {
  const { palette } = useTheme();
  const [text, setText] = useState<string>(value);

  useEffect(() => {
    onChange(text);
  }, [onChange, text]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setText(event.target.value);
  };

  const handleClean = (): void => {
    setText('');
  };

  return (
    <Container>
      <Icon size={1} path={mdiMagnify} style={{ color: palette.info.main }} />

      <InputBase
        placeholder="Pesquisar empresa"
        value={text}
        onChange={handleChange}
      />

      <IconButton onClick={handleClean} disabled={!text}>
        <Icon size={1} path={mdiClose} />
      </IconButton>
    </Container>
  );
};

export default SearchInput;
