import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
}

export const NavLink = styled(Link)`
  width: calc(100% - 32px);
  margin: 4px 0;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 32px;
  color: white;
  text-decoration: none;

  &.selected {
    color: ${props => props.theme.palette.primary.main};
    background-color: white;
  }

  svg {
    margin-right: 16px;
    overflow: inherit;
  }

  &:hover:not(.selected) {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const Title = styled.span<Props>`
  height: ${props => (props.title.length > 25 ? '25' : '19')}px;
  font-weight: 500;
`;
