import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background.paper};

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    height: 100vh;

    background-image: linear-gradient(
      65deg,
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.secondary.main}
    );
    flex-direction: column-reverse;
  }
`;
export const WelcomeContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  background-image: linear-gradient(
    105deg,
    ${props => props.theme.palette.primary.main},
    ${props => props.theme.palette.secondary.main}
  );
  text-align: center;
  h1 {
    font-size: 56px;
    font-weight: 500;
    color: ${props => props.theme.palette.background.paper};
  }
  p {
    font-size: 24px;
    font-weight: 300;
    color: ${props => props.theme.palette.background.paper};
  }
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
    }
  }
`;
export const FormContent = styled.div`
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  form {
    width: 100%;
    max-width: 700px;
    .MuiInputLabel-shrink {
      left: 16px;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 20px;
    }
    legend {
      margin-left: 16px;
    }
    div.MuiOutlinedInput-root {
      border-radius: 32px !important;
    }
  }
  h1 {
    width: 100%;
    text-align: center;
  }
  a {
    float: right;
    color: ${props => props.theme.palette.secondary.main};
    text-decoration: none;
  }
  button {
    border-radius: 32px !important;
  }
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: ${props => props.theme.palette.background.paper};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: ${props => props.theme.palette.background.paper};
  }
`;
export const TextForgot = styled.span`
  float: right;
  color: ${props => props.theme.palette.secondary.main};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`;
