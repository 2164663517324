/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useRef, useState } from 'react';
import MaterialTable, {
  MaterialTableProps,
  MTableHeader,
} from 'material-table';

import { Icons, Localization, Options } from './configs';
import { Container, Paper, ResponsiveTable, LinearProgress } from './styles';

export interface TableProps<RowData extends object>
  extends MaterialTableProps<RowData> {
  actionsTitle?: string;
  noElevation?: boolean;
  onSearchChange?(searchText: string): void;
}

const Table = <RowData extends object>({
  actionsTitle = 'Ações',
  isLoading = false,
  options = null,
  components = null,
  noElevation = false,
  columns,
  data,
  ...rest
}: TableProps<RowData>): JSX.Element => {
  const observer = useRef(null);
  const [topLoaderVisible, setTopLoaderVisible] = useState(true);

  const topLoaderRef = (node: React.ReactNode): void => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      setTopLoaderVisible(entries[0]?.isIntersecting);
    });
    if (node) observer.current.observe(node);
  };

  return (
    <Container>
      <ResponsiveTable
        columns={columns}
        actionsTitle={actionsTitle}
        isLoading={isLoading}
        length={data?.length || 0}
        noElevation={noElevation}
      >
        <MaterialTable
          {...rest}
          columns={columns}
          data={data || []}
          icons={Icons}
          localization={Localization(actionsTitle, isLoading)}
          options={Options<RowData>(options)}
          isLoading={isLoading && !topLoaderVisible}
          components={{
            ...components,
            Container: props => (
              <Paper {...props} elevation={noElevation ? 0 : 1} />
            ),
            // eslint-disable-next-line no-shadow
            Header: ({ columns, actions, ...props }) => (
              <React.Fragment>
                <MTableHeader {...props} columns={columns} actions={actions} />
                <thead>
                  <tr>
                    <td
                      style={{ padding: 0 }}
                      colSpan={columns.length + actions.length}
                    >
                      <LinearProgress
                        ref={topLoaderRef}
                        style={{
                          visibility: isLoading ? 'unset' : 'hidden',
                        }}
                      />
                    </td>
                  </tr>
                </thead>
              </React.Fragment>
            ),
          }}
        />
      </ResponsiveTable>
    </Container>
  );
};

export default Table;
