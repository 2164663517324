/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { mdiCalendar, mdiText } from '@mdi/js';

import { Card, DatePicker, Input } from '../../../../components/Form';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import { ILog } from '../../models';

const LogDetails: React.FC = () => {
  const history = useHistory();
  const formRef = useRef(null);
  const log = history.location.state as ILog;

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title="Visualizar Log"
        subtitle="Aqui é mostrado os dados do log"
      />

      <Card
        formRef={formRef}
        initialData={log}
        title={`Você está visualizando o log "${log.id}"`}
      >
        {/* <Input
          sm={4}
          disabled
          startIcon={mdiAccount}
          name="userName"
          label="Usuário"
        />

        <Input
          sm={4}
          disabled
          startIcon={mdiDomain}
          name="companyName"
          label="Empresa"
        /> */}

        <DatePicker
          disabled
          startIcon={mdiCalendar}
          name="createAt"
          label="Data"
          inputFormat="DD-MM-YYYY HH:mm:ss"
          mask="__-__-____ __:__:__"
        />

        <Input
          disabled
          multiline
          startIcon={mdiText}
          name="description"
          label="Descrição"
        />
      </Card>
    </React.Fragment>
  );
};

export default LogDetails;
