import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Icon from '@mdi/react';

interface Props {
  tint: 'secondary' | 'action';
}

// https://github.com/styled-components/styled-components/pull/2093#issuecomment-474743876
export const AutocompleteIcon = styled(Icon)<Props>`
  margin-right: 8px;
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ tint, theme }) =>
    tint === 'secondary'
      ? theme.palette.secondary.main
      : theme.palette.action.active};
`;

export const Container = styled(Grid)`
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 9px 14px;
  }

  @media (max-width: 900px) {
    .blmfIz {
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 100px;
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 9.5px 0px;
  }

  .MuiAutocomplete-popupIndicator:not(.Mui-disabled) {
    color: ${props => props.theme.palette.primary.main};
  }
`;

export const ChipContainer = styled.div`
  .MuiChip-root:last-child {
    margin-right: 8px;
  }
`;
