/* eslint-disable @typescript-eslint/ban-types */
import { QueryResult } from 'material-table';

import { api } from '../../../../services/api';

export const getData = async <RowData extends object>(
  url: string,
  key: string,
  page: number,
  filters: object,
): Promise<QueryResult<RowData>> => {
  const params = { page, ...filters };
  const res = await api.get(url, { params });
  return {
    page,
    data: res.data.data[key],
    totalCount: res.data.data.total,
  } as QueryResult<RowData>;
};
