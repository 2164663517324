import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';

interface ContainerProps extends GridProps {
  visibility: 'visible' | 'hidden';
}

export const Container = styled(Grid)<ContainerProps>`
  display: ${props => (props.visibility === 'visible' ? 'flex' : 'none')};

  div {
    max-height: 38px;
  }
`;
