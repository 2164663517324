import React from 'react';
import { mdiExitToApp, mdiMenu } from '@mdi/js';

import { useAuth } from '../../../hooks/auth';

import companyPhoto from '../../../assets/companies/cloudmed.png';

import Notification from './Notification/index';
import RoundButton from '../../Buttons/RoundButton';
import Logo from '../../Logo';

import {
  CompanyContainer,
  Container,
  LogoContainer,
  NotificationContainer,
} from './styles';

interface Props {
  isOpen: boolean;
  onOpen(): void;
}

const Toolbar: React.FC<Props> = ({ isOpen, onOpen }) => {
  const { loggedCompany, logout } = useAuth();

  return (
    <Container>
      <LogoContainer isOpen={isOpen}>
        <RoundButton
          icon={mdiMenu}
          iconColor="primary"
          backgroundColor="primary"
          onClick={onOpen}
        />

        <Logo size={30} />
      </LogoContainer>

      <NotificationContainer>
        <Notification />
        <CompanyContainer>
          <img src={companyPhoto} alt="Imagem da Empresa" />

          <p>{loggedCompany.name}</p>

          <RoundButton
            icon={mdiExitToApp}
            iconColor="secondary"
            backgroundColor="secondary"
            onClick={logout}
          />
        </CompanyContainer>
      </NotificationContainer>
    </Container>
  );
};

export default Toolbar;
