import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { IconButton } from '@material-ui/core';

export const IconCloseModal = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const ModalView = styled(Modal)`
  display: block;
  padding: 40px;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  outline: 0;
`;
