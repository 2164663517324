import { api, api_no_header } from '../../../services/api';

import { IAuthData } from '../../../models/auth';

import {
  IAuthResponse,
  IForgotPassword,
  ILogin,
  ILoginResponse,
} from '../models';

const parseAuth = ({ data, token }: ILoginResponse): IAuthData => {
  const { user } = data;
  return {
    user: {
      id: user.id,
      id_User: user.id_User,
      email: user.email,
      name: user.name,
      profile: user.profile,
      image: user.image,
    },
    company: {
      id: user.id_Company,
      name: user.companyName,
      type: user.companyType,
      image: null,
    },
    token,
  };
};

const postLogin = async (login: ILogin): Promise<IAuthResponse> => {
  const res = await api.post('/auth/login', login);
  const { data, token } = res.data as ILoginResponse;

  if (token) {
    return parseAuth({ data, token });
  }

  return { user: data.user, userCompanies: data.user.userCompanies };
};

const postConfirm = async (login: ILogin): Promise<IAuthData> => {
  const res = await api.post('/auth/confirm', login);
  const { data, token } = res.data;
  return parseAuth({ data, token });
};

const postForgotPassword = async (
  forgotPassword: IForgotPassword,
): Promise<void> => {
  await api_no_header.post('/user/forgot', forgotPassword);
};

export default {
  postLogin,
  postConfirm,
  postForgotPassword,
};
