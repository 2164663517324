import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mdiArrowLeft, mdiRobotDead } from '@mdi/js';

import { CircularProgress, Grid } from '@material-ui/core';
import Icon from '@mdi/react';
import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';

import RoundButton from '../../../../components/Buttons/RoundButton';
import Logo from '../../../../components/Logo';

import api from '../../services';
import { ISelectCompanyState, IUserCompany } from '../../models';

import SearchInput from './components/SearchInput';
import CompanyButton from './components/CompanyButton';
import {
  CompaniesContainer,
  Container,
  ContentContainer,
  HelloContainer,
  LoaderContainer,
  MessageNoCompany,
  SearchContainer,
  SelectCompanyContainer,
} from './styles';

const SelectCompany: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { setAuthData } = useAuth();
  const { setErrorMessage } = useSnackbar();
  const { login, user, userCompanies } = state as ISelectCompanyState;
  const [companies, setCompanies] = useState<IUserCompany[]>(userCompanies);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCompanies(
      userCompanies.filter(
        company => company.companyName.indexOf(searchText.trim()) !== -1,
      ),
    );
  }, [userCompanies, searchText]);

  const handleBack = (): void => {
    history.goBack();
  };

  const handleSelect = async ({
    id_UserCompany,
  }: IUserCompany): Promise<void> => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const authCompany = await api.postConfirm({ ...login, id_UserCompany });
      setIsLoading(false);
      setAuthData(authCompany);
      history.push('/');
    } catch (err) {
      setIsLoading(false);
      setErrorMessage('Ocorreu um erro ao selecionar a empresa.', err, true);
    }
  };

  return (
    <Container>
      {isLoading && (
        <LoaderContainer>
          <CircularProgress color="secondary" />
        </LoaderContainer>
      )}

      <SearchContainer>
        <RoundButton
          icon={mdiArrowLeft}
          iconColor="info"
          buttonSize={7}
          onClick={handleBack}
        />

        <SearchInput value={searchText} onChange={setSearchText} />
      </SearchContainer>

      <ContentContainer>
        <div>
          <Logo size={68} />
        </div>

        <HelloContainer>
          <h1>
            <span>Olá</span> {user.name}!
          </h1>
        </HelloContainer>

        <SelectCompanyContainer>
          <h1>Selecione sua empresa</h1>
          <p>
            Perfeito! Agora que já sei quem você é, me informe a empresa para
            continuarmos...
          </p>
        </SelectCompanyContainer>

        <CompaniesContainer container spacing={3}>
          {companies?.map((company, i) => (
            <CompanyButton
              key={i.toString()}
              company={company}
              onClick={handleSelect}
            />
          ))}

          {!companies?.length && (
            <Grid item xs>
              <MessageNoCompany>
                <Icon size={1} path={mdiRobotDead} />
                <p>
                  Não encontramos nenhum resultado para{' '}
                  <b>{`"${searchText}"`}</b>
                </p>
              </MessageNoCompany>
            </Grid>
          )}
        </CompaniesContainer>
      </ContentContainer>
    </Container>
  );
};

export default SelectCompany;
