import React from 'react';
import StepButton from '@material-ui/core/StepButton';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {
  mdiArrowDown,
  mdiCheckCircle,
  mdiCheckboxBlankCircleOutline,
} from '@mdi/js';

import { MyStepper, StepSeparateIcon, StepIcon } from './styles';
import Card from '../../../../../../components/Card';

interface Props {
  activeStep: number;
  onChange?: (index: number) => void;
}

const steps = ['ABI', 'Atendimento', 'Procedimento'];

const TableStepper: React.FC<Props> = ({ activeStep, onChange }) => {
  const handleStepChange = (index: number): void => {
    onChange(index);
  };

  const CompletedIcon = ({ index }): JSX.Element => {
    return index <= activeStep ? (
      <StepIcon size={0.8} path={mdiCheckCircle} />
    ) : (
      <StepIcon size={0.8} path={mdiCheckboxBlankCircleOutline} />
    );
  };

  return (
    <Card style={{ height: '100%' }}>
      <MyStepper
        nonLinear
        orientation="vertical"
        activeStep={activeStep}
        connector={<StepSeparateIcon size={0.8} path={mdiArrowDown} />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={() => handleStepChange(index)}
              disabled={index > activeStep}
            >
              <StepLabel
                StepIconComponent={() => <CompletedIcon index={index} />}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </MyStepper>
    </Card>
  );
};

export default TableStepper;
