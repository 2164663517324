/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { IconButtonProps, IconButton } from '@material-ui/core';
import { addHolder } from '../../../utils/helpers/colors-helper';

export interface Props extends IconButtonProps {
  backgroundColor: string;
  iconColor: string;
  buttonSize: number;
}

const MyButtonTransient = ({
  backgroundColor,
  iconColor,
  buttonSize,
  ...rest
}: Props): React.ReactElement => <IconButton {...rest} />;

// https://github.com/styled-components/styled-components/pull/2093#issuecomment-474743876
export const MyButton = styled(MyButtonTransient)`
  height: ${props => props.theme.spacing(props.buttonSize)}px;
  width: ${props => props.theme.spacing(props.buttonSize)}px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.iconColor};

  &:hover:not(:disabled) {
    background-color: ${props => addHolder(props.backgroundColor)};

    border-color: unset !important;
  }
`;
