import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
// import { Grid } from '@material-ui/core';
// import Icon from '@mdi/react';
import { mdiPound, mdiTextSubject } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import { Card, Input, TextEditor } from '../../../../components/Form';
// import { HelpContainer, HelperLabel } from './styles';

import api from '../../services';
import { IDefenseText } from '../../models';
// import palette from '../../../../styles/theme/palette';

const TextDefenseForm: React.FC = () => {
  const history = useHistory();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  const texts = history.location.state as IDefenseText;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: IDefenseText): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('O campo nome é obrigatório')
          .max(200, 'Digite um nome com no máximo 200 caracteres'),
        text: Yup.string()
          .required('O campo texto é obrigatório')
          .max(3000, 'Digite um texto com no máximo 3000 caracteres'),
      });

      await schema.validate(data, { abortEarly: false });

      setIsLoading(true);
      if (texts) {
        await api.putDefenseText({ ...data, id: texts.id });
      } else {
        await api.postDefenseText(data);
      }

      setMessage({
        type: 'success',
        message: 'Texto de defesa salvo com sucesso',
      });

      history.goBack();
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar o texto de defesa.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title={`${texts ? 'Editar' : 'Criar'} texto de defesa`}
        subtitle={`${
          texts
            ? 'Aqui é mostrado os dados do texto de defesa'
            : 'Preencha os campos para cadastrar'
        }`}
      />

      <Card
        formRef={formRef}
        onSubmit={handleSubmit}
        initialData={texts}
        isLoading={isLoading}
        title={
          texts
            ? `Você selecionou o texto de defesa "${texts.name}" para editar`
            : 'Você clicou para cadastrar uma novo texto de defesa'
        }
      >
        <Input
          name="name"
          startIcon={mdiPound}
          label="Nome"
          placeholder="Qual o nome do texto de defesa?"
          disabled={isLoading}
        />

        {/* <Grid sm={12} direction="row" style={{ padding: 12 }}>
          <HelpContainer>
            <HelperLabel>Observação Texto de Defesa:</HelperLabel>
            <Icon size={1} color={palette.info.main} path={mdiHelpCircle} />
            <div>
              Para alterar determinadas palavras na tela de Defesa (Detalhamento
              do Motivo), declare um variável usando @.
              <span>Ex: @nomevariavel</span>
            </div>
          </HelpContainer>
        </Grid> */}

        <TextEditor
          name="text"
          startIcon={mdiTextSubject}
          labelStyle={{ transform: 'translate(14px, -6px) scale(0.75)' }}
          label="Texto de Defesa"
          placeholder="Escreva o texto de defesa aqui..."
          disabled={isLoading}
        />
      </Card>
    </React.Fragment>
  );
};

export default TextDefenseForm;
