import styled from 'styled-components';
import { Paper } from '@material-ui/core';

interface PropsContainer {
  overflow?: string;
}
interface Props {
  p: number;
}

export const Container = styled(Paper)<PropsContainer>`
  position: relative;
  overflow: ${props => props.overflow};
`;

export const TitleContainer = styled.div<Props>`
  padding: ${props => props.theme.spacing(props.p)}px
    ${props => props.theme.spacing(props.p)}px 8px;
`;
