import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Title, Subtitle, Number } from './styles';

interface Props {
  background: string;
  title: string;
  subtitle: string;
  value: number;
  pathname: string;
  params?: string;
}

const ListExpired: React.FC<Props> = ({
  background,
  title,
  subtitle,
  value,
  pathname,
  params,
}) => {
  const history = useHistory();

  const goToReap = (): void => {
    history.push({ pathname, state: params });
  };

  return (
    <>
      <Card background={background} onClick={() => goToReap()}>
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
        <Number>{value}</Number>
      </Card>
    </>
  );
};

export default ListExpired;
