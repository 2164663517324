import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface Props {
  title: string;
  message?: string;
  textButton?: string;
  textButtonTwo?: string;
  colorTitle?: string;
  colorButton?: string;
  colorButtonTwo?: string;
  open: boolean;
  onSubmit: () => void;
  onClose?: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DialogConfirm: React.FC<Props> = ({
  title,
  message,
  textButton = 'Sim',
  textButtonTwo = 'Não',
  colorTitle,
  colorButton,
  colorButtonTwo,
  open,
  onSubmit,
  onClose,
}) => (
  <React.Fragment>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <span style={{ color: `${colorTitle}` }}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: '#333' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          autoFocus
          style={{ color: `${colorButtonTwo}` }}
        >
          {textButtonTwo}
        </Button>
        <Button
          autoFocus
          onClick={onSubmit}
          style={{ color: `${colorButton}` }}
        >
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
);

export default DialogConfirm;
