import styled from 'styled-components';

export const Container = styled.div`
  height: 56px;
  width: 100%;
  padding: 0 24px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: #e9e9e9;
  border-radius: 32px;

  :hover {
    border-color: rgba(0, 0, 0);
  }

  div {
    flex: 1;
  }

  input {
    margin: 0 16px;
    padding: 18px 0;
  }

  button {
    padding: 4px;
    margin-right: -4px;
  }
`;
