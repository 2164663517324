export const colorHexRegex = /^#[0-9A-F]{8}$/i;
export const colorHexNoTransparencyRegex = /^#[0-9A-F]{6}$/i;
export const colorSmallHexRegex = /^#[0-9A-F]{3}$/i;

export const cnpjRegex = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;

export const cnpjMask = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export const number = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const monetaryValue = (value: any): any => {
  return value.replace(/\D/g, '').replace(/(\d)(\d{2})$/, '$1.$2');
};
