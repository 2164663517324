/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Icon from '@mdi/react';
import { mdiEmail, mdiEye, mdiEyeOff, mdiLock } from '@mdi/js';
import { Form } from '@unform/web';
import { Grid, IconButton } from '@material-ui/core';

import { useAuth } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { handleFormError } from '../../../../utils/helpers/form-helper';

import Button from '../../../../components/Buttons/Button';
import { Input } from '../../../../components/Form';
import Logo from '../../../../components/Logo';

import api from '../../services';
import { ILogin } from '../../models';

import ResetPassword from './components/ResetPassword';
import { Container, FormContent, WelcomeContent, TextForgot } from './styles';
import Cookies from '../../../Cookie';

const Login: React.FC = () => {
  const history = useHistory();
  const formRef = useRef(null);
  const { setAuthData } = useAuth();
  const { setErrorMessage } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showCookie, setShowCookie] = useState(false);

  const handleSubmit = async (login: ILogin): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Campo obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().required('Campo obrigatório'),
      });

      await schema.validate(login, { abortEarly: false });

      setIsLoading(true);
      const { user, company, userCompanies, token } = await api.postLogin(
        login,
      );

      if (token) {
        setAuthData({ user, company, token });
      } else if (!userCompanies?.length) {
        setIsLoading(false);
        setErrorMessage(
          'Infelizmente não existe nenhuma empresa vinculado à seu login.',
        );
      } else {
        history.push({
          pathname: '/selecionar-empresa',
          state: { login, user, userCompanies },
        });
      }
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao realizar o login.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };

  const handleOpen = (): void => {
    setOpenModal(true);
    // setMessage({
    //   type: 'warning',
    //   message:
    //     'No momento esta função está desabilitado. Entre em contato com um administrador para alterar sua senha.',
    // });
  };

  const handleClose = (): void => {
    setOpenModal(false);
  };

  useEffect(() => {
    const getCookie = (cname): string => {
      const name = `${cname}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    };
    const user = getCookie('Resus');

    if (user) {
      console.log(user);
    } else {
      setShowCookie(true);
    }
  }, []);

  return (
    <Container>
      <FormContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} style={{ width: '100%' }}>
              <Logo size={68} sizeImage={85} />
            </Grid>

            <Input
              label="E-mail"
              name="email"
              type="e-mail"
              startIcon={mdiEmail}
            />

            <Input
              label="Senha"
              name="password"
              type={showPassword ? 'text' : 'password'}
              startIcon={mdiLock}
              endIcon={
                <IconButton onClick={togglePasswordVisibility}>
                  <Icon size={1} path={showPassword ? mdiEye : mdiEyeOff} />
                </IconButton>
              }
            />

            <Grid item xs={12} style={{ paddingTop: 0 }} onClick={handleOpen}>
              <TextForgot>Esqueci minha senha, me ajude?</TextForgot>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                buttonColor="gradient"
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Form>

        {openModal && (
          <ResetPassword isVisible={openModal} onClose={handleClose} />
        )}
      </FormContent>

      <WelcomeContent>
        <h1>Bem vindo!</h1>
        <p>Vamos ter uma experiência única?</p>
        <p>Acesse a sua conta</p>
      </WelcomeContent>
      <Cookies open={showCookie} onClose={() => setShowCookie(false)} />
    </Container>
  );
};

export default Login;
