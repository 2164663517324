/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  Grid,
  InputAdornment,
  InputProps,
  FormHelperText,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';
import Icon from '@mdi/react';

import TextEditor from '../../TextEditor';

import { EditorContainer, InputLabel } from './styles';

type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Props extends InputProps {
  name?: string;
  label?: string;
  mainRef?: React.MutableRefObject<any>;
  width?: number | string;
  hidden?: boolean;
  labelStyle?: React.CSSProperties;
  startIcon?: string;
  onEditorFocus?(e: globalThis.FocusEvent): void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
  setValueText?: (text: string) => void;
}

const TextEditorForm: React.FC<Props> = ({
  name = undefined,
  label = null,
  placeholder = null,
  disabled = false,
  mainRef = null,
  width = null,
  hidden = false,
  labelStyle = {},
  startIcon = null,
  onEditorFocus = null,
  // setValueText,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  ...rest
}) => {
  const auxRef = useRef(null);
  const inputRef = mainRef || auxRef;
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name || 'undefined');
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!name) return;
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ({ value }) => value,
      setValue: (_, value) => {
        setValue(value);
      },
    });
  }, [fieldName, inputRef, name, registerField]);

  const handleChange = (newValue: string): void => {
    inputRef.current.value = newValue;
    setValue(newValue);
    // if (setValueText) setValueText(newValue);
  };

  const handleFocus = (e: globalThis.FocusEvent): void => {
    clearError();
    if (onEditorFocus) onEditorFocus(e);
  };

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ display: hidden ? 'none' : 'flex', width }}
    >
      <FormControl fullWidth disabled={disabled} variant="outlined">
        {label && (
          <InputLabel style={labelStyle} disabled={disabled} error={!!error}>
            {label}
          </InputLabel>
        )}

        <EditorContainer disabled={disabled} error={!!error}>
          {startIcon && (
            <InputAdornment position="start">
              <Icon size={1} path={startIcon} color="action" />
            </InputAdornment>
          )}

          <TextEditor
            name="my-textbox"
            mode="balloon"
            disable={disabled}
            setContents={value}
            onChange={handleChange}
            placeholder={placeholder}
            onFocus={handleFocus}
          />
        </EditorContainer>

        <OutlinedInput
          {...rest}
          defaultValue={defaultValue}
          disabled={disabled}
          inputRef={inputRef}
          error={!!error}
          style={{ display: 'none' }}
        />

        <FormHelperText
          error={!!error}
          style={{ position: 'absolute', bottom: -19 }}
        >
          {error}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default TextEditorForm;
