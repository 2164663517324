import styled from 'styled-components';

interface ContainerProps {
  transparent?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  background: ${props => (props.transparent ? 'none' : 'rgba(0, 0, 0, 0.4)')};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 180ms ease-in-out;

  div {
    padding: 20px;
    border-radius: 6px;
    background: ${props => (props.transparent ? 'transparent' : '#fafafa')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      color: ${props => props.theme.palette.secondary.dark};
      font-size: 22px;
      margin-top: 5px;
    }

    img {
      width: 160px;
      filter: saturate(70) brightness(30) hue-rotate(200deg);
    }
  }
`;
