import React, { useState, useEffect } from 'react';

import { mdiBell } from '@mdi/js';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSnackbar } from '../../../../hooks/snackbar';

import NotificationItem from './components/NotificationItem';
import RoundButton from '../../../Buttons/RoundButton';
import { INotification } from './models';
import { initNotification } from '../../../../models/notification';
import { useNotification } from '../../../../hooks/notification';
import api from './services';

import {
  StyledBadge,
  NotificationDetails,
  StyledPopper,
  EmptyMessage,
} from './styles';

const Notification: React.FC = () => {
  const { setErrorMessage } = useSnackbar();
  const {
    notifications,
    quantity,
    setQuantity,
    setNotification,
  } = useNotification();
  const [eventButton, setEventButton] = useState<HTMLButtonElement | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuantity, setIsLoadingQuantity] = useState(false);
  const [isLoadingNotification, setIsLoadingNotification] = useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setEventButton(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const updateNotification = async (id: number): Promise<void> => {
    setIsLoading(true);
    try {
      await api.putNotification(id);
    } catch (err) {
      setErrorMessage('Houve um erro ao atualizar a notificação.', err, true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  const getQuantityNotification = async (): Promise<void> => {
    setIsLoadingQuantity(true);
    try {
      const res = await api.getQuantity();
      setQuantity(res.quantity);
    } catch (err) {
      setQuantity(initNotification.quantity);
    } finally {
      setIsLoadingQuantity(false);
    }
  };

  const getContentNotification = async (): Promise<void> => {
    setIsLoadingNotification(true);
    try {
      const res = await api.getNotification();
      console.log(res);
      setNotification(res);
    } catch (err) {
      setNotification(initNotification.notification);
    } finally {
      setIsLoadingNotification(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getQuantityNotification();
      getContentNotification();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <StyledBadge color="error" badgeContent={quantity}>
          <RoundButton
            icon={mdiBell}
            iconColor="secondary"
            backgroundColor="secondary"
            onClick={handleClick('bottom-end')}
          />
        </StyledBadge>

        <StyledPopper
          open={open}
          anchorEl={eventButton}
          placement={placement}
          transition
        >
          <Paper>
            <NotificationDetails>
              {isLoadingNotification || isLoading || isLoadingQuantity ? (
                <>
                  <LinearProgress />
                  <EmptyMessage>
                    <span>Carregando...</span>
                  </EmptyMessage>
                </>
              ) : (
                <List>
                  {notifications?.length > 0 ? (
                    notifications?.map((item: INotification, index: number) => (
                      <NotificationItem
                        updateNotification={updateNotification}
                        key={item.id}
                        notifications={item}
                        // date={
                        //   notifications?.length - 1 === index &&
                        //   notifications[index]?.document.createdAt
                        // }
                      />
                    ))
                  ) : (
                    <>
                      <EmptyMessage>
                        <span>Caixa de notificações limpa</span>
                      </EmptyMessage>
                    </>
                  )}
                </List>
              )}
            </NotificationDetails>
          </Paper>
        </StyledPopper>
      </div>
    </ClickAwayListener>
  );
};
export default Notification;
