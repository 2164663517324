import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { IconButton } from '@material-ui/core';

export const IconCloseModal = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const ModalView = styled(Modal)`
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

export const SpanEmailError = styled.span`
  font-size: 12px;
  margin-left: 15px;
  padding: none;
`;

export const ErrorMessage = styled.div`
  width: 10rem;
  height: 1rem;
  color: ${props => props.theme.palette.error.main};
`;
