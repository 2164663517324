import Axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from '../../../../hooks/snackbar';

import Card from '../../../../components/Card';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import apiNotification from '../../../../components/PrivateLayout/Toolbar/Notification/services';
import { IFile } from '../../models';
import api from '../../services';

import { useNotification } from '../../../../hooks/notification';
import { initNotification } from '../../../../models/notification';
import FileList from './components/FileList';
import Upload from './components/Upload';
import { Container } from './styles';

const AbiUpload: React.FC = () => {
  const { setErrorMessage } = useSnackbar();
  const { setNotification, setQuantity } = useNotification();
  const [files, setFiles] = useState<IFile[]>();
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource
  >();

  const updateFile = useCallback(
    (lastFile: IFile, newFile?: IFile): void => {
      const index = files.findIndex(element => element === lastFile);
      if (index === -1) return;

      const filesUpdate = [...files];
      if (newFile) {
        filesUpdate[index] = newFile;
      } else {
        filesUpdate.splice(index, 1);
      }
      setFiles(filesUpdate);
    },
    [files],
  );

  const updateProgress = useCallback(
    (file: IFile, { loaded, total }): void => {
      if (file.status === 'loading') {
        const progress = (loaded / total) * 100;
        console.log(progress);
        updateFile(file, { ...file, progress });
      }
    },
    [updateFile],
  );

  const handleCancel = (): void => {
    cancelTokenSource.cancel();
  };

  useEffect(() => {
    const getQuantityNotification = async (): Promise<void> => {
      try {
        const res = await apiNotification.getQuantity();
        setQuantity(res.quantity);
      } catch (err) {
        setQuantity(initNotification.quantity);
      }
    };

    const getContentNotification = async (): Promise<void> => {
      try {
        const res = await apiNotification.getNotification();
        console.log(res);
        setNotification(res);
      } catch (err) {
        setNotification(initNotification.notification);
      }
    };

    const handleUpload = async (file: IFile): Promise<void> => {
      try {
        const cancelToken = Axios.CancelToken;
        const source = cancelToken.source();
        setCancelTokenSource(source);
        await api.postDocument(file, source, e => updateProgress(file, e));
        updateFile(file, { ...file, status: 'success' });
        getQuantityNotification();
        getContentNotification();
      } catch (err) {
        if (!Axios.isCancel(err)) {
          setErrorMessage('Erro ao enviar o arquivo.', err, true);
        }
        updateFile(file, { ...file, status: 'error' });
      }
    };

    if (files?.length) {
      const index = files.findIndex(
        file => file.status === 'loading' && file.progress > 0,
      );

      if (index === -1) {
        const fileFind = files.find(file => file.status === 'loading');
        if (fileFind) handleUpload(fileFind);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, setErrorMessage, updateFile, updateProgress]);

  return (
    <React.Fragment>
      <PageHeader
        showBackButton
        title="Nova ABI"
        subtitle="Importe arquivos da ABI para cadastrar"
      />

      <Card title="Upload de ABI">
        <Container>
          <Upload setFiles={setFiles} />
          <FileList
            files={files}
            onUpdate={updateFile}
            onCancel={handleCancel}
          />
        </Container>
      </Card>
    </React.Fragment>
  );
};

export default AbiUpload;
