import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 24px;
  display: flex;

  button {
    margin-right: 20px;
  }

  h2 {
    margin-bottom: 4px;
    color: ${props => props.theme.palette.secondary.main};
  }

  /* h3 {
    font-size: 16px;
    font-weight: 600;
  } */
`;
