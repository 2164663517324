import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { useAuth } from '../../../../hooks/auth';
import { useBackupFilters } from '../../../../hooks/backup-filters';
import { CompanyTypeEnum } from '../../../../utils/enum';

import { FabButtonContainer } from '../../../../styles/global';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';

import { IAttendance, IDocument, IProcedure } from '../../models';

import { ABI as Document, Attendance, Procedure, Stepper } from './components';

const DocumentDetails: React.FC = () => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const { clearBackup } = useBackupFilters();
  const document = history.location.state as IDocument;

  const [attendance, setAttendance] = useState<IAttendance>();
  const [procedure, setProcedure] = useState<IProcedure>();
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!document) history.replace('/abis');
  }, [document, history]);

  const handleSelectAttendance = (data: IAttendance): void => {
    setAttendance(data);
    setStep(1);
  };

  const handleSelectProcedure = (data: IProcedure): void => {
    setProcedure(data);
    setStep(2);
  };

  const handleBack = (): void => {
    if (step === 0) {
      clearBackup('Attendances');
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };

  const getButtons = (): number => {
    if (step === 1) {
      if (loggedCompany.type !== CompanyTypeEnum.Requester) return 2;
      return 1;
    }
    return 0;
  };

  return (
    <FabButtonContainer buttons={getButtons()}>
      <PageHeader
        showBackButton
        title="ABI"
        onBack={handleBack}
        subtitle={`Confira os detalhes da "ABI ${document?.numberABI}"`}
      />

      <Grid container spacing={3}>
        <Grid item>
          <Stepper activeStep={step} onChange={setStep} />
        </Grid>

        <Grid item xs>
          {step === 0 && (
            <Document
              document={document}
              onSelectAttendance={handleSelectAttendance}
            />
          )}

          {step === 1 && (
            <Attendance
              document={document}
              attendance={attendance}
              onChangeAttendance={setAttendance}
              onSelectProcedure={handleSelectProcedure}
            />
          )}

          {step === 2 && <Procedure procedure={procedure} />}
        </Grid>
      </Grid>

      {/* {step === 1 &&
        (loggedCompany.type !== CompanyTypeEnum.Requester ? (
          <React.Fragment>
            <FabButton
              size="small"
              color="primary"
              onClick={handleOpen}
              bottom={12}
            >
              <Icon size={1} path={mdiViewList} />
            </FabButton>
            <FabButton
              color="secondary"
              onClick={handleDefense}
              disabled={!attendance.attendanceAdicionalInfo}
            >
              <Icon size={1} path={mdiShieldEdit} />
            </FabButton>
          </React.Fragment>
        ) : (
          <FabButton color="secondary" onClick={handleOpen}>
            <Icon size={1} path={mdiBookEdit} />
          </FabButton>
        ))} */}

      {/* {(step === 0 || step === 1) && openModal && (
        <AttendanceModal
          attendance={attendance}
          isVisible={openModal}
          onClose={handleClose}
        />
      )} */}
    </FabButtonContainer>
  );
};

export default DocumentDetails;
