import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from '../../components/Buttons/Button';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

const Cookies: React.FC<Props> = ({ onClose, open = false }) => {
  const styles = useStyles();

  const setCookie = (e: string, o: string, i: number): void => {
    let expires = '';
    if (i) {
      const date = new Date();
      date.setTime(date.getTime() + (24 * i * 60 * 60 * 1e3)); //eslint-disable-line
      expires = "; expires=" + date.toUTCString(); //eslint-disable-line
    }
    document.cookie = e + "=" + (o || "") + expires + "; path=/" //eslint-disable-line
  };

  const handleAcceptCookie = (): void => {
    setCookie('Resus', '1', 7);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <>
      <Dialog
        className={styles.Dialog}
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cookies</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black', fontSize: 15 }}>
            Ao usar este site, você aceita automaticamente o uso de Cookies.
          </DialogContentText>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cdn.cloudmed.io/cloudmed/index.html"
            style={{ fontSize: 15 }}
          >
            Política de Privacidade e Termo de uso
          </a>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            buttonColor="secondary"
            variant="contained"
            onClick={handleAcceptCookie}
            style={{ marginTop: 5 }}
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cookies;
