import { Grid } from '@material-ui/core';
import React from 'react';

import companyPhoto from '../../../../../../assets/companies/cloudmed.png';

import { IUserCompany } from '../../../../models';

import { Container } from './styles';

interface Props {
  company: IUserCompany;
  onClick(company: IUserCompany): void;
}

const CompanyButton: React.FC<Props> = ({ company, onClick }) => (
  <Grid item xs={12} sm={6}>
    <Container onClick={() => onClick(company)}>
      <img src={companyPhoto} alt="Imagem da Empresa" />
      <p>{company.companyName}</p>
    </Container>
  </Grid>
);

export default CompanyButton;
