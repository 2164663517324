/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Grid,
  FormControl,
  Select,
  SelectProps,
  InputLabel,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import Icon from '@mdi/react';

type FocusEvent = React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>;
type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props<T extends object> extends SelectProps {
  label: string;
  options: T[];
  optionName: string;
  optionValue: string;
  startIcon?: string;
  hidden?: boolean;
  onFocus?(e: FocusEvent): void;
  onChange?(
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  );
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const SelectForm = <T extends object>({
  label,
  options,
  optionName,
  optionValue,
  startIcon = null,
  placeholder = null,
  disabled = false,
  defaultValue = 0,
  hidden = false,
  onFocus = null,
  onChange = null,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  multiple = false,
  ...rest
}: Props<T>): JSX.Element => {
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ display: hidden ? 'none' : 'flex' }}
    >
      <FormControl fullWidth disabled={disabled}>
        <InputLabel>{label}</InputLabel>

        <Select
          {...rest}
          multiple={multiple}
          startAdornment={
            startIcon ? (
              <InputAdornment position="start">
                <Icon size={1} path={startIcon} color="action" />
              </InputAdornment>
            ) : (
              undefined
            )
          }
          onChange={onChange}
          onFocus={onFocus}
          defaultValue={defaultValue}
          label={label}
          variant="outlined"
          error={false}
          disabled={disabled}
        >
          {placeholder && (
            <MenuItem value={-1} disabled>
              {placeholder}
            </MenuItem>
          )}

          {options.map(option => (
            <MenuItem key={option[optionValue]} value={option[optionValue]}>
              {option[optionName]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SelectForm;
