import { api } from '../../../services/api';

import { IDefenseText } from '../models';

const getDefenseTextById = async (id: number): Promise<IDefenseText> => {
  const res = await api.get(`/defaultdefense/${id}`);
  const { data } = res.data;
  return data;
};

const postDefenseText = async (company: IDefenseText): Promise<void> => {
  await api.post('/defaultdefense', company);
};

const putDefenseText = async (company: IDefenseText): Promise<void> => {
  await api.put(`/defaultdefense`, company);
};

export default {
  getDefenseTextById,
  postDefenseText,
  putDefenseText,
};
