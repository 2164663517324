import styled from 'styled-components';

import background from '../../assets/background/background.svg';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-color: ${props => props.theme.palette.background.paper};

  > div {
    width: 100%;
    max-width: 480px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-bottom: 16px;
    }

    p {
      color: ${props => props.theme.palette.text.secondary};
      font-size: 18px;
      text-align: center;
    }

    button {
      border-radius: 32px;
      margin-top: 32px;
    }
  }
`;
