import React, { createContext, useState, useContext, useCallback } from 'react';
import { IMessageSnackbar, initMessage } from '../models/snackbar';
import Snackbar from '../components/Snackbar';

interface ErrorMessage {
  data?: {
    error: string;
  };
  response?: {
    data: {
      error: string;
    };
  };
}

interface SnackbarContextData {
  message: IMessageSnackbar;
  setMessage(message: IMessageSnackbar): void;
  setErrorMessage<T extends ErrorMessage>(
    defaultMessage: string,
    error?: T,
    showAdminMessage?: boolean,
  ): void;
}

const SnackbarContext = createContext({} as SnackbarContextData);

const SnackbarProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState(initMessage);
  const [open, setOpen] = useState(false);

  const handleMessage = useCallback(
    (messageSnackbar: IMessageSnackbar): void => {
      setMessage(messageSnackbar);
      setOpen(true);
    },
    [],
  );

  const setErrorMessage = useCallback(
    // eslint-disable-next-line func-names
    function<T extends ErrorMessage>(
      defaultMessage: string,
      error: T,
      addAdminMessage = false,
    ): void {
      let errorMessage = defaultMessage;

      if (addAdminMessage) {
        errorMessage += ' Por favor, entre em contato com um administrador.';
      }

      if (error) {
        const { data, response } = error;
        if (data?.error) {
          errorMessage = data.error;
        } else if (response?.data?.error) {
          errorMessage = response.data.error;
        }
      }
      handleMessage({ type: 'error', message: errorMessage });
    },
    [handleMessage],
  );

  return (
    <SnackbarContext.Provider
      value={{ message, setMessage: handleMessage, setErrorMessage }}
    >
      <Snackbar
        open={open}
        severity={message.type}
        message={message.message}
        onClose={() => setOpen(false)}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = (): SnackbarContextData => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within an SnackbarProvider');
  }
  return context;
};

export { SnackbarProvider, useSnackbar };
