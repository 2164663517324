/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  StandardTextFieldProps,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import Icon from '@mdi/react';

import { MaskArray } from '../../../utils/masks';

import Masked from './masked';
import { MyInput } from './styles';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
type FocusEvent = React.FocusEvent<HTMLInputElement>;
type KeyboardEvent = React.KeyboardEvent<HTMLInputElement>;
type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Props extends StandardTextFieldProps {
  name?: string;
  label?: string;
  mask?: MaskArray | MaskArray[];
  appearance?: 'input' | 'textArea';
  disableDefaultValue?: boolean;
  mainRef?: React.MutableRefObject<any>;
  width?: number | string;
  hidden?: boolean;
  shrinkAlwaysVisible?: boolean;
  labelStyle?: React.CSSProperties;
  startIcon?: string;
  endIcon?: string | React.ReactElement;
  maxLength?: number | string;
  setText?(text: string): void;
  onChange?(e: ChangeEvent): void;
  onFocus?(e: FocusEvent): void;
  onBlur?(e: FocusEvent): void;
  onBlurShrink?(e: FocusEvent, setShrink: (shrink: boolean) => void): void;
  onKeyPress?(e: KeyboardEvent): void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const InputForm: React.FC<Props> = ({
  name = undefined,
  label = null,
  appearance = 'input',
  mask = null,
  disableDefaultValue = false,
  mainRef = null,
  fullWidth = true,
  width = null,
  hidden = false,
  labelStyle = {},
  InputProps = {},
  startIcon = null,
  endIcon = null,
  maxLength = null,
  onChange = null,
  onFocus = null,
  onBlur = null,
  onKeyPress = null,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
  ...rest
}) => {
  const auxRef = useRef(null);
  const inputRef = mainRef || auxRef;
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name || 'undefined');
  const [maskedStartValue, setMaskedStartValeu] = useState(defaultValue);

  const inputProps = {
    startAdornment: startIcon ? (
      <InputAdornment position="start">
        <Icon size={1} path={startIcon} color="action" />
      </InputAdornment>
    ) : null,
    endAdornment: endIcon ? (
      <InputAdornment position="end">
        {typeof endIcon === 'string' ? (
          <Icon size={1} path={endIcon} />
        ) : (
          React.cloneElement(endIcon)
        )}
      </InputAdornment>
    ) : null,
  };

  useEffect(() => {
    if (!name) return;
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ({ value }) => value.trim(),
      setValue: (ref, value) => {
        setMaskedStartValeu(value);
        ref.value = value;
      },
    });
  }, [fieldName, inputRef, name, registerField]);

  const handleFocus = (e: FocusEvent): void => {
    clearError();
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent): void => {
    inputRef.current.value = inputRef.current.value.trim();
    if (onBlur) onBlur(e);
  };

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ display: hidden ? 'none' : 'flex', width }}
    >
      <MyInput
        {...rest}
        {...(!disableDefaultValue && { defaultValue })}
        fullWidth={fullWidth}
        appearance={appearance}
        label={label}
        InputLabelProps={{
          shrink: true,
          style: {
            display: label ? null : 'none',
            ...labelStyle,
          },
        }}
        inputProps={{
          maxLength,
        }}
        inputRef={inputRef}
        error={!!error}
        helperText={error}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        FormHelperTextProps={{
          style: {
            position: 'absolute',
            bottom: -19,
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={
          mask
            ? {
                ...InputProps,
                ...inputProps,
                inputComponent: Masked as any,
                inputProps: {
                  mask,
                  startValue: maskedStartValue,
                  onChange,
                  onKeyPress,
                },
              }
            : { ...InputProps, ...inputProps }
        }
      />
    </Grid>
  );
};

export default InputForm;
