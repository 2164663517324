import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';

export const StyledBadge = styled(Badge)`
  span {
    transform: none;
  }
`;

export const NotificationDetails = styled.div`
  height: 152px;
  width: 20rem;
  overflow-wrap: break-word;
  overflow: auto;
  ::-webkit-scrollbar {
    background: transparent;
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);

    border-radius: 10px;
  }
  @media (max-width: 500px) {
    width: 17rem;
    height: 140px;
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 2;
  .MuiList-root .MuiList-padding {
    padding: 0;
  }
`;

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;
