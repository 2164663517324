import React from 'react';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { useAuth } from '../../hooks/auth';

interface Props {
  open: boolean;
  message: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  onClose: () => void;
}

const Snackbar: React.FC<Props> = ({
  message,
  severity = 'info',
  onClose,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();

  const ignoreAuthentication = (): boolean => {
    const { pathname } = window.location;
    return pathname === '/expirou' || pathname === '/senha';
  };

  const handleClose = (_: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') return;
    onClose();
  };

  return (
    <MuiSnackbar
      {...rest}
      onClose={handleClose}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{
        marginTop: !ignoreAuthentication() && isAuthenticated() ? 76 : 0,
      }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={handleClose}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
