import styled from 'styled-components';
import { Fab, FabProps } from '@material-ui/core';

interface FabButtonProps extends FabProps {
  bottom?: number;
}
interface FabButtonContainerProps {
  buttons?: number;
}

interface StatusProps {
  color: string;
}

export const FabButtonContainer = styled.div<FabButtonContainerProps>`
  margin-bottom: ${props =>
    props.theme.spacing(
      (props.buttons || props.buttons === 0 ? props.buttons : 1) * 10,
    )}px;
`;

export const FabButton = styled(Fab)<FabButtonProps>`
  position: fixed;
  z-index: 10;
  right: ${props => props.theme.spacing(props.size === 'small' ? 4 : 3)}px;
  bottom: ${props => props.theme.spacing(props.bottom || 3)}px;
`;

export const DataSeparateTitle = styled.span`
  font-weight: 500;
  color: ${props => props.theme.palette.secondary.main};
`;

export const StatusContainer = styled.div<StatusProps>`
  position: relative;
  background-color: ${props => props.color};
  border-radius: 40px !important;
  width: 120px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    color: #fff;
    font-weight: 500;
    margin-top: 1px;
  }
`;

export const ListLinesContainer = styled.span`
  display: flex;
  flex-direction: column;
`;
