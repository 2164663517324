import styled from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  width: ${props => (props.isOpen ? 250 : 48)}px;
  height: 100vh;
  padding: 0 ${props => (props.isOpen ? 24 : 16)}px;
  position: fixed;
  z-index: 800;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
    110deg,
    ${props => props.theme.palette.primary.main} 25%,
    ${props => props.theme.palette.secondary.main} 130%
  );
  transition: width 180ms, padding 180ms;
  box-shadow: 2px 0 20px 5px rgba(0, 0, 0, 0.192);
  overflow-x: hidden;
  overflow-y: overlay;
`;

export const HeaderContainer = styled.div<Props>`
  width: 100%;
  padding: 12px 0 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;

  > div {
    display: flex;
    color: white;
    align-items: center;

    img {
      margin-left: 4px;
      width: 40px;
      height: 48px;
    }

    p {
      max-height: 48px;
      width: 134px;
      margin: 0 12px;
      flex: 1;
      opacity: ${props => (props.isOpen ? 1 : 0)};
      overflow: hidden;
      font-size: 18px;
      font-weight: 500;
      transition: opacity 180ms;
    }

    span {
      font-weight: 400;
    }
  }
`;

export const ItemsContainer = styled.div<Props>`
  width: 100%;
  margin-top: 24px;

  a {
    > svg {
      margin-left: ${props => (props.isOpen ? 0 : -4)}px;
      transition: margin-left 200ms;
    }

    > span {
      opacity: ${props => (props.isOpen ? 1 : 0)};
      transition: opacity 200ms;
    }
  }
`;

export const FooterContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  padding: 12px;

  > span {
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    transition: opacity 200ms;
    color: white;
    font-weight: 500;
    overflow: hidden;
    margin-left: 20px;
  }

  &:hover {
    background-color: white;
    border-radius: 32px;
  }

  &:hover:not(.selected) {
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (min-width: 600px) {
    display: none;
  }
`;
