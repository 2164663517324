import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import { mdiEye, mdiMagnify } from '@mdi/js';

import { useSnackbar } from '../../../../hooks/snackbar';
import { IDateFilter } from '../../../../models/api';
import { formatDate } from '../../../../utils/helpers/format-helper';

import {
  Input as InputFilter,
  DatePicker,
} from '../../../../components/Filters';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import AsyncTable from '../../../../components/Tables/AsyncTable';

import { ILog } from '../../models';
import api from '../../services';

const LogList: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useSnackbar();
  const [initialDate, setInitialDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filters, setFilters] = useState<IDateFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const columns: Column<ILog>[] = [
    {
      title: 'Descrição',
      field: 'description',
    },
    {
      title: 'Data',
      render: ({ createAt }) => formatDate(createAt, true, true),
    },
    // {
    //   title: 'Usuário',
    //   render: ({ userName }) => userName || 'Não Informado',
    // },
    // {
    //   title: 'Empresa',
    //   render: ({ companyName }) => companyName || 'Não Informado',
    // },
  ];

  const handleEdit = async ({ id }: ILog): Promise<void> => {
    try {
      setIsLoading(true);
      const log = await api.getLogById(id);
      history.push({
        pathname: `/logs/detalhes/${id}`,
        state: log,
      });
    } catch (err) {
      setErrorMessage('Houve um erro ao buscar os dados do log.', err, true);
      setIsLoading(false);
    }
  };

  const handleInitialDate = (date: Date): void => {
    setInitialDate(date);

    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFilters({
      ...filters,
      initialDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
    });
  };

  const handleEndDate = (date: Date): void => {
    setEndDate(date);

    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFilters({
      ...filters,
      endDate: formattedDate !== 'Invalid date' ? formattedDate : undefined,
    });
  };

  const actions: Action<ILog>[] = [
    {
      icon: () => <Icon size={1} path={mdiEye} />,
      tooltip: 'Visualizar',
      onClick: (_, log) => handleEdit(log as ILog),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ ...filters, term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os logs.', err, true);
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Logs"
        subtitle="Aqui é listado todos os logs gerados pelo sistema"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={6}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <DatePicker
          sm={4}
          md={3}
          label="De"
          valueInput={initialDate}
          onChange={handleInitialDate}
          placeholder="Data Inicial"
        />

        <DatePicker
          sm={4}
          md={3}
          label="Até"
          valueInput={endDate}
          minDate={initialDate}
          onChange={handleEndDate}
          placeholder="Data Final"
        />

        <Grid item xs={12}>
          <AsyncTable
            actions={actions}
            columns={columns}
            dataKey="logs"
            dataUrl="/log"
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            onError={handleError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogList;
