import React, { createContext, useState, useContext, useEffect } from 'react';

import { INotification } from '../components/PrivateLayout/Toolbar/Notification/models';

interface NotificationContextData {
  notifications: INotification[];
  quantity: number;
  setNotification(data: INotification[]): void;
  setQuantity(data: number): void;
}

const NotificationContext = createContext({} as NotificationContextData);

const NotificationProvider: React.FC = ({ children }) => {
  const getNotification = (): INotification[] => {
    const data = localStorage.getItem('@notification');
    try {
      return JSON.parse(data);
    } catch (_) {
      return null;
    }
  };

  const getQuantity = (): number => {
    const data = localStorage.getItem('@quantity');
    try {
      return JSON.parse(data);
    } catch (_) {
      return null;
    }
  };

  const [notification, setNotification] = useState<INotification[]>(
    getNotification(),
  );

  const [quantity, setQuantity] = useState<number>(getQuantity());

  useEffect(() => {
    if (notification) {
      localStorage.setItem('@notification', JSON.stringify(notification));
    }
  }, [notification]);

  useEffect(() => {
    if (quantity) {
      localStorage.setItem('@quantity', JSON.stringify(quantity));
    }
  }, [quantity]);

  return (
    <NotificationContext.Provider
      value={{
        notifications: notification,
        quantity,
        setNotification,
        setQuantity,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = (): NotificationContextData => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'Erro ao carregar notificações. Entre em contato com o administrador',
    );
  }
  return context;
};

export { useNotification, NotificationProvider };
