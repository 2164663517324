import styled from 'styled-components';

export const ContainerFrame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const FramePolicy = styled.iframe`
  height: 100%;
  width: 100%;
  min-height: 550px;
  border-radius: 10px;
  border: 2px solid ${props => props.theme.palette.primary.main} !important;

  @media (min-width: 960px) {
    max-width: 80% !important;
  }
`;
