import React, { useCallback } from 'react';
import { useTheme, Theme } from '@material-ui/core/styles';
import { IconButtonProps } from '@material-ui/core';
import Icon from '@mdi/react';

import { IPaletteColor, IPaletteVariantColor } from '../../../models/palette';
import {
  validatePaletteColor,
  validateHexColor,
} from '../../../utils/helpers/colors-helper';

import { MyButton } from './styles';

interface Props extends IconButtonProps {
  icon: string;
  backgroundColor?: IPaletteColor | string;
  iconColor?: IPaletteColor | string;
  variantColor?: IPaletteVariantColor;
  buttonSize?: number;
}

const RoundButton: React.FC<Props> = ({
  icon,
  backgroundColor = '#e9e9e9',
  iconColor = null,
  variantColor = 'main',
  buttonSize = 6,
  ...props
}) => {
  const { palette } = useTheme() as Theme;

  const getBackgroundColor = useCallback((): string => {
    if (validatePaletteColor(backgroundColor)) {
      return palette[backgroundColor].light;
    }
    if (validateHexColor(backgroundColor)) {
      return backgroundColor;
    }
    return '#e9e9e9';
  }, [backgroundColor, palette]);

  const getIconColor = useCallback((): string => {
    if (validatePaletteColor(iconColor)) {
      return palette[iconColor][variantColor];
    }
    if (validateHexColor(iconColor)) {
      return iconColor;
    }
    return palette.primary[variantColor];
  }, [iconColor, variantColor, palette]);

  return (
    <MyButton
      {...props}
      backgroundColor={getBackgroundColor()}
      iconColor={getIconColor()}
      buttonSize={buttonSize}
    >
      <Icon size={1} path={icon} />
    </MyButton>
  );
};

export default RoundButton;
