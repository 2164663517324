import { api, api_no_header } from '../../../services/api';

import { IFormUser, IPassword, IUserCompany } from '../models';

export const getCompanies = async (): Promise<IUserCompany[]> => {
  const res = await api.get('/company');
  const { companies } = res.data.data;
  return companies;
};

const getUserById = async (id: number): Promise<IFormUser> => {
  const res = await api.get(`/user/${id}`);
  const { data } = res.data;
  return data;
};

const postUser = async (user: IFormUser): Promise<void> => {
  await api.post('/user', user);
};

export const putUser = async (user: IFormUser): Promise<void> => {
  await api.put('/user', user);
};

const postPassword = async (password: IPassword): Promise<void> => {
  await api_no_header.post('/user/confirm/password', password);
};

export default {
  getCompanies,
  getUserById,
  postUser,
  putUser,
  postPassword,
};
