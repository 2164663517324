import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import { useField } from '@unform/core';

// import { Container } from './styles';

type Size = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface OptionsProps {
  name: string;
  label: string;
  value: string | number;
}

interface Props extends RadioGroupProps {
  options: OptionsProps[];
  name: string;
  label?: string;
  defaultValue?: string | number;
  onValueChange?: (value: string | number) => void;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

const RadioGroupForm: React.FC<Props> = ({
  options,
  name,
  label = null,
  defaultValue: startValue,
  onValueChange = null,
  xs = 12,
  sm = null,
  md = null,
  lg = null,
  xl = null,
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [value, setValue] = useState<string | number>(
    defaultValue || startValue,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => value,
      setValue: (_, val) => {
        setValue(val);
      },
    });
  }, [fieldName, value, registerField]);

  const setStringValue = (v: string): void => {
    setValue(v);
    if (onValueChange) onValueChange(v);
  };

  const setNumberValue = (v: string): void => {
    const n = Number(v);
    setValue(n);
    if (onValueChange) onValueChange(n);
  };

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
  ): void => {
    if (typeof options[0].value === 'number') {
      try {
        setNumberValue(newValue);
      } catch (err) {
        setStringValue(newValue);
      }
    } else {
      setStringValue(newValue);
    }
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <FormControl component="fieldset">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <RadioGroup
          defaultValue={defaultValue || startValue}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {options.map((option: OptionsProps) => (
            <FormControlLabel
              {...option}
              key={option.name}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default RadioGroupForm;
