import styled from 'styled-components';

export const ContentDate = styled.div`
  min-width: 85px;
`;

export const ContentMainProcedure = styled.div`
  max-width: 95px;
  overflow: auto;
`;
