export type MaskArray = (string | RegExp)[];

export const cnpjMask: MaskArray = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /[0-1]/,
  /[0-1]/,
  /[0-1]/,
  /[0-1]/,
  '-',
  /\d/,
  /\d/,
];
