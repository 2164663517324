import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Icon from '@mdi/react';

import { useBackupFilters } from '../../../../hooks/backup-filters';

import { NavLink, Title } from './styles';

interface Props {
  icon: string;
  route: string;
  title: string;
  isOpen: boolean;
  isVisible?: boolean;
}

const NavItem: React.FC<Props> = ({
  icon,
  route,
  title,
  isOpen,
  isVisible = true,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const { clearBackup } = useBackupFilters();

  const isSelected = (): boolean => {
    return pathname === route || pathname.indexOf(`${route}/`) >= 0;
  };

  if (!isVisible) return null;

  return (
    <Tooltip title={isOpen ? '' : title}>
      <NavLink
        className={isSelected() && 'selected'}
        to={route}
        onClick={() => {
          clearBackup();
          clearBackup('Attendances');
        }}
      >
        <Icon size={1} path={icon} />
        <Title title={title}>{title}</Title>
      </NavLink>
    </Tooltip>
  );
};

export default NavItem;
