import React from 'react';
import resusPhoto from '../../assets/resus/LogoReSUS.png';
import { Container } from './styles';

interface Props {
  size?: number;
  sizeImage?: number;
}

const Logo: React.FC<Props> = ({ size = 24, sizeImage = 48 }) => {
  return (
    <Container size={size} sizeImage={sizeImage}>
      <img src={resusPhoto} alt="Imagem da Empresa" />
    </Container>
  );
};

export default Logo;
