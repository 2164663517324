import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { IconButton } from '@material-ui/core';

export const ItemList = styled.button`
  z-index: 0;
  width: 100%;
  display: flex;
  text-align: initial;
  background-color: ${props => props.theme.palette.background.paper};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

export const SelectedItem = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .MuiIconButton-root {
    padding: 0;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ItemOptions = styled.div`
  padding: 8px;
`;

export const ItemContent = styled.span`
  margin-left: 10px;
`;

export const StyledPopper = styled(Popper)`
  z-index: 3;
  position: relative;
  left: 70% !important;
  top: 110px !important;
`;

export const StyledPaper = styled(Paper)`
  position: absolute;
  top: 50px;
  width: 18.4rem;
  height: 7rem;
  border: 1px solid #000;
  padding: 0 10px 10px 10px;
  z-index: 5;
  .sc-fzozJi {
    height: 0px;
  }
`;

export const ItemTitle = styled.div`
  width: 270px;
  padding: 8px;
`;

export const ItemDate = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  border-top: 1px solid #000;
  padding: 8px;
`;

export const Selected = styled.span`
  font-size: 12px;
  padding: 0 0 10px 14px;
`;

export const ArrowButton = styled(IconButton)`
  justify-content: flex-end;
  margin-top: 6px;
  align-items: flex-end;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  height: 2px;
`;

export const OptionsButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: transparent;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }
`;
