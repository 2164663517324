import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPencil } from '@mdi/js';
import { Grid } from '@material-ui/core';
import { Action, Column } from 'material-table';

import { formatCnpj } from '../../../../utils/helpers/format-helper';
import { useSnackbar } from '../../../../hooks/snackbar';
import { IBasicFilter } from '../../../../models/api';
import { ListLinesContainer } from '../../../../styles/global';

import InputFilter from '../../../../components/Filters/Input';
import PageHeader from '../../../../components/PrivateLayout/PageHeader';
import AsyncTable from '../../../../components/Tables/AsyncTable';

import { IDefenseList } from '../../models';
import api from '../../services';

const DefenseList: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useSnackbar();
  const [filters, setFilters] = useState<IBasicFilter>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const renderOperatorCnpj = ({ document }: IDefenseList): JSX.Element => (
    <ListLinesContainer>
      {document.companyName}
      <b>{formatCnpj(document.cnpj)}</b>
    </ListLinesContainer>
  );

  const renderCreateAt = ({ createAt }: IDefenseList): JSX.Element => (
    <ListLinesContainer>
      {moment(createAt).format('DD-MM-YYYY')}
      <b>{moment(createAt).format('HH:mm')}</b>
    </ListLinesContainer>
  );

  const columns: Column<IDefenseList>[] = [
    {
      title: 'ABI',
      field: 'document.numberABI',
    },
    {
      title: 'Número do ofício',
      field: 'document.tradeNumber',
    },
    {
      title: 'Nome da operadora / CNPJ',
      render: renderOperatorCnpj,
    },
    {
      title: 'Nº Order',
      field: 'attendance.orderNumber',
    },
    {
      title: 'Atendimento',
      field: 'number',
    },
    {
      title: 'Data',
      render: renderCreateAt,
    },
  ];

  const handleEdit = async ({ id }: IDefenseList): Promise<void> => {
    try {
      setIsLoading(true);
      const defense = await api.getDefenseById(id);
      history.push({
        pathname: `/defesas/editar/${id}`,
        state: defense,
      });
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao buscar os dados da empresa.',
        err,
        true,
      );
      setIsLoading(false);
    }
  };

  const actions: Action<IDefenseList>[] = [
    {
      icon: () => <Icon size={1} path={mdiPencil} />,
      tooltip: 'Editar',
      onClick: (_, defense) => handleEdit(defense as IDefenseList),
    },
  ];

  const handleChange = (term: string): void => {
    setFilters({ term });
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar as defesas.', err, true);
  };

  return (
    <React.Fragment>
      <PageHeader title="Defesas" subtitle="Aqui estão as defesas" />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputFilter
            label="Pesquisar"
            startIcon={mdiMagnify}
            defaultValue={filters?.term}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncTable
            isLoading={isLoading}
            actions={actions}
            columns={columns}
            dataKey="defenses"
            dataUrl="/defense"
            filters={filters}
            setFilters={setFilters}
            onError={handleError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DefenseList;
