import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    // '& > *': {
    //   margin: theme.spacing(2),
    // },
  },
  btn: {
    backgroundColor: '#69ded8',
    color: '#252f60',
    '&:hover': {
      backgroundColor: '#252f60', // Cor de fundo ao passar o mouse
      color: '#fff', // Cor do texto ao passar o mouse
    },
  },
}));

interface Props {
  handleOnClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
const SubmitAns: React.FC<Props> = ({ handleOnClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        className={classes.btn}
        onClick={handleOnClick}
      >
        Enviar Documento
      </Button>
    </div>
  );
};

export default SubmitAns;
