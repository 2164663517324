import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  box-shadow: ${props => props.theme.shadows[2]};
  border-radius: 48px;
  background-color: ${props => props.theme.palette.background.paper};
  cursor: pointer;

  img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  p {
    margin: 0 12px;
    color: ${props => props.theme.palette.info.main};
    font-weight: 500;
    font-size: 20px;
  }

  :hover {
    background-image: linear-gradient(
      90deg,
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.secondary.main}
    );

    p {
      color: white;
    }
  }
`;
