import axios, { AxiosRequestConfig } from 'axios';
import { getAuthHeader, updateToken } from '../../utils/auth-header';

interface Response {
  data: {
    token: string;
  };
}

interface ErrorResponse {
  response: {
    status: number;
  };
}

const DEFAULT_TIMEOUT = 20000;

const addHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const headers = {
    ...getAuthHeader(),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  const c = config;
  c.headers = headers;
  return c;
};

const handleError = <T extends ErrorResponse>(
  err: T,
  noResponseMessage = false,
): Promise<T> => {
  if (err.response) {
    if (err.response.status === 401) {
      window.location.replace('/expirou');
      return Promise.reject(new Error('Sessão expirada'));
    }
    return Promise.reject(noResponseMessage ? err : err.response);
  }
  return Promise.reject(err);
};

const handleResponse = <T extends Response>(res: T): T => {
  if (res && res.data && res.data.token) {
    updateToken(res.data.token);
  }
  return res;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: DEFAULT_TIMEOUT,
});

api.interceptors.request.use(config => addHeaders(config));

api.interceptors.response.use(
  res => handleResponse(res),
  err => handleError(err),
);

const api_no_timeout = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_no_timeout.interceptors.request.use(config => addHeaders(config));

api_no_timeout.interceptors.response.use(
  res => handleResponse(res),
  err => handleError(err),
);

const api_no_header = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: DEFAULT_TIMEOUT,
});

export { api, api_no_timeout, api_no_header };
