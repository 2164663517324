/* eslint-disable react/jsx-no-undef */
import Axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useState } from 'react';
import apiNotification from '../../../../components/PrivateLayout/Toolbar/Notification/services';
import { useNotification } from '../../../../hooks/notification';
import { useSnackbar } from '../../../../hooks/snackbar';
import { initNotification } from '../../../../models/notification';
import { IFile } from '../../../Document/models';

interface DropzoneProps {
  files: Array<IFile>;
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
  onUpload?: (file: IFile) => void;
  onUpdate?: (lastFile: IFile, newFile: IFile) => void;
  onCancel?: () => void;
}

// const UploadDoc: React.FC<DropzoneProps> = ({
//   files,
//   onUpload,
//   onUpdate,
//   onCancel,
//   setFiles,
// }) => {
//   // eslint-disable-next-line no-use-before-define

//   return (
//     <React.Fragment>
//       <CustomFileList>
//       </CustomFileList>
//     </React.Fragment>
//   );
// };

// eslint-disable-next-line
export const useFileUploader = () => {
  const [files, setFiles] = useState<Array<IFile>>();
  const { setNotification, setQuantity } = useNotification();
  const { setErrorMessage } = useSnackbar();
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource
  >();

  const updateFile = useCallback(
    (lastFile: IFile, newFile?: IFile): void => {
      const index = files.findIndex(element => element === lastFile);
      if (index === -1) return;
      // console.log('teste');

      const filesUpdate = [...files];
      if (newFile) {
        filesUpdate[index] = newFile;
      } else {
        filesUpdate.splice(index, 1);
      }
      setFiles(filesUpdate);
    },
    [files],
  );

  const getQuantityNotification = async (): Promise<void> => {
    try {
      const res = await apiNotification.getQuantity();
      setQuantity(res.quantity);
    } catch (err) {
      setQuantity(initNotification.quantity);
    }
  };

  const getContentNotification = async (): Promise<void> => {
    try {
      const res = await apiNotification.getNotification();
      console.log(res);
      setNotification(res);
    } catch (err) {
      setNotification(initNotification.notification);
    }
  };

  const updateProgress = useCallback(
    (file: IFile, { loaded, total }): void => {
      if (file.status === 'loading') {
        const progress = (loaded / total) * 100;
        console.log(progress);
        updateFile(file, { ...file, progress });
      }
    },
    [updateFile],
  );

  const handleUpload = async (file: IFile): Promise<void> => {
    try {
      const cancelToken = Axios.CancelToken;
      const source = cancelToken.source();
      setCancelTokenSource(source);
      // await api.postDocument(file, source, e => updateProgress(file, e));
      updateFile(file, { ...file, status: 'success' });
      getQuantityNotification();
      getContentNotification();
    } catch (err) {
      if (!Axios.isCancel(err)) {
        setErrorMessage('Erro ao enviar o arquivo.', err, true);
      }
      updateFile(file, { ...file, status: 'error' });
    }
  };

  const handleCancel = (): void => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
  };

  return {
    handleCancel,
    updateFile,
    handleUpload,
    files,
    setFiles,
  };
};
