/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Grid, GridProps } from '@material-ui/core';

interface ButtonsGridProps extends GridProps {
  minWidthGrid: number;
}

export const ProgressGrid = styled.div`
  width: 100%;

  > div {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const MyButtonsGridTransient = ({
  minWidthGrid,
  ...rest
}: ButtonsGridProps): React.ReactElement => <Grid {...rest} />;

export const ButtonsGrid = styled(MyButtonsGridTransient)`
  transition: 180ms;

  @media (min-width: ${props => props.minWidthGrid}px) {
    button {
      :not(.back-button) {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: ${props => props.minWidthGrid - 1}px) {
    display: block;

    button {
      width: 100%;

      :not(.back-button) {
        margin-top: 16px;
      }
    }
  }
`;
