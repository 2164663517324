import React from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const GenericModal = ({ open, onClose, message, isError }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h6" gutterBottom>
          {isError ? 'Error' : 'Success'}
        </Typography>
        <Typography variant="body2" color={isError ? 'error' : 'textPrimary'}>
          {message}
        </Typography>
      </div>
    </Modal>
  );
};

export default GenericModal;
