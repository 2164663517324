import styled, { css } from 'styled-components';

interface DropProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

interface ColorProps {
  type?: 'success' | 'error';
}

const dragActive = css`
  border-color: ${props => props.theme.palette.primary.main};
`;

const dragReject = css`
  border-color: ${props => props.theme.palette.error.main};
`;

const iconDefault = css`
  color: ${props => props.theme.palette.secondary.main};
`;

export const DropContainer = styled.div<DropProps>`
  padding: 8px;
  background-image: linear-gradient(
    100deg,
    ${props => props.theme.palette.primary.main} 10%,
    ${props => props.theme.palette.secondary.main} 180%
  );
  border-radius: 28px;
  outline: none;

  > div {
    border-radius: 20px;
    background-color: #f2f2f2;
    cursor: pointer;

    transition: height 0.2s ease;
    ${props => props.isDragActive && dragActive}
    ${props => props.isDragReject && dragReject}
  }
`;

export const UploadMessage = styled.p<ColorProps>`
  display: flex;
  color: ${props => {
    const { palette } = props.theme;
    switch (props.type) {
      case 'success':
        return palette.success.main;
      case 'error':
        return palette.error.main;
      default:
        return '#7A7A7A';
    }
  }};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 120px;
  font-size: 20px;

  svg {
    margin-right: 6px;
    ${props => !props.type && iconDefault}
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
