import styled from 'styled-components';

interface Props {
  color?: string;
}

export const ColorDueDate = styled.div<Props>`
  color: ${props => props.color};
  font-weight: 500;
`;
