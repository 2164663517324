import { api } from '../../../services/api';

import { IExpiredDocument } from '../models';

const getReapExpired = async (): Promise<IExpiredDocument[]> => {
  const res = await api.get(`/reap/nextdue`);
  const { data } = res.data;
  return data;
};

export default {
  getReapExpired,
};
