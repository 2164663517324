import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from '../containers/Auth/pages/Login';
import SelectCompany from '../containers/Auth/pages/SelectCompany';
import UserPassword from '../containers/User/pages/Password';

const PublicRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact component={Login} path="/" />
      <Route exact component={SelectCompany} path="/selecionar-empresa" />

      <Route exact component={UserPassword} path="/senha/:token" />

      <Redirect to={{ pathname: '/' }} />
    </Switch>
  );
};

export default PublicRoutes;
