import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import gif from '../../../../assets/resus/1484.gif';

interface LoadingModalProps {
  open: boolean;
}
const useStyles = makeStyles(theme => ({
  customDialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(0), // Espaço entre o título e o conteúdo
  },
  centeredContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredImage: {
    display: 'block',
    margin: '0 auto',
  },
}));

const LoadingModal: React.FC<LoadingModalProps> = ({ open }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      className={classes.customDialog}
    >
      <DialogTitle id="loading-modal-title" className={classes.centeredTitle}>
        Enviando Arquivos...
      </DialogTitle>
      <DialogContent className={classes.centeredContent}>
        <img src={gif} alt="Carregando..." className={classes.centeredImage} />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
