import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  height: 44px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    margin-top: 2px;
  }

  div:focus {
    background-color: unset;
  }

  div:before {
    border-bottom: none;
  }

  div:after {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  input {
    margin: 1px 10px 0;
  }
`;
