import styled from 'styled-components';
import Icon from '@mdi/react';
import Stepper from '@material-ui/core/Stepper';

export const MyStepper = styled(Stepper)`
  padding: 16px;
`;

export const StepIcon = styled(Icon)`
  color: ${props => props.theme.palette.secondary.main};
`;

export const StepSeparateIcon = styled(Icon)`
  margin: 12px 0;
  color: #7a7a7a;
`;
