import React from 'react';
import parse from 'html-react-parser';

import { Container } from './styles';

const TextHighline: React.FC = ({ children, ...rest }) => {
  const replaceText = (): JSX.Element | JSX.Element[] | React.ReactNode => {
    if (!children || typeof children !== 'string') return children;

    let newText = children as string;
    let startMark = true;
    while (newText.indexOf('"') >= 0) {
      newText = newText.replace(
        '"',
        startMark ? '&ldquo;<span>' : '</span>&rdquo;',
      );
      startMark = !startMark;
    }

    return parse(newText);
  };

  return <Container {...rest}>{replaceText()}</Container>;
};

export default TextHighline;
