import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import PageHeader from '../../components/PrivateLayout/PageHeader';
import ExpiredDocDate from './components/ExpiredDocDate';
import { Container } from './styles';

import { IExpiredDocument } from './models';

import api from './services';

const initialExpired: IExpiredDocument[] = [
  {
    day: 5,
    quantity: 0,
  },
  {
    day: 15,
    quantity: 0,
  },
  {
    day: 30,
    quantity: 0,
  },
];

const Dashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [expiredDocument, setExpiredDocument] = useState<IExpiredDocument[]>(
    initialExpired,
  );

  const getDocReap = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const reapExpired = await api.getReapExpired();
      if (reapExpired) {
        setExpiredDocument(reapExpired);
      } else {
        setExpiredDocument(initialExpired);
      }
    } catch (error) {
      setExpiredDocument(initialExpired);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getDocReap();
  }, [getDocReap]);

  return (
    <Container>
      <PageHeader
        title="Dashboard"
        subtitle="Aqui você consegue visualizar algumas informações resumidas"
      />

      {isLoading ? (
        <Loading
          show={isLoading}
          transparent
          message="Carregando dashboard..."
        />
      ) : (
        <Card title="Documento Recolhido - Data Vencimento">
          <Grid container spacing={3}>
            <ExpiredDocDate expiredDate={expiredDocument} />
          </Grid>
        </Card>
      )}
    </Container>
  );
};

export default Dashboard;
