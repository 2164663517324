import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import { SpeedDial, SpeedDialIcon } from '@material-ui/lab';
import { Action, Column } from 'material-table';
import Icon from '@mdi/react';
import {
  mdiBookEdit,
  mdiClose,
  mdiEye,
  mdiShieldEdit,
  mdiShieldRemove,
  mdiViewList,
} from '@mdi/js';

import { useSnackbar } from '../../../../../../hooks/snackbar';
import { formatMonetaryValue } from '../../../../../../utils/helpers/format-helper';
import { handleFormError } from '../../../../../../utils/helpers/form-helper';
import {
  AttendanceStatusEnum,
  CompanyTypeEnum,
} from '../../../../../../utils/enum';

import { DataSeparateTitle, FabButton } from '../../../../../../styles/global';
import AsyncTable from '../../../../../../components/Tables/AsyncTable';
import { useAuth } from '../../../../../../hooks/auth';
import Card from '../../../../../../components/Card';
import { Input, DatePicker, Form } from '../../../../../../components/Form';
import Dialog from '../../../../../../components/Dialog';
import { SpeedDialActions } from './styles';

import apiDefense from '../../../../../Defense/services';
import api from '../../../../services';
import {
  IAttendance,
  IAttendanceModal,
  IDocument,
  IProcedure,
  ISpeedDial,
} from '../../../../models';
import AttendanceModal from '../AttendanceModal';
import palette from '../../../../../../styles/theme/palette';

interface Props {
  document: IDocument;
  attendance: IAttendance;
  onChangeAttendance: (attendance: IAttendance) => void;
  onSelectProcedure: (procedure: IProcedure) => void;
}

const AttendanceStep: React.FC<Props> = ({
  document,
  attendance,
  // attendance: attendanceParam,
  onChangeAttendance,
  onSelectProcedure,
}) => {
  const history = useHistory();
  const { loggedCompany } = useAuth();
  const { setMessage, setErrorMessage } = useSnackbar();
  const formRef = useRef(null);
  // const [attendance, setAttendance] = useState(attendanceParam);
  const [isRequester, setIsRequester] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);

  useEffect(() => {
    setIsRequester(loggedCompany.type === CompanyTypeEnum.Requester);
  }, [loggedCompany]);

  // useEffect(() => {
  //   setAttendance(attendanceParam);
  // }, [attendanceParam]);

  const columns: Column<IProcedure>[] = [
    {
      title: 'Tipo',
      field: 'typeProcedure',
    },
    {
      title: 'Código',
      field: 'codeProcedure',
    },
    {
      title: 'Quantidade',
      field: 'quantityProcedure',
    },
    {
      title: 'Valor',
      render: ({ valueProcedure }) => formatMonetaryValue(valueProcedure),
    },
    {
      title: 'Descrição',
      field: 'descriptionProcedure',
    },
  ];

  const handleDetails = async ({ id }: IProcedure): Promise<void> => {
    try {
      setIsLoading(true);
      const procedure = await api.getProcedureById(id);
      onSelectProcedure(procedure);
    } catch (err) {
      setErrorMessage('Houve um erro ao buscar os dados do procedimento.', err);
      setIsLoading(false);
    }
  };

  const actions: Action<IProcedure>[] = [
    {
      icon: () => <Icon size={1} path={mdiEye} />,
      tooltip: 'Visualizar',
      disabled: isLoading,
      onClick: (_, procedure) => handleDetails(procedure as IProcedure),
    },
  ];

  const handleCloseModal = (
    _: boolean,
    data: IAttendanceModal = null,
  ): void => {
    setIsShowModal(false);
    if (data) {
      const statusId =
        attendance.id_Status === AttendanceStatusEnum.Open
          ? AttendanceStatusEnum.Filled
          : attendance.id_Status;

      onChangeAttendance({
        ...attendance,
        attendanceAdicionalInfo: data,
        id_Status: statusId,
      });
    }
  };

  const handleDefense = async (): Promise<void> => {
    const idDefense = attendance?.id_Defense;
    console.log('attendance: ', attendance);

    if (idDefense) {
      setIsLoading(true);

      try {
        const defense = await apiDefense.getDefenseById(idDefense);
        history.push({
          pathname: `/defesas/editar/${idDefense}`,
          state: defense,
        });
      } catch (err) {
        setErrorMessage(
          'Houve um erro ao buscar os dados da defesa.',
          err,
          true,
        );
        setIsLoading(false);
      }
    } else {
      history.push({
        pathname: `/defesas/novo/`,
        state: { document, attendance },
      });
    }
  };

  const handleOpenSpeedDial = (): void => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = (): void => {
    setOpenSpeedDial(false);
  };

  const handleOpenDialog = (): void => {
    setIsShowDialog(true);
  };

  const actionsSpeed: ISpeedDial[] = [
    {
      icon: (
        <IconButton size="small" onClick={() => setIsShowModal(true)}>
          <Icon color="white" size={1} path={mdiViewList} />
        </IconButton>
      ),
      name: 'Dados Adicionais',
      color: palette.primary.main,
      colorHover: palette.primary.dark,
    },
    {
      icon: (
        <IconButton
          size="small"
          onClick={handleDefense}
          disabled={attendance.id_Status === AttendanceStatusEnum.Open}
        >
          <Icon color="white" size={1} path={mdiShieldEdit} />
        </IconButton>
      ),
      name: 'Editar Defesa',
      color: palette.secondary.main,
      colorHover: palette.secondary.dark,
      disabled: attendance.id_Status === AttendanceStatusEnum.Open,
      hidden: loggedCompany.type === CompanyTypeEnum.Requester,
    },
    {
      icon: (
        <IconButton
          size="small"
          hidden={loggedCompany.type === CompanyTypeEnum.Requester}
          disabled={
            attendance.id_Status === AttendanceStatusEnum.WithoutDefense ||
            attendance.id_Status === AttendanceStatusEnum.Protocoled
          }
          onClick={() => {
            handleOpenDialog();
          }}
        >
          <Icon color="white" size={1} path={mdiShieldRemove} />
        </IconButton>
      ),
      name: 'Sem Defesa',
      color: palette.error.main,
      colorHover: palette.secondary.light,
      hidden:
        loggedCompany.type === CompanyTypeEnum.Requester ||
        attendance.id_Status === AttendanceStatusEnum.WithoutDefense ||
        attendance.id_Status === AttendanceStatusEnum.Protocoled,
    },
  ];

  const handleSetNoDefense = async (): Promise<void> => {
    try {
      setIsLoading(true);

      setIsShowDialog(false);

      await api.putAttendanceStatus(
        attendance.id,
        AttendanceStatusEnum.WithoutDefense,
      );

      setMessage({
        type: 'success',
        message: 'Status do atendimento alterado com sucesso.',
      });
    } catch (err) {
      setErrorMessage(
        'Houve um erro ao alterar o status do atendimento.',
        err,
        true,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async ({
    observation,
    documentNumber,
    protocolNumber,
  }: IAttendance): Promise<void> => {
    try {
      setIsLoading(true);

      const newAttendance = {
        ...attendance,
        observation,
        documentNumber,
        protocolNumber,
      };
      await api.putAttendance(newAttendance);
      onChangeAttendance(newAttendance);

      setMessage({
        type: 'success',
        message: 'Informações para controle salvo com sucesso',
      });

      setIsLoading(false);
    } catch (err) {
      handleFormError(
        err,
        formRef,
        'Houve um erro ao salvar as informações para controle.',
        setErrorMessage,
        setIsLoading,
      );
    }
  };

  const handleError = (err: unknown): void => {
    setErrorMessage('Houve um erro ao buscar os procedimentos', err, true);
  };

  return (
    <React.Fragment>
      <Card
        title={`Você está visualizando o atendimento "${attendance?.type}" de "Nº ${attendance?.orderNumber}"`}
      >
        <Form
          formRef={formRef}
          initialData={attendance}
          showSubmitButton
          onSubmit={handleSubmit}
        >
          <Input sm={4} name="orderNumber" label="Nº Ordem" disabled />

          <Input sm={4} name="competence" label="Competência" disabled />

          <Input
            sm={4}
            label="Valor Total"
            value={formatMonetaryValue(attendance?.totalValue)}
            disabled
          />

          <Grid item xs={12}>
            <DataSeparateTitle>Atendimento identificado</DataSeparateTitle>
          </Grid>

          <Input sm={4} name="type" label="Tipo" disabled />

          <Input sm={4} name="number" label="Nº Atendimento" disabled />

          <Input
            sm={4}
            name="characterAttendance"
            label="Caráter Atendimento"
            disabled
          />

          <DatePicker
            sm={6}
            name="initialDateAttendance"
            label="Data Inicial"
            disabled
          />

          <DatePicker
            sm={6}
            name="endDateAttendance"
            label="Data Fim"
            disabled
          />

          <Grid item xs={12}>
            <DataSeparateTitle>Beneficiário</DataSeparateTitle>
          </Grid>

          <Input sm={4} name="beneficiaryCode" label="Código" disabled />

          <Input sm={4} name="codeCCO" label="Código CCO" disabled />

          <DatePicker
            sm={4}
            name="beneficiaryBirthDay"
            label="Data Nascimento"
            disabled
          />

          <Grid item xs={12}>
            <DataSeparateTitle>Informações para Controle</DataSeparateTitle>
          </Grid>

          <Input
            sm={4}
            name="observation"
            label="Observações"
            placeholder="Se necessário, informe uma observação aqui..."
            disabled={isRequester || isLoading}
          />

          <Input
            sm={4}
            name="documentNumber"
            label="Documentos"
            placeholder="Anote aqui os documentos que estão faltando ou se está tudo ok..."
            disabled={isRequester || isLoading}
          />

          <Input
            sm={4}
            name="protocolNumber"
            label="Nº Protocolo"
            placeholder="Nº Protocolo da defesa..."
            disabled={isRequester || isLoading}
          />
        </Form>

        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <DataSeparateTitle>Procedimentos</DataSeparateTitle>
          </Grid>

          <Grid item xs={12}>
            <AsyncTable
              ignoreBackupFilters
              actions={actions}
              columns={columns}
              dataKey="procedures"
              dataUrl="/procedure"
              filters={{ id: attendance?.id }}
              isLoading={isLoading}
              onError={handleError}
              noElevation
            />
          </Grid>
        </Grid>
      </Card>

      {loggedCompany.type !== CompanyTypeEnum.Requester ? (
        <React.Fragment>
          <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            style={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
            icon={
              <SpeedDialIcon openIcon={<Icon size={1} path={mdiClose} />} />
            }
            onClose={handleCloseSpeedDial}
            onOpen={handleOpenSpeedDial}
            open={openSpeedDial}
          >
            {actionsSpeed.map(action => (
              <SpeedDialActions
                key={action.name}
                icon={action.icon}
                color={action.color}
                colorHover={action.colorHover}
                tooltipTitle={action.name}
                hiddenButton={action.hidden}
                FabProps={{ disabled: action.disabled }}
                onClick={handleCloseSpeedDial}
              />
            ))}
          </SpeedDial>
        </React.Fragment>
      ) : (
        <FabButton color="secondary" onClick={() => setIsShowModal(true)}>
          <Icon size={1} path={mdiBookEdit} />
        </FabButton>
      )}

      {isShowModal && (
        <AttendanceModal
          attendance={attendance}
          isVisible={isShowModal}
          onClose={handleCloseModal}
        />
      )}

      <Dialog
        title="Atendimento Sem Defesa"
        message={`Você tem certeza que deseja marcar esse atendimento como "Sem Defesa"?`}
        open={isShowDialog}
        onSubmit={handleSetNoDefense}
        onClose={() => setIsShowDialog(false)}
        colorTitle={palette.secondary.main}
        colorButton={palette.success.main}
        colorButtonTwo={palette.error.main}
      />
    </React.Fragment>
  );
};

export default AttendanceStep;
