import { api } from '../../../services/api';

import { ILog } from '../models';

const getLogById = async (id: number): Promise<ILog> => {
  const res = await api.get(`/log/${id}`);
  const { data } = res.data;
  return data;
};

export default { getLogById };
